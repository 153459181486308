import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import Colors from '../../../../assets/styles';
import AddPaymentMethodModal from '../Payment/AddPaymentMethodModal';
import SimpleDialog from '../../../../components/Dialog';

const DriverTip = ({
  setClose,
  selectedTipBtn,
  setSelectedTipBtn,
  setSelectedTipAmount,
  loader,
  onPress,
  submit = false,
  back = false,
  driver,
  paymentMethodDialog,
  setPaymentMethodDialog,
  open
}) => {
  const [showInput, setShowInput] = useState(false);
  const [isNoTip, setIsNoTip] = useState(false);
  const [activeStep ,setActiveStep] = useState(0)
//   const [paymentMethodDialog, setPaymentMethodDialog] = useState(false);
  const isWallet = useSelector((state) => state.cartData.isWallet);
  console.log(isWallet)
  const [isWalletEnabled, setIsWalletEnabled] = useState(isWallet);
  const paymentCardData = useSelector(
    (state) => state.cartData.paymentCardData
  );
  console.log(paymentCardData)
  const theme = useTheme();

  const handleChange = (value) => {
    setSelectedTipAmount(value);
  };

  const handleSkip = () => {
    setSelectedTipBtn(3);
    setSelectedTipAmount(0);
    setIsNoTip(true);  // Set this to true because the user opted to skip tipping
    setShowInput(false);
    setClose();  // Only close on explicit skip
  };

  const handleSubmit = () => {
    if (!isNoTip) {
      onPress();  // Proceed with the submit action if the user didn't choose to skip
    } else {
      setClose();  // Close the modal if skipping
    }
  };
  return (
    <Dialog open={open} onClose={setClose} sx={{borderRadius:"20px !important",".css-1t1j96h-MuiPaper-root-MuiDialog-paper":{
      borderRadius:"20px !important"
    }}}>

    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"  
    >
    
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        bgcolor="white"
        borderRadius={4}
        boxShadow={3}
        p={3.3}
        position="relative"
      >
          <Box sx={{fontWeight:"bold",fontSize:"20px"}}>ADD A TIP</Box>
        <PersonIcon
          style={{
            marginTop:'10px',
            fontSize: 110,
            marginBottom: theme.spacing(2),
            color: Colors.grey,
          }}
        />
        <Typography variant="h6" align="center">
          Add a tip for {driver?.driver}
        </Typography>
        <Typography
          variant="body2"
          align="center"
          color="textSecondary"
          gutterBottom
        >
          Traffic, Weather, Stairs - they face it all so you don't have to.
        </Typography>
        <Box
          display="flex"
          justifyContent="space-evenly"
          width="100%"
          mt={1}
        >
          {[2, 5, 10].map((amount, index) => (
            <Button
              key={index}
              
              onClick={() => {
                setSelectedTipBtn(index);
                setSelectedTipAmount(amount);
                setIsNoTip(false);  // Ensure this is false since a tip is selected
              }}
              style={{
                width: 70,
                height: 70,
                backgroundColor: selectedTipBtn === index ? Colors.primary : "#f6f6f6",
                color:selectedTipBtn === index ? Colors.white : Colors.black,
                borderRadius:'15px',
                fontWeight:"500"
              }}
            >
              ${amount}
            </Button>
          ))}
        </Box>
          <Button
            
            onClick={handleSkip}
            style={{
                fontSize:'15px',
              width: 70,
              background:"none",
              mt:1,
              textTransform:"uppercase",
              height: 70,
              color:"rgb(106 98 98)",
              fontWeight:"500"
            }}
          >
            Skip
          </Button>
        <Box sx={{
            width: "100%",
            marginTop: "16px",
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
        }}>
          {!showInput ? (
            <Button
              onClick={() => !isNoTip && setShowInput(true)}
              style={{ color: 'red' }}
            >
              Add a custom amount
            </Button>
          ) : (
            <Box display="flex" alignItems="center">
              <IconButton onClick={() => setShowInput(false)}>
                <CloseIcon />
              </IconButton>
              <TextField
                placeholder="Enter Amount"
                type="number"
                onChange={(e) => handleChange(e.target.value)}
               
              />
              <IconButton onClick={() => setShowInput(false)}>
                <AddIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box mt={2} width="100%">
          <Typography variant="subtitle1">Payment Method</Typography>
          <Button
            fullWidth
            onClick={()=>setPaymentMethodDialog(true)}
            style={{ marginTop: '8px' }}
          >
            {isWallet ? 'Pay from wallet' : `**** ${paymentCardData?.last_four}`}
          </Button>
        </Box>
        {submit && (
        <Box mt={3} width="100%">
          <Button
            variant="contained"
            sx={{
                background:Colors.black,
                ":hover":{
                    background:Colors.black,

                }

            }}
            fullWidth
            onClick={handleSubmit}
            disabled={loader}
          >
            Submit & Continue
          </Button>
        </Box>
      )}
      </Box>
      
      {paymentMethodDialog &&(
        <>
        <SimpleDialog
          open={paymentMethodDialog}
          onClose={() => setPaymentMethodDialog(false)}
          title="Add Payment Methods"
        >
        <AddPaymentMethodModal 
         modalVisible={paymentMethodDialog}
         setIsWalletEnabled={setIsWalletEnabled}
         setClose={() => setPaymentMethodDialog(false)}
         fromWallet={false}
         onClick={() => {
        //    setAddCardModal(true);
           setPaymentMethodDialog(false);
         }}
         
        //  cards={cards}
         setpaymentMethodDialog={() =>
           setPaymentMethodDialog(!paymentMethodDialog)
         }
        //  removeModal={removeCardModal}
        //  setRemoveModal={setRemoveCardModal}
         closeAllModal={() => {
        //    setPaymentOptionModal(false);
        //    setPaymentMethodOptionModal(false);
           setPaymentMethodDialog(false);
         }}
        />
        </SimpleDialog>
        </>
      )  }
    </Box>
  
    </Dialog>
  );
};

export default DriverTip;
