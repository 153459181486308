import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Container,
} from "@mui/material";
import ShopServices from "../../../../api/ShopServices";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import Colors from "../../../../assets/styles";
import { useSelector } from "react-redux";

const StoreGrid = () => {
  const [shopList, setShopList] = useState([]);
  const [page, setPage] = useState(1);
  const { state } = useLocation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData.userData);


  const getShopList = async (page) => {
    const day = moment().format("ddd");
    const time = moment().format("HH:mm");
    const isPagination = true;
    const limit = 18;

    try {
      const data = await ShopServices.getShopList(
        day,
        time,
        isPagination,
        limit,
        page,
        userData?.id ? userData?.id :""
      );
      setShopList((prevData) => [...prevData, ...data.shoplist]);
    } catch (err) {
      console.log(err);
    }
  };

  const showMore = () => {
    setPage((prevPage) => {
      const newPage = prevPage + 1;
      getShopList(newPage);
      return newPage;
    });
  };

  useEffect(() => {
    getShopList();
  }, []);

  return (
    <Container sx={{ mt: 7, maxWidth: "1300px !important" }}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={12} md={12}>
          <Box sx={{ fontSize: "30px", fontWeight: "700" }}>All Shops</Box>
        </Grid>
        {state.map((store, index) => (
          <Grid item xs={12} sm={6} md={12} key={index}>
            <Card
              sx={{
                // p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: "none",
                // border: "1px solid #f3f3f3",
                borderRadius: "10px",
                cursor: "pointer",
                mb: 2
              }}
              onClick={() => navigate(`/store/detail/${store.shopid}`)}
            >
              <CardMedia
                component="img"
                image={baseUrl + "/docs/files/shops/banner/" + store.default_banner}
                alt={store.name}
                // sx={{ borderRadius: "50%", width: "70px", height: "70px" }}
              />
              <CardContent sx={{ textAlign: "center" }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ fontSize: "18px", fontWeight: "600" }}
                >
                  {store.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography sx={{ ml: 1 }}>
                    {`${
                          store?.estimated_delivery_time == null
                            ? ""
                            : `${store?.estimated_delivery_time - 5} - ${
                                store?.estimated_delivery_time
                              } mins`
                        }`}
                    </Typography>
                  </Box>
                  {store.offers && (
                    <Typography sx={{ color: "green" }}>
                      {store.offers}
                    </Typography>
                  )}
                </Typography>
              </CardContent>
              </Card>
              <Box sx={{ width: "100%", px: 2, mb: 2 }}>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Products
                </Typography>
                {store.products && store.products.length > 0 ? (
                  <Grid container spacing={1}>
                    {store.products.map((product) => (
                      <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={index}
                      onClick={() => navigate(`/product/detail/${product.product_id}`)}
                    >
                      <Card
                        sx={{
                        //   p: 2,
                          display: "flex",
                          alignItems: "center",
                          boxShadow: "none ",
                          border: "1px solid #f3f3f3",
                          borderRadius: "10px",
                          cursor:"pointer"
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={baseUrl + "/docs/files/products/" + product.pic}
                          alt={product.name}
                          sx={{ width: "80px", height: "90px" }}
                        />
                        <CardContent>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            sx={{ fontSize: "18px", fontWeight: "600" }}
                          >
                            {product.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography sx={{ ml: 1 }}>
                                {"$ " + product.price}
                              </Typography>
                            </Box>
                            {product.offers && (
                              <Typography sx={{ color: "green" }}>
                                {product.offers}
                              </Typography>
                            )}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography>No products available</Typography>
                )}
              </Box>
            {/* </Card> */}
          </Grid>
        ))}
      </Grid>
      {/* <Box
        sx={{
          background: Colors.black,
          color: Colors.white,
          py: 2,
          width: "150px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          borderRadius: "10px",
          margin: "30px auto",
          cursor: "pointer",
        }}
        onClick={showMore}
      >
        Show More
      </Box> */}
    </Container>
  );
};

export default StoreGrid;
