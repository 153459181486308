import { StoreRoutes } from "./route";
import { post, get, put } from "../index";


const StoreServices = {
  getStoreTypes: async () => {
    let result = get(StoreRoutes.getStoreTypes );
    return result;
  },
  getGroceryStores: async (obj) => {
    let result = get(StoreRoutes.getGroceryStores ,obj);
    return result;
  },

};

export default StoreServices;
