import {createSlice, configureStore, PayloadAction} from '@reduxjs/toolkit';

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    value: [],
    userConversation: {},
  },
  reducers: {
    reset(state) {
      state.value = [];
    },
    addMsg(state, action) {
      state.value.push(action.payload);
    },
    setConversation(state, action) {
      state.userConversation = action.payload;
    },
  },
});

export const {reset, addMsg, setConversation} = chatSlice.actions;
export default chatSlice.reducer;
