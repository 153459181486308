import { ShopRoutes } from "./route";
import { post, get, put } from "../index";


const ShopServices = {
  getShopList: async (day,time,isPagination,limit,page,user_id) => {
    let param = `?day=${day}&time=${time}&isPagination=${isPagination}&limit=${limit}&page=${page}`
    if(user_id !=""){
       param += `&user_id=${user_id}`
    }
    let result = get(ShopRoutes.getShopList + param);
    return result;
  },
  getShopByCuisine: async (day,time,user_id,cuisine_id) => {
    let result = get(ShopRoutes.getShopByCuisine + `?day=${day}&time=${time}&user_id=${user_id}&cuisine_id=${cuisine_id}`);
    return result;
  },

};

export default ShopServices;
