import React, { useState } from 'react';
import { Container, Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { giftCardApiCall } from '../../../../api/giftCard';
import { ErrorToaster, SuccessToaster } from '../../../../components/Toaster';
import { MaximizeTwoTone } from '@mui/icons-material';
import Colors from '../../../../assets/styles';


const Redeemed = ({getMyGiftCards,setOpenDialog}) => {
  const { control, handleSubmit, reset,register,formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);
  const userData = useSelector(state => state.userData.userData);
  const navigate = useNavigate();

  const redeemGiftCard = async formData => {
    setLoading(true);
    try {
      const { message, status } = await giftCardApiCall.redeemGiftCard(
        formData?.giftCardCode,
        userData?.id
      );
      if (status === '1') {
        SuccessToaster(message);
        setOpenDialog(false);
        getMyGiftCards();
        reset();
      } else {
        ErrorToaster(message);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container sx={{ backgroundColor: '#ffffff', paddingTop: '20px',maxWidth:"1300px"}}>
      
      <Box component="form" onSubmit={handleSubmit(redeemGiftCard)} style={{ marginTop: '20px' }}>
      <TextField
          {...register("giftCardCode", { required: "Gift card code is required" })}
          placeholder="Got a gift card?"
          variant="outlined"
          fullWidth
          autoFocus={true}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
          }}
          margin="normal"
          error={!!errors.giftCardCode}
          helperText={errors.giftCardCode ? errors.giftCardCode.message : ''}
        />
        <Typography
          style={{ fontSize: '14px', color: '#7e7e7e', marginTop: '10px', marginBottom: '20px' }}
        >
          Enter the Gift Code on the Gift Card you have received. Let's Eat will add the amount of Gift Card to your wallet.
        </Typography>
        <Box textAlign="center">
          <Button
            type="submit"
            variant="contained"
           
            sx={{mt:3, padding: '10px 20px',width:"100%",background:Colors.black,":hover":{
                background:Colors.black
            } }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Redeem'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Redeemed;
