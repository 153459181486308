import {groupOrderEndpoints} from './route';
import {get, post, patch, deleted} from '../index';

export const groupOrderApiCall = {
  addGroupOrder: async param => {
    let result = await post(groupOrderEndpoints.addGroupOrder, param);
    return result;
  
  },
  editGroupOrder: async param => {
    let result = await post(groupOrderEndpoints.editGroupOrder, param);
    return result;
  
  },
  joinGroupOrder: async param => {
    let result = await post(groupOrderEndpoints.joinGroupOrder, param);
    return result;
  
  },
  addGroupOrderToCart: async param => {
    let result = await post(groupOrderEndpoints.addGroupOrderToCart, param);
    return result;
  
  },
  getGroupOrder: async param => {
    let result = await get(groupOrderEndpoints.getgroupOrderDetail, param);
    return result;
  
  },
  getJoinGroupOrderDetail: async id => {
    let result = await get(
      groupOrderEndpoints.getjoingroupOrderDetail + `?id=${id}`,
    );
    return result;
  
  },
  getGroupOrderCartList: async param => {
    let result = await get(groupOrderEndpoints.getgroupOrderCartList, param);
    return result;
  
  },
  deleteGroupOrder: async id => {
    let result = await post(
      groupOrderEndpoints.deleteGroupOrder + `?group_id=${id}`,
    );
    return result;
  
  },
  deleteItemFromGroupOrder: async id => {
    let result = await post(
      groupOrderEndpoints.deleteItemFromGroupOrder + `?id=${id}`,
    );
    return result;
  
  },
  deleteUserFromGroupOrder: async (userId, groupId) => {
    let result = await post(
      groupOrderEndpoints.deleteUserFromGroupOrder +
        `?user_id=${userId}&group_id=${groupId} `,
    );
    return result;
  
  },
  editGroupOrderAddToCart: async param => {
    let result = await post(groupOrderEndpoints.editGroupOrderAddToCart, param);
    return result;
  
  },
};
