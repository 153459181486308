export const  LocationRoutes = {
   
    saveAddressDetails: '/customer/addaddress',
    addAddressDetails: '/customer/AddAddressNew',
    updatedAddressDetails: '/customer/UpdateAddress',
    getCustomerAddress: '/customer/GetAddress',
    deleteCustomerAddress: '/customer/DeletAddress',
    getShopAddress: '/data/GetShopAddress',
    getStoreAddress: '/public/GetStoreAddress',
    // lets eat parcel module endpoints
    getParcelAddress: '/customer/GetParcelAddress',
    deleteParcelAddress: '/customer/DeleteParcelAddress',
    updatedParcelAddressDetails: '/customer/UpdateParcelAddress',
    addParcelAddressDetails: '/customer/AddParcelAddress',
    getParcelAddressDistance: '/public/GetParcelAddress',
    
    
  };
    