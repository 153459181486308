import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
  Grid,
  Paper,
  TextField,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  CssBaseline,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Images } from "../../../../assets/images";
import Colors from "../../../../assets/styles";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import FooterV2 from "../../../../layout/footerV2";
import Header2 from "../../../../layout/dashboard/header/header2";
import { styled } from "@mui/system";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm, Controller } from "react-hook-form";
import { FormServices } from "../../../../api/FormServices";
import { SuccessToaster, ErrorToaster } from "../../../../components/Toaster";

export default function Home() {
  const [captchaValue, setCaptchaValue] = useState(null); // State to store captcha value
  const [captchaError, setCaptchaError] = useState(false); // State to store captcha error

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaError(false);
    setValue("captcha", value);
  };

  const onSubmit = async (data) => {
    if (!captchaValue) {
      setCaptchaError(true);
      return;
    }
    const obj = {
      name: data?.firstName,
      lname: data?.lastName,
      restname: data?.restaurantName,
      email: data?.email,
      phone: data?.phoneNumber,
      address: data?.restaurantAddress,
      type: "store", // store or shop
    };
    const response = await FormServices.submitForm(obj);
    try {
      SuccessToaster(response?.message);
    } catch (error) {
      ErrorToaster(error);
    }
    console.log("Form Data: ", data);
  };

  const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      fill: theme.palette.primary.main,
    },
    backgroundColor: "#fff",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&.Mui-checked:hover": {
      backgroundColor: "#fff",
    },
  }));

  const cardData = [
    {
      image: Images.personOnImage,
      title: "Expand customer reach",
      description:
        "Let's Eat offers island-wide delivery making your restaurant more accessible.",
    },
    {
      image: Images.visibility,
      title: "Boost your visibility",
      description:
        "Stand out with in-app marketing to reach even more customers, increase sales and boost visibility.",
    },
    {
      image: Images.connect,
      title: "Manage with Ease",
      description:
        "Manage your menu and review sales reports through the restaurant partner portal. Restaurant support team is responsive.",
    },
  ];
  const cardData2 = [
    {
      image: Images.orderImage1,
      title: "Customers Order",
      description:
        "A customer finds your restaurant and places an order through the Let's Eat app.",
    },
    {
      image: Images.prepareImage1,
      title: "You Prepare",
      description:
        "Your restaurant accepts the order through the restaurant partner app and prepares the order.",
    },
    {
      image: Images.personArriving1,
      title: "Delivery Person arrives",
      description:
        "Delivery person picks up the order from your restaurant and then delivers it to the customer.",
    },
  ];
  const CustomContainer = styled(Container)(({ theme }) => ({
    backgroundImage: `url(${Images.orangeBG})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: "32px",
    marginTop: "180px",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      padding: "30px",
      marginTop: "30px",
    },
  }));
  return (
    <React.Fragment>
      <CssBaseline />
      <Header2 />
      {/* Hero Section */}
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Section with Background and Clip Path */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "100%",
            height: "100%",

            zIndex: 1,
            bottom: { md: "none", xs: 0 },
          }}
        />

        {/* Container with Content */}
        <Box
          sx={{ position: "relative", zIndex: 2, pt: { md: 10, sm: 4, xs: 4 } }}
        >
          <Container sx={{ mt: 1, maxWidth: "1300px !important" }}>
            <Grid container>
              <Grid item md={6}>
                {/* Blowing Up Box */}

                {/* Main Heading */}
                <Typography
                  component="h1"
                  gutterBottom
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "900",
                    fontSize: { md: "4rem", sm: "3rem", xs: "2rem" },
                    color: "#000",
                    lineHeight: "1.4",
                    // WebkitTextStroke: "2px #000",
                  }}
                >
                  Join the Let's Eat Marketplace
                </Typography>

                {/* Subtext */}
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                    width: "100%",
                    fontSize: "16px",
                    color: "#858b90",
                    fontSize: { md: "17px", sm: "15px", xs: "13px" },
                  }}
                  paragraph
                >
                  Let's Eat gives you the reliability, visibility and data
                  insights you need to connect with more customers. Partner with
                  us today.
                </Typography>

                {/* Features Section */}
                <Box mt={4} display="flex" gap="18px" spacing={2}>
                  <Box display="flex" alignItems="center">
                    <img src={Images.shield} />
                    <Typography
                      variant="body1"
                      sx={{
                        marginLeft: "8px",
                        fontWeight: "bold",
                        fontSize: { md: "14px", xs: "12px" },
                      }}
                    >
                      Connect with customers
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <img src={Images.shield} />

                    <Typography
                      variant="body1"
                      sx={{
                        marginLeft: "8px",
                        fontWeight: "bold",
                        fontSize: { md: "14px", xs: "12px" },
                      }}
                    >
                      Boost your visibility
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* Image Section */}
              <Grid
                item
                md={6}
                sx={{
                  position: "relative",
                  mt: { md: 0, sm: "100px", xs: "100px" },
                }}
              >
                <img
                  style={{ width: "100%" }}
                  src={Images.grocery}
                  alt="Delicious Food"
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Box sx={{ background: Colors.black, mx: 2, borderRadius: "15px" }}>
        <Container sx={{ py: { md: 10, sm: 1, xs: 1 }, maxWidth: "1300px" }}>
          <Grid
            container
            spacing={4}
            alignItems="center"
            sx={{
              margin: "0 auto",
              gap: { md: 10, sm: 0 },
              display: "flex",
              justifyContent: {
                lg: "center",
                md: "center",
                sm: "start",
                xs: "start",
              },
            }}
          >
            {/* Left Section: Information Text */}

            <Grid
              item
              xs={12}
              md={3}
              sx={{
                paddingRight: { md: 0, sm: "30px", xs: "30px" },
                paddingLeft: { md: "32px", sm: "0px", xs: "0px !important" },
              }}
            >
              <Typography
                sx={{
                  color: Colors.white,
                  fontFamily: "Poppins",
                  // fontSize: { md: "30px", sm: "30px", xs: "25px" },
                  fontSize: { md: "2.6rem", sm: "2.4rem", xs: "22px" },

                  width: { md: "auto", sm: "auto", xs: "200px" },
                }}
                variant="h3"
                fontWeight="bold"
                color="textPrimary"
                gutterBottom
              >
                Let’s Get <br /> started with <br />
                <span style={{ color: "#F04D22" }}>Let’s Eat</span>
              </Typography>
              <Typography
                sx={{
                  color: Colors.white,
                  fontFamily: "Poppins",
                  fontSize: "13px",
                  mt: { md: 2, sm: 4, xs: 4 },
                }}
                variant="body1"
                color="textSecondary"
                mb={3}
              >
                Sign Up to Let’s Eat. Thousands of Let’s Eat app users are
                searching for food in your area. By partnering with Let’s Eat
                and adding your restaurant to the platform, we can help you
                reach those users.
              </Typography>
            </Grid>

            {/* Right Section: Signup Form */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                paddingRight: { md: 0, sm: "30px", xs: "30px" },
                paddingLeft: { md: "32px", sm: "0px", xs: "0px !important" },
              }}
            >
              <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  {/* Restaurant Name Field */}
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      gutterBottom
                    >
                      Restaurant Name{" "}
                      <span style={{ color: Colors.red }}>*</span>
                    </Typography>
                    <Controller
                      name="restaurantName"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Restaurant Name is required" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          required
                          sx={{
                            bgcolor: "#333",
                            input: { color: "white" },
                            borderRadius: "4px",
                            input: {
                              padding: "11px 14px !important",
                            },
                          }}
                          InputLabelProps={{ style: { color: "white" } }}
                          error={!!errors.restaurantName}
                          helperText={
                            errors.restaurantName
                              ? errors.restaurantName.message
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>

                  {/* Restaurant Address Field */}
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "15px",
                        input: {
                          padding: "11px 14px !important",
                        },
                      }}
                      gutterBottom
                    >
                      Restaurant Address{" "}
                      <span style={{ color: Colors.red }}>*</span>
                    </Typography>
                    <Controller
                      name="restaurantAddress"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Restaurant Address is required" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          required
                          sx={{
                            bgcolor: "#333",
                            input: { color: "white" },
                            borderRadius: "4px",
                            input: {
                              padding: "11px 14px !important",
                            },
                          }}
                          InputLabelProps={{ style: { color: "white" } }}
                          error={!!errors.restaurantAddress}
                          helperText={
                            errors.restaurantAddress
                              ? errors.restaurantAddress.message
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>

                  {/* First Name Field */}
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "15px",
                        input: {
                          padding: "11px 14px !important",
                        },
                      }}
                      gutterBottom
                    >
                      First Name <span style={{ color: Colors.red }}>*</span>
                    </Typography>
                    <Controller
                      name="firstName"
                      control={control}
                      defaultValue=""
                      rules={{ required: "First Name is required" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          required
                          sx={{
                            bgcolor: "#333",
                            input: { color: "white" },
                            borderRadius: "4px",
                            input: {
                              padding: "11px 14px !important",
                            },
                          }}
                          InputLabelProps={{ style: { color: "white" } }}
                          error={!!errors.firstName}
                          helperText={
                            errors.firstName ? errors.firstName.message : ""
                          }
                        />
                      )}
                    />
                  </Grid>

                  {/* Last Name Field */}
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "15px",
                        input: {
                          padding: "11px 14px !important",
                        },
                      }}
                      gutterBottom
                    >
                      Last Name <span style={{ color: Colors.red }}>*</span>
                    </Typography>
                    <Controller
                      name="lastName"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Last Name is required" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          required
                          sx={{
                            bgcolor: "#333",
                            input: { color: "white" },
                            borderRadius: "4px",
                            input: {
                              padding: "11px 14px !important",
                            },
                          }}
                          InputLabelProps={{ style: { color: "white" } }}
                          error={!!errors.lastName}
                          helperText={
                            errors.lastName ? errors.lastName.message : ""
                          }
                        />
                      )}
                    />
                  </Grid>

                  {/* Email Field */}
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "15px",
                        input: {
                          padding: "11px 14px !important",
                        },
                      }}
                      gutterBottom
                    >
                      Email <span style={{ color: Colors.red }}>*</span>
                    </Typography>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Email is required",
                        pattern: {
                          value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                          message: "Invalid email address",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          required
                          sx={{
                            bgcolor: "#333",
                            input: { color: "white" },
                            borderRadius: "4px",
                            input: {
                              padding: "11px 14px !important",
                            },
                          }}
                          InputLabelProps={{ style: { color: "white" } }}
                          error={!!errors.email}
                          helperText={errors.email ? errors.email.message : ""}
                        />
                      )}
                    />
                  </Grid>

                  {/* Phone Number Field */}
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "15px",
                        input: {
                          padding: "11px 14px !important",
                        },
                      }}
                      gutterBottom
                    >
                      Phone Number <span style={{ color: Colors.red }}>*</span>
                    </Typography>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Phone Number is required",
                        pattern: {
                          value: /^[0-9]{10,15}$/,
                          message: "Invalid phone number",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          required
                          sx={{
                            bgcolor: "#333",
                            input: { color: "white" },
                            borderRadius: "4px",
                            input: {
                              padding: "11px 14px !important",
                            },
                          }}
                          InputLabelProps={{ style: { color: "white" } }}
                          error={!!errors.phoneNumber}
                          helperText={
                            errors.phoneNumber ? errors.phoneNumber.message : ""
                          }
                        />
                      )}
                    />
                  </Grid>

                  {/* Terms and Conditions Checkbox */}
                  <Grid item xs={12}>
                    <Controller
                      name="terms"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <FormControlLabel
                          sx={{ ml: "0px !important" }}
                          control={<CustomCheckbox {...field} />}
                          label={
                            <Typography
                              sx={{
                                color: "#fff",
                                ml: 1,
                                fontSize: {
                                  md: "17px",
                                  sm: "15px",
                                  xs: "10px",
                                },
                                color: "#858b90",
                              }}
                              variant="body2"
                            >
                              Creating an account means you’re okay with our{" "}
                              Terms of Service , Privacy Policy , and our
                              default Notification Settings.
                            </Typography>
                          }
                        />
                      )}
                    />
                  </Grid>

                  {/* CAPTCHA Placeholder */}
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        borderRadius: "4px",
                        padding: "16px",
                        textAlign: "center",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      {/* Google reCAPTCHA Component */}
                      <ReCAPTCHA
                        sitekey="6Ler8UkcAAAAAP4IKjtdHGSaUrARnJ8nQJ4TOLVs" // Replace with your actual site key
                        onChange={handleCaptchaChange}
                      />
                      {captchaError && (
                        <Typography variant="body2" color="error">
                          Captcha is required.
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  {/* Sign Up Button */}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      pt: "8px !important",
                      pb: 2.5,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      type="submit"
                      sx={{
                        backgroundImage:
                          "linear-gradient(450deg, #f5593d 0%, #ef4223 50%, #ef4223 100%)",
                        color: "white",
                        fontSize: "13px",
                        textTransform: "none",
                        py: 1,
                        borderRadius: "12px",
                        width: "100%",
                        "&:hover": {
                          backgroundImage:
                            "linear-gradient(450deg, #f5593d 0%, #ef4223 50%, #ef4223 100%)",
                        },
                      }}
                    >
                      Sign up
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          align="center"
          sx={{
            fontFamily: "Poppins",
            WebkitTextStroke: "0.6px #000",
            // fontSize: { md: "36px", sm: "35px", xs: "24px" },
            fontSize: { md: "2.6rem", sm: "2.4rem", xs: "22px" },
          }}
          gutterBottom
        >
          Why{" "}
          <span style={{ color: "#F04D22", WebkitTextStroke: "0.6px #F04D22" }}>
            Let’s Eat?
          </span>
        </Typography>

        {/* Cards Section */}
        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="center"
          mt={5}
        >
          {cardData.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  height: "500px",
                  borderRadius: "16px",
                  textAlign: "center",
                  p: 2,
                  transition:
                    "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
                  border: "1px solid #ddd !important",
                  boxShadow: "none !important",
                  "&:hover": {
                    color: "white",
                    transform: "scale(1.05)",
                    backgroundColor: Colors.primary,

                    "& .card-description": {
                      color: "rgb(255 255 255 / 80%)",
                    },
                  },
                }}
              >
                <CardContent>
                  <Box sx={{ mb: 2 }}>
                    <img
                      src={card.image}
                      alt={card.title}
                      style={{ height: "180px", width: "180px" }}
                    />
                  </Box>

                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      transition: "color 0.3s ease-in-out",
                    }}
                    className="card-title"
                    gutterBottom
                  >
                    {card.title.split(" ")[0]} <br />{" "}
                    {card.title.split(" ").slice(1).join(" ")}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#858b90",
                      mt: 2,

                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      transition: "color 0.3s ease-in-out",
                      fontSize: { md: "17px", sm: "17px", xs: "14px" },
                    }}
                    className="card-description"
                    variant="body2"
                  >
                    {card.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Box sx={{ backgroundColor: "black", py: 10 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                fontWeight="900"
                sx={{
                  color: "white",
                  mb: 2,
                  fontFamily: "Poppins",
                  WebkitTextStroke: "0.6px #fff",
                  letterSpacing: "2px",
                  // fontSize: { md: "36px", sm: "35px", xs: "24px" },
                  fontSize: { md: "2.6rem", sm: "2.4rem", xs: "22px" },

                  lineHeight: 1.5,
                }}
              >
                Unlock <br />
                <span
                  style={{
                    color: "#F04D22",
                    WebkitTextStroke: "0.6px #F04D22",
                    letterSpacing: "2px",
                  }}
                >
                  New Growth
                </span>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: Colors.white,
                  lineHeight: "1.7",
                  fontSize: { md: "18px", sm: "18px", xs: "12px" },
                  fontFamily: "Poppins",
                  mt: 4,
                }}
              >
                Island-wide delivery allows thousands of Let's Eat users across
                Grand Cayman to view and order from your menu. Satisfy your
                customers by giving them the food they want, when and where they
                want it.
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="center">
                <img
                  src={Images.newGrowth}
                  alt="Let's Eat App"
                  style={{
                    maxHeight: "400px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 10 }}>
        {/* Title Section */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: "900",
            fontFamily: "Poppins",
            textAlign: "center",
            WebkitTextStroke: "0.7px #000",
            // fontSize: { md: "36px", sm: "35px", xs: "24px" },
            fontSize: { md: "2.6rem", sm: "2.4rem", xs: "22px" },

            letterSpacing: "1.4px",
          }}
          gutterBottom
        >
          How{" "}
          <span style={{ color: "#F04D22", WebkitTextStroke: "0.7px #F04D22" }}>
            Let's Eat{" "}
          </span>{" "}
          <br /> Work for restaurant partners
        </Typography>

        {/* Cards Section */}
        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="center"
          mt={5}
        >
          {cardData2.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  // height: '500px',
                  borderRadius: "16px",
                  textAlign: "center",
                  background: Colors.black,
                }}
              >
                <CardContent sx={{ padding: "0px 0px 20px 0px !important" }}>
                  <Box sx={{ mb: 2 }}>
                    <img
                      src={card.image}
                      alt={card.title}
                      style={{ width: "100%", objectFit: "cover" }}
                    />
                  </Box>
                  <Box sx={{ background: Colors.black }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        color: Colors.white,
                      }}
                      gutterBottom
                    >
                      {card.title.split(" ")[0] +
                        " " +
                        card.title.split(" ").slice(1).join(" ")}
                    </Typography>

                    <Typography
                      sx={{
                        color: "#858b90",
                        mt: 1,
                        fontSize: { md: "17px", sm: "17px", xs: "14px" },
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        padding: "1px 24px",
                      }}
                      className="card-description"
                      variant="body2"
                    >
                      {card.description}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: "#f1f1f1",
          py: 5,
          borderRadius: "12px",
          my: 4,
          width: { md: "100%", sm: "95%", xs: "95%" },
        }}
      >
        <Grid container spacing={4}>
          {/* Left Side Content */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              paddingLeft: { md: "32px", sm: "32px", xs: "50px !important" },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Poppins",
                mb: 2,
                fontWeight: "900",
                // fontSize: { md: "35px", sm: "35px", xs: "22px" },
                fontSize: { md: "2.6rem", sm: "2.4rem", xs: "22px" },

                WebkitTextStroke: {
                  md: "0.7px #000",
                  sm: "0.7px #000",
                  xs: "0.7px #000",
                },

                lineHeight: 1.2,
                letterSpacing: "1.4px",
              }}
            >
              Get started in <br /> just 3 steps
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: { md: "17px", sm: "17px", xs: "14px" },

                fontWeight: "bold",
                lineHeight: "2",
                my: 5,
                fontFamily: "Poppins",
              }}
            >
              1. Click the link below to sign up your store. <br />
              2. Upload or send the support team your inventory list and logo{" "}
              <br />
              3. Access your Store portal and go live!
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundImage:
                  " linear-gradient(450deg, #f5593d 0%, #ef4223 50%, #ef4223 100%)",

                color: "#fff",
                fontSize: { md: "16px", sm: "16px", xs: "14px" },
                fontWeight: "bold",
                textTransform: "none",
                borderRadius: "8px",
                px: 4,
                py: 1,
                "&:hover": {
                  backgroundImage:
                    " linear-gradient(450deg, #f5593d 0%, #ef4223 50%, #ef4223 100%)",
                },
              }}
            >
              Sign up
            </Button>
          </Grid>

          {/* Right Side Image */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              paddingLeft: { md: "32px", sm: "32px", xs: "50px !important" },
            }}
          >
            <Box
              component="img"
              src={Images.phoneImage}
              alt="Let's Eat App"
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: "12px",
                objectFit: "cover",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <CustomContainer
        sx={{
          height: {
            md: "310px",
            sm: "auto",
            xs: "auto",
            maxWidth: "1200px !important",
          },
          width: { md: "100%", sm: "95%", xs: "95%" },
          borderRadius: "20px",
          backgroundColor: {
            md: "none",
            sm: "#f54525",
            xs: "#f54525",
          },
        }}
      >
        <Grid container spacing={4} alignItems="center">
          {/* Left Side Content */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              padding: {
                md: "90px 53px !important",
                sm: "53px 0px !important",
                xs: "53px 0px !important",
              },
            }}
          >
            <Typography
              variant="h4"
              fontWeight="900"
              sx={{
                fontFamily: "Poppins",
                color: Colors.black,
                ml: 2,
                WebkitTextStroke: "0.7px #000",
                // fontSize: { md: "36px", sm: "34px", xs: "34px" },
                fontSize: { md: "2.6rem", sm: "2.4rem", xs: "22px" },
              }}
              gutterBottom
            >
              Got A Question? <br />{" "}
              <span style={{ color: "#fff", WebkitTextStroke: "0.7px #fff" }}>
                Contact Us Today.
              </span>
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "#fff",
                fontFamily: "Poppins",
                mb: 3,
                ml: 2,
              }}
            >
              team@letseat.ky
            </Typography>
            <CardMedia
              sx={{
                display: { md: "flex", sm: "none", xs: "none" },
                width: "155px",
                position: "absolute",
                top: "183px",
                left: "218px",
              }}
              component={"img"}
              image={Images.blackWhiteArrow}
            />
          </Grid>

          {/* Right Side Image */}
          <Grid item xs={12} md={6} sx={{ position: "relative" }}>
            {/* Delivery Image */}
            <Box
              component="img"
              src={Images.deliveryGirl}
              alt="Delivery Image"
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: "12px",
                objectFit: "cover",
                maxWidth: "470px",
                margin: "auto",
                position: { md: "absolute", xs: "static", sm: "static" },
                right: { md: "8px", sm: "auto", xs: "auto" },
                top: { md: "-245px", sm: "auto", xs: "auto" },
              }}
            />

            {/* Question Icon */}
            <Box
              sx={{
                position: "absolute",
                top: { md: "-189px", xs: "10%" },
                left: { md: "-7%", xs: "5%" },
                width: { md: "20%", sm: "13%", xs: "13%" },
              }}
            >
              <Box
                component="img"
                src={Images.qustionPic}
                alt="Question Icon"
                sx={{
                  width: "100%",
                }}
              />
            </Box>

            {/* Map Circle Icon */}
            <Box
              sx={{
                position: "absolute",
                top: { md: "35px", sm: "none" },
                bottom: { md: "0", sm: "1px", xs: "1px" },
                right: { md: "4%", sm: "155px", xs: "60px" },
                width: { md: "20%", sm: "13%", xs: "13%" },
              }}
            >
              <Box
                component="img"
                src={Images.mapCircle}
                alt="Map Circle Icon"
                sx={{
                  width: "100%", // Scale to fit container
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomContainer>

      <FooterV2 />
    </React.Fragment>
  );
}
