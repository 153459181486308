import {paymentEndpoints} from './route';
import {get, post, patch, deleted} from '../tokenindex';

export const paymentApiCall = {
  addCard: async param => {
    let result = await post(paymentEndpoints.addCard, param);
    return result
  },
  addToWallet: async param => {
    let result = await post(paymentEndpoints.addToWallet, param);
    return result
  },
  pay3d: async param => {
    let result = await post(paymentEndpoints.pay3d, param);
    return result
  },
  deleteCard: async id => {
    let result = await post(paymentEndpoints.deleteCard + `?id=${id}`);
    return result
  },
  getCards: async param => {
    let result = await get(paymentEndpoints.getCard, param);
    return result
  },
  tipPayment: async param => {
    let result = await post(paymentEndpoints.tipPayment, param);
    return result
  },
};
