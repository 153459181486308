import {profileEndpoints} from './route';
import {get, post, patch, deleted} from '../tokenindex';

export const profileApiCall = {
  getUserProfile: async param => {
    let result = await get(profileEndpoints.getUserProfile, param);
    return result;
  },
  getCustomerCards: async param => {
    let result = await get(profileEndpoints.getCustomercard, param);
    return result;
  },
  getWalletDetails: async param => {
    let result = await get(profileEndpoints.getWalletDetails, param);
     return result;
  },

  //
  getDisputeDetails: async obj => {
    let result = await get(
      profileEndpoints.getDisputeDetails +
        `?id=${obj?.id}&user_id=${obj?.userId} `,
    );
    return result;
  },

  createTicket: async obj => {
    let result = await post(profileEndpoints.createTicket, obj);
    return result;
  },
  addDispute: async obj => {
    let result = await post(profileEndpoints.addDispute, obj);
    return result;
  },
//   uploadFile: async obj => {
//     let result = await documentPost(profileEndpoints.uploadFile, obj);
//     return result.dat 
//   },
  editProfile: async obj => {
    let result = await post(profileEndpoints.editProfile, obj);
    return result;
  },
  getProfile: async obj => {
    let result = await get(profileEndpoints.getProfile, obj);
    return result;
  },
};
