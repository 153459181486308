import { giftCardEndpoints } from './route.js';
import { get, post, patch, deleted } from '../tokenindex.js';

export const giftCardApiCall = {
  getGiftCards: async () => {
    let result = await get(giftCardEndpoints.getGiftCards);
     return result
  },
  getMyGiftCards: async (id) => {
    let result = await get(giftCardEndpoints.getMyGiftCards + `?userID=${id}`);
     return result
  },
  getGiftCardCategories: async () => {
    let result = await get(giftCardEndpoints.getGiftCardCategories);
     return result
  },
  createGiftCard: async (obj) => {
    let result = await post(giftCardEndpoints.createGiftCard, obj);
     return result
  },
  redeemGiftCard: async (code, userId) => {
    let result = await post(giftCardEndpoints.redeemGiftCard + `?code=${code}&userID=${userId}`);
     return result
  },
  updateGiftCard: async (obj) => {
    let result = await post(giftCardEndpoints.updateGiftCard, obj);
     return result
  },
};
