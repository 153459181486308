import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  TextField,
  IconButton,
  CircularProgress,
  Divider,
} from '@mui/material';
import { Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../redux/slices/UserDataSlices';
import { profileApiCall } from "../../api/ProfileServices/index";
import OTP from '../OTPINPUT';
import Colors from '../../assets/styles';
import SimpleDialog from '../Dialog';
import ChangePIN from '../ChangePin';
import { ErrorToaster, SuccessToaster } from '../Toaster';

const EditProfileModal = () => {
  const [selectedItem, setSelectedItem] = useState('Accounts Info');
  const [editable, setEditable] = useState(false);
  const [pinDialog, setPinDialog] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [otpCode, setOtpCode] = useState("");

  const { control, handleSubmit, getValues, setValue } = useForm();
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    setValue('email', userData?.email);
    setValue('name', userData?.name);
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    setUpdating(true);
    let params = {
      user_id: userData?.id,
    };

    try {
      const res = await profileApiCall.getProfile(params);
      const user = { ...userData, email: res?.email, name: res?.name };
      dispatch(setUserData(user));
      setEditable(false);
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      console.log('error', error);
    }
  };

  const EditProfile = async () => {
    setUpdating(true);
    let editProfileObj = {
      id: userData?.id,
      name: getValues('name'),
      email: getValues('email'),
    };

    try {
      const res = await profileApiCall.editProfile(editProfileObj);
      if (res.status === '1') {
        SuccessToaster('Profile information updated.');
        getUserProfile();
      } else {
        ErrorToaster('Something went wrong.');
      }
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      console.log('error', error);
    }
  };

  const handleOtpChange = (otp) => {
    setOtpCode(otp);
  };

  return (
    <>
  
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: '10px',
        margin: 'auto',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', marginY: '20px' ,gap:"10px"}}>
      <Button
        sx={{
          color: Colors.black,
          borderBottom: selectedItem === 'Accounts Info' ? '2px solid black' : 'none',
        }}
        onClick={() => setSelectedItem('Accounts Info')}
      >
        Accounts Info
      </Button>
      <Button
        sx={{
          color: Colors.black,
          borderBottom: selectedItem === 'Security' ? '2px solid black' : 'none',
        }}
        onClick={() => setSelectedItem('Security')}
      >
        Security
      </Button>
    </Box>

      {selectedItem === 'Accounts Info' ? (
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6" sx={{fontWeight:"bold"}}>Basic Information</Typography>
          {editable ? (
            <Box sx={{mt:2}}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                {...control.register('email')}
                sx={{ marginBottom: '20px' }}
              />
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                {...control.register('name')}
                sx={{ marginBottom: '20px' }}
              />
              <Button
                variant="contained"
               sx={{background:Colors.black,":hover":{
                background:Colors.black
               }}}
                onClick={handleSubmit(EditProfile)}
                disabled={updating}
                fullWidth
              >
                {updating ? <CircularProgress size={24} /> : 'Update'}
              </Button>
            </Box>
          ) : (
            <Box sx={{mt:2}}>
              <InfoBtn title="Name" fieldValue={userData?.name} />
              <Divider sx={{ marginY: '10px' }} />
              <InfoBtn title="Email" fieldValue={userData?.email} />
              <Divider sx={{ marginY: '10px' }} />
              <InfoBtn title="Phone Number" fieldValue={userData?.phone} />
            </Box>
          )}
          <IconButton
            sx={{ position: 'absolute', top: '100px', right: '20px' }}
            onClick={() => setEditable(!editable)}
          >
            {editable ? <CloseIcon /> : <EditIcon />}
          </IconButton>
        </Box>
      ) : (
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6" sx={{fontWeight:"bold"}}>Your PIN</Typography>
          <OTP length={4} value="1234" focusInputIndex={false} disabled onChange={handleOtpChange} />
          <Button variant="text" sx={{ marginTop: '20px' ,color:Colors.black }} onClick={()=>setPinDialog(true)}>
            Tap here to change PIN
          </Button>
        </Box>
      )}
    </Box>
    {pinDialog && (
        <>
         <SimpleDialog   open={pinDialog}
              onClose={() => setPinDialog(false)}
              title="Change PIN ">
              <Box>
                <ChangePIN setPinDialog={setPinDialog} />
    
              </Box>
            </SimpleDialog>
        </>
        
    )} 
      </>
  );
};



const InfoBtn = ({ title, fieldValue, onPress }) => (
  <Box onClick={onPress} sx={{ paddingBottom: '20px', cursor: 'pointer' }}>
    <Typography variant="body1" fontWeight="bold">
      {title}
    </Typography>
    <Typography variant="body2" color="textSecondary">
      {fieldValue}
    </Typography>
  </Box>
);

export default EditProfileModal;
