export const CartRoutes = {
  addToCart: "/cart/AddToCart",
  getCartDetails: "/cart/GetCartDetailsNew",
  removeProductFromCart: "/cart/DeleteItemFromCart",
  updateAddToCart: "/cart/EditAddToCart",
  setupPayerAuth: "customer/SetupPayerAuth",
  payerAuthenticationValidationService:
    "customer/PayerAuthenticationValidationService",
  placeOrder: "/user/addorder",
  payerAuthenticationCheckEnrollmentService:
    "customer/PayerAuthenticationCheckEnrollmentService",
};
