import Dashboard from "../../views/admin/dashboard";
import Login from "../../layout/login/index"
import ProductDetail from "../../views/admin/pages/StoreDetail";
import AddToCart from "../../views/admin/pages/ProductDetail/store";
import AllStores from "../../views/admin/pages/AllStore/index";
import SearchData from "../../views/admin/pages/SearchData/index";
import OrderList from "../../views/admin/pages/OrderStatus/index";
import OrderDetail from "../../views/admin/pages/OrderStatus/OrderDetail";
import ChatDiver from "../../views/admin/pages/OrderStatus/Chat"
import Wallet from "../../views/admin/pages/Wallet"
import Favorite from "../../views/admin/pages/Favorite"
import StoreDetail from "../../views/admin/pages/ShopDetail"
import SearchStore from "../../views/admin/pages/SearchStore"
import MygGift from "../../views/admin/pages/Gifts/MygGift";
import SendGift from "../../views/admin/pages/Gifts/SendGift"
import CustomizeGift from "../../views/admin/pages/Gifts/CustomizeGift"
import GiftCheckout from "../../views/admin/pages/Gifts/GiftCheckout";
import Home from "../../views/admin/dashboard/home";

const AdminRoutes = [
  {
    path: "/login",
    component: <Login />
  },
  {
    path: "/home",
    component: <Dashboard />
  },
  {
    path: "/store/detail/:id",
    component: <ProductDetail />
  },
  {
    path: "/shop/detail/:id",
    component: <StoreDetail />
  },
  {
    path: "/all-stores",
    component: <AllStores />
  },
  {
    path: "/product/detail/:id",
    component: <AddToCart />
  },
  {
    path: "/search-data",
    component: <SearchData />
  },
  {
    path: "/orders-list",
    component: <OrderList />
  },
  {
    path: "/order-details",
    component: <OrderDetail />
  },
  {
    path: "/Chat",
    component: <ChatDiver />
  },
  {
    path: "/wallet",
    component: <Wallet />
  },
  {
    path: "/favorites",
    component: <Favorite />
  },
  {
    path: "/searchStore",
    component: <SearchStore />
  },
  {
    path: "/my-gift",
    component: <MygGift />
  },
  {
    path: "/send-gift",
    component: <SendGift />
  },
  {
    path: "/customize-gift-card",
    component: <CustomizeGift />
  },
  {
    path: "/gift-checkout",
    component: <GiftCheckout />
  },


];

export default AdminRoutes;