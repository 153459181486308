import { ProductRoutes } from "./route";
import { post, get, put } from "../index";


const ProductServices = {
  getProductDetail: async (productId) => {
    let result = get(ProductRoutes.getProductDetail  + `?productId=${productId}`);
    return result;
  },
  getRecommendedProducts: async param => {
    let result = await get(
      ProductRoutes.getRecommendedProducts,
      param,
    );
   return result;

  },
};

export default ProductServices;
