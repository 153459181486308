import React, { useEffect, useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Divider,
  List,
  ListItem,
  Grid,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import OrderServices from "../../../api/CartServices/index";
import { useSelector } from "react-redux";
import Colors from "../../../assets/styles";
import CartServices from "../../../api/CartServices/index";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../assets/images";
import BreadCrumbProvider from '../../../hooks/useBreadCrumb';


const OrderDrawer = ({ isOpen, onClose, setBadge }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedShop, setSelectedShop] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [itemData, setItemData] = useState([]);
  const [subtotal, setSubTotal] = useState("");

  // const [cartData, setCartData] = useState([]);
  // const [quantities, setQuantities] = useState({});

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userData = useSelector((state) => state.userData.userData);
  const userId = userData.id;
  const navigate = useNavigate();

  const {cartData,setCartData ,badge,quantities,setQuantities,getOrderDetail} = BreadCrumbProvider()
  console.log(cartData)

  //   const getOrderDetail = async () => {
  //     try {
  //       const data = await OrderServices.getCartDetails(userId);
  //       setCartData(data);
  //       setBadge(data.length);
      
  //       // Initialize quantities for each item
  //       const initialQuantities = {};
  //       data.forEach((shop, shopIndex) => {
  //         shop.items.forEach((item, itemIndex) => {
  //           initialQuantities[`${shopIndex}-${itemIndex}`] = item.quantity;
  //         });
  //       });
  //       setQuantities(initialQuantities);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  // useEffect(() => {
  //   getOrderDetail();
  // }, []);



  const handleNextStep = (index, item) => {
    setSelectedShop(index);
    setSelectedData(index)
  
    setItemData(item);
    console.log(item);
    setCurrentStep(1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(0);
    setSelectedShop(null);
  };

  const handleIncrement = (shopIndex, itemIndex) => {
    const key = `${shopIndex}-${itemIndex}`;
    const newQty = quantities[key] + 1;
    updateCart(shopIndex, itemIndex, newQty);
  };

  const handleDecrement = (shopIndex, itemIndex) => {
    const key = `${shopIndex}-${itemIndex}`;
    const currentQty = quantities[key];
    if (currentQty > 1) {
      const newQty = currentQty - 1;
      updateCart(shopIndex, itemIndex, newQty);
    } else {
      handleDeleteItem(cartData[shopIndex].items[itemIndex].cart_id);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      const result = await CartServices.removeProductFromCart({ id });
      console.log("handleDeleteItem result:", result);
      setCurrentStep(0)
      await getOrderDetail();
    } catch (error) {
      console.log("handleDeleteItem error:", error);
    }
  };

  const updateCart = async (shopIndex, itemIndex, qty) => {
    try {
      const ingredientsArray = [];
      const selectedProduct = cartData[shopIndex]?.items?.[itemIndex];

      selectedProduct?.itemIngs.forEach((element) => {
        let ingredientObj = {
          ingredient_prop_id: element?.ingredient_prop_id,
          modifier: element?.modifier,
          value: element?.value ? element?.value * qty : 1 * qty,
        };
        ingredientsArray.push(ingredientObj);
      });

      const editCart = {
        id: selectedProduct?.cart_id,
        user_id: userData?.id,
        shop_address_id: 0,
        shop_id: selectedProduct?.shop_id,
        product_id: selectedProduct?.product_id,
        quantity: qty,
        note: "",
        ingredients: ingredientsArray,
      };

      const result = await CartServices.updateAddToCart(editCart);
      console.log("updateCart result:", result);

      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [`${shopIndex}-${itemIndex}`]: qty,
      }));
    } catch (error) {
      console.log("updateCart error:", error);
    } finally {
      console.log("updateCart finished");
      getOrderDetail();
    }
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Box
        sx={{
          width: {md:375,sm:375,xs:308},
          padding: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {currentStep === 0 ? "Cart" : "Order Details"}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={{ my: 2 }} />

          {currentStep === 0 ? (
            <List>
             {Array.isArray(cartData) && cartData.length > 0 ? (
  cartData.map((item, index) => (
    <ListItem key={index} sx={{ pb: 2, mb: 1, mt: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box onClick={() => handleNextStep(index, item)} sx={{ display: "flex", alignItems: "center", cursor: "pointer",width:"100%" }}>
          <img
            src={item?.based_on === "shop" ? baseUrl + "/docs/files/shops/" + item?.shopavtar : baseUrl + "/docs/files/stores/" + item?.shopavtar}
            alt={item?.name}
            style={{ width: 50, height: 50, marginRight: 10 }}
          />
          <Box sx={{width:"100%"}}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {item?.shopname}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {item?.items?.length + " item " + " - " + " $" + item?.total_pay}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {"Deliver to " + item?.addrsses.map((addr) => addr?.address).join(', ')}
            </Typography>
          </Box>
          <IconButton>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Box>
    </ListItem>
  ))
) : (
  <Box
  sx={{
    textAlign: "center",
    mt: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }}
>
  <img
    src={Images.Cart} // Replace with the correct path to your image
    alt="Empty Cart"
    style={{ width: 150, height: 150, marginBottom: 20 }}
  />
  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
    Add items to start a cart
  </Typography>
  <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
    Once you add items from a restaurant or store, your cart will appear
    here.
  </Typography>
  <Button
    variant="contained"
    sx={{
      backgroundColor: "#000",
      color: "#fff",
      textTransform: "none",
      padding: "10px 20px",
      borderRadius: "20px",
      ":hover": {
        backgroundColor: "#333",
      },
    }}
    onClick={onClose}
  >
    Start shopping
  </Button>
</Box>
)}

            </List>
          ) : (
            <Box>
              <IconButton
                onClick={handlePreviousStep}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  background: "none !important",
                }}
              >
                <ArrowBackIosIcon />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {cartData[selectedShop]?.shopname}
                </Typography>
              </IconButton>
              <Box>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {cartData[selectedShop]?.items?.length + " Items"}
                </Typography>
                </Box>
              {selectedShop !== null && (
                <>
                  <Box>
                    <List>
                      {cartData[selectedShop]?.items.map((item, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                          <ListItem>
                            <Box sx={{ width: "100%" }}>
                              <Grid container spacing={2}>
                                <Grid item xs={6} sx={{display:"flex",alignItems:"center"}}>
                                  <Box>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.name}
                                    </Typography>
                                  </Box>
                                </Grid>

                                <Grid item xs={6}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "70px",
                                        height: "70px",
                                        borderRadius: "50%",
                                        objectFit: "fill",
                                      }}
                                      src={
                                        baseUrl +
                                        "/docs/files/products/" +
                                        item?.pic
                                      }
                                      alt={item?.name}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={6}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      mt: 1,
                                      background: "#d8d8d8",
                                      borderRadius: "20px",
                                      padding: "0px 8px",
                                      width: "90px",
                                    }}
                                  >
                                    <IconButton
                                      onClick={() =>
                                        handleDecrement(selectedShop, index)
                                      }
                                    >
                                      {quantities[`${selectedShop}-${index}`] >
                                      1 ? (
                                        <RemoveIcon />
                                      ) : (
                                        <DeleteIcon />
                                      )}
                                    </IconButton>
                                    <Typography variant="body2">
                                      {quantities[`${selectedShop}-${index}`]}
                                    </Typography>
                                    <IconButton
                                      onClick={() =>
                                        handleIncrement(selectedShop, index)
                                      }
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{ fontSize: "18px", marginTop: "20px" }}
                                  >
                                    ${item?.price}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </ListItem>
                          {item?.itemIngs?.length > 0 && (
                            <Box sx={{ mt: 1, pl: 2 }}>
                              <Grid container spacing={1} sx={{ mt: 1 }}>
                                {item?.itemIngs?.map((ing, ingIndex) => (
                                  <Grid
                                    container
                                    item
                                    key={ingIndex}
                                    spacing={1}
                                  >
                                    <Grid item xs={6}>
                                      <Typography variant="body2">
                                        {ing?.modifier}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography variant="body2">
                                        {ing?.name}-( ${ing.price})
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Grid>
                            </Box>
                          )}
                          <Divider sx={{ my: 1 }} />
                        </Box>
                      ))}
                    </List>
                  </Box>
                  
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography sx={{ fontWeight: "700" }} variant="body2">
                        Sub-Total
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      <Typography sx={{ fontWeight: "700" }} variant="body2">
                        {/* {itemData.total_pay} */}
                        ${cartData[selectedShop]?.total_pay}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mt: 2,
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{
                        flex: 1,
                        background: Colors.black,
                        py: 1,
                        ":hover": {
                          background: Colors.black,
                        },
                      }}
                      onClick={() => {
                        navigate("/checkout", { state: {itemData:itemData,itemIndex:selectedData,fromReorder:false} });
                        onClose();
                      }}
                    >
                      Go to Checkout
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ flex: 1, background: Colors.primary, py: 1 }}
                      onClick={() => {
                        navigate(`/store/detail/${itemData?.shop_id}`);
                        onClose();
                      }}
                    >
                      Add Item
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default OrderDrawer;
