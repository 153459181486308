import { SearchDataRoutes } from "./route";
import { post, get, put } from "../index";


const SearchServices = {
  getSearchData: async (search,key,page,limit,shop_id,basedOn) => {
    let param = `?search=${search}&key=${key}&page=${page}&limit=${limit}`
    if(shop_id !=""){
      param += `&shop_id=${shop_id}&based_on=${basedOn}`
    }
    let result = get(SearchDataRoutes.getSearchData  + param);
    return result;
  },
  getStoreTypesForNav: async () => {
    let result = get(SearchDataRoutes.getStoreTypesForNav);
    return result;
  },

};

export default SearchServices;
