export const AuthRoutes = {
  login: "/login/LogIn",
  registerUrl: "/login/registerUser",
  validateUrl: "/login/Newvalidateotp_user",
  updateUserUUID: "/login/UpdateUUidNew",
  refreshToken: "/login/RefreshToken",
  changePinCode: "/login/ChangePinCode",
  deleteAccount: "/login/DeleteAccount",
  checkPhoneExist: "/login/CheckPhoneNo",
  sendOTP: "/login/SendOtp",
};
