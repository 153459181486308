import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  Badge,
  Grid,
  Box,
  Hidden,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  ListItemAvatar,
  Avatar,
  Collapse,
  useMediaQuery,
  useTheme,
  ToggleButtonGroup,
  ToggleButton,
  Container
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import {
  Menu as MenuIcon,
  ShoppingCart as ShoppingCartIcon,
  LocationOn as LocationOnIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Drawer from "../sidenav/index";
import { Images } from "../../../assets/images";
import "./Navbar.css";
import Colors from "../../../assets/styles";
import OrderDrawer from "../OrderDrawer/index";
import CategoriesServices from "../../../api/CategoriesServices";
import axios from "axios";
import SearchServices from "../../../api/SearchServices";
import ClearIcon from "@mui/icons-material/Clear";
import debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import Location from "../../../components/Location";
import SimpleDialog from "../../../components/Dialog";

import BreadCrumbProvider from "../../../hooks/useBreadCrumb";

const Search = styled("div")(({ theme }) => ({
  width: "100%",
  boxShadow: "0px 0px 10px 5px #c4c4c412 inset",
  position: "relative",
  borderRadius: "200px",
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.1),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "black",
}));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "black",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     paddingRight: theme.spacing(6), // Add space for the clear icon
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("xl")]: {
//       width: "80ch",
//     },
//     [theme.breakpoints.down("xl")]: {
//       width: "0ch",
//     },
//     [theme.breakpoints.down("sm")]: {
//       width: "0ch",
//     },
//     "&::placeholder": {
//       color: "#000000",
//     },
//   },
// }));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: theme.spacing(6), // Add space for the clear icon
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("xl")]: {
      width: "100%", // Full width for large screens
    },
    [theme.breakpoints.down("md")]: {
      width: "100%", // Adjust width for medium screens
    },
    [theme.breakpoints.down("sm")]: {
      width: "20ch", // Set a reasonable width for small screens
    },
    "&::placeholder": {
      color: "#000000",
    },
  },
}));

const SearchSuggestions = ({
  onClickOutside,
  filteredShopProducts,
  setFilteredShopProducts,
  searchResultData,
  setKey,
  searchText,
  fetchSearchData,
  setSearchSuggestionsOpen,
}) => {
  const [value, setValue] = useState(0);
  const [navType, setNavType] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedTab = navType[newValue];
    if (selectedTab) {
      setKey(selectedTab); // Update the key with the selected tab's name
    }
  };

  const getSearchTypeForNav = async () => {
    try {
      let result = await SearchServices.getStoreTypesForNav();
      setNavType(result);
    } catch (error) {
      console.log("Error fetching shop products:", error);
    }
  };

  useEffect(() => {
    getSearchTypeForNav();
  }, []);

  const handleListItemClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Paper
      style={{
        position: "absolute",
        width: "100%",
        height: "500px",
        overflow: "auto",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor={Colors.black}
        variant="scrollable" // Enables scrolling
        scrollButtons="auto" // Shows scroll buttons when needed
        aria-label="search category tabs"
        sx={{ fontSize: "10px" }}
      >
        {navType.map((item, index) => (
          <Tab key={index} label={item} />
        ))}
      </Tabs>

      {navType.map((item, index) => (
        <>
          {console.log(item)}
          <Collapse key={index} in={value === index} timeout="auto">
            <List component="nav" aria-label="search suggestions">
              {value === 1 && searchText != "" ? (
                // Conditionally render a single map if value is 1
                searchResultData.map((product) => (
                  <ListItem
                    button
                    onClick={() => {
                      navigate(`/store/detail/${product.shopid}`);
                    }}
                    key={product.id}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={baseUrl + "/docs/files/shops/" + product.avatar}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={product.name} />
                  </ListItem>
                ))
              ) : // Existing logic for other values of 'value'
                searchResultData.length > 0 && searchText !== "" ? (
                  searchResultData.map((dataItem) =>
                    dataItem.products.map((product) => (
                      <ListItem
                        button
                        onClick={() => {
                          setSearchSuggestionsOpen(false);
                          navigate(`/product/detail/${product.product_id}`);
                        }}
                        key={product.id}
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={baseUrl + "/docs/files/products/" + product.pic}
                          />
                        </ListItemAvatar>
                        <ListItemText primary={product.name} />
                      </ListItem>
                    ))
                  )
                ) : searchResultData.length === 0 && searchText !== "" ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                      height: "200px",
                    }}
                  >
                    No Data Found
                  </Box>
                ) : (
                  filteredShopProducts.map((product) => (
                    <ListItem
                      button
                      // onClick={handleListItemClick}
                      key={product.id}
                      onClick={() => {
                        setSearchSuggestionsOpen(false);
                        navigate("/home", { state: product });
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          src={baseUrl + "/docs/files/cuisine/" + product.pic}
                        />
                      </ListItemAvatar>
                      <ListItemText primary={product.name} />
                    </ListItem>
                  ))
                )}
            </List>
          </Collapse>
        </>
      ))}
    </Paper>
  );
};
// Styled components for the toggle slider and container with responsiveness
// const ToggleSlider = styled(Box)(({ transformX, width, height }) => ({
//   transition: "transform 300ms ease-in-out",
//   transform: `translateX(${transformX}%)`,
//   position: "absolute",
//   width: width, // Responsive width
//   height: height, // Responsive height
//   backgroundColor: "#ffffff", // Slider color
//   borderRadius: "20px", // Rounded corners for slider
// }));

// const ToggleButtonContainer = styled(Box)(({ width, height }) => ({
//   display: "flex",
//   position: "relative",
//   width: width, // Responsive width
//   height: height, // Responsive height
//   borderRadius: "20px",
//   backgroundColor: "#f0f0f0", // Background color for non-active area
//   overflow: "hidden",
//   border: "2px solid #aeaeae47", // Border color adjusted for visibility
// }));

// const ToggleOption = styled(Button)(({ active, fontSize }) => ({
//   flex: 1,
//   height: "100%",
//   borderRadius: "20px",
//   backgroundColor: "transparent", // Remove background color here
//   color: "black", // Black text for all states
//   fontSize: fontSize, // Responsive font size
//   "&:hover": {
//     backgroundColor: "rgba(255,255,255,0.3)", // Light hover effect
//   },
// }));

// const ToggleButton = ({
//   selectedType,
//   setSelectedType,
//   isTakeaway,
//   deliveryDisabled,
//   pickupDisabled,
// }) => {
//   const [transformX, setTransformX] = useState(0);
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Check for small screens

//   useEffect(() => {
//     if (pickupDisabled) {
//       setTransformX(0);
//     } else if (deliveryDisabled) {
//       setTransformX(50); // 50% of the container width to right side
//     } else {
//       setTransformX(selectedType === "Pickup" ? 100 : 0); // Correctly aligns with the options
//     }
//   }, [pickupDisabled, deliveryDisabled, selectedType]);

//   // Determine the width, height, and font size based on screen size
//   const containerWidth = isSmallScreen ? "120px" : "180px"; // Adjust width for smaller screens
//   const containerHeight = isSmallScreen ? "36px" : "36px"; // Adjust height for smaller screens
//   const sliderWidth = isSmallScreen ? "60px" : "90px"; // Adjust slider width accordingly
//   const sliderHeight = containerHeight; // Slider height should match container height
//   const fontSize = isSmallScreen ? "12px" : "16px"; // Adjust font size for smaller screens

//   return (
//     <>
//       <Box>
//         <Box>
//           <ToggleButtonContainer
//             width={containerWidth}
//             height={containerHeight}
//           >
//             <ToggleSlider
//               transformX={transformX}
//               width={sliderWidth}
//               height={sliderHeight}
//             />
//             <ToggleOption
//               disabled={deliveryDisabled}
//               active={selectedType === "Delivery"}
//               onClick={() => setSelectedType("Delivery")}
//               fontSize={fontSize}
//             >
//               Delivery
//             </ToggleOption>
//             <ToggleOption
//               active={selectedType === "Pickup"}
//               onClick={() => setSelectedType("Pickup")}
//               fontSize={fontSize}
//             >
//               Pickup
//             </ToggleOption>
//           </ToggleButtonContainer>
//         </Box>
//       </Box>
//     </>
//   );
// };

const CustomToggleButton = styled(ToggleButton)(({ theme, selected }) => ({
  textTransform: "none",
  fontWeight: 600,
  padding: "2px 16px",
  borderRadius: "50px !important",
  border: "1px solid transparent",
  color: "#000",
  transition: "background-color 0.3s ease, box-shadow 0.3s ease",
  width: "50%",
  "&.Mui-selected": {
    ":hover": {
      backgroundColor: "#ffffff",
    },
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 5px #d4d4d4",
  }
}));

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  borderRadius: "999px", // Rounded container for the buttons
  padding: "4px", // Slight padding to create space between buttons and edge
  display: "flex",
  alignItems: "center",
  gap: "4px",
}));

const ToggleButtonExample = ({
  selectedType,
  setSelectedType,
  isTakeaway,
  deliveryDisabled,
  pickupDisabled,
}) => {
  return (
    <CustomToggleButtonGroup
      value={selectedType}
      exclusive
    >
      <CustomToggleButton
        disabled={deliveryDisabled}
        active={selectedType === "Delivery"}
        onClick={() => setSelectedType("Delivery")}
        value="Delivery"
      >
        Delivery
      </CustomToggleButton>
      <CustomToggleButton
        active={selectedType === "Pickup"}
        onClick={() => setSelectedType("Pickup")}
        value="Pickup"
      >
        Pickup
      </CustomToggleButton>
    </CustomToggleButtonGroup>
  );
};

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchSuggestionsOpen, setSearchSuggestionsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const [filteredShopProducts, setFilteredShopProducts] = useState([]);
  const [searchResultData, setSearchResultData] = useState([]);
  const [isSearchFocused, setIsSearchFocused] = useState([]);
  const [key, setKey] = useState("All");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("token_id");
  const [activeStep, setActiveStep] = useState(0);
  // const [badge, setBadge] = useState(0);
  const [shopid, setShopid] = useState("");
  const [basedOn, setBasedOn] = useState("");
  const [dialog, setDialog] = useState(false);
  const [selectedType, setSelectedType] = useState("Delivery");
  const { setBadge, badge } = BreadCrumbProvider();

  const [shopProducts, setShopProducts] = useState([]);
  const searchTextRef = useRef(searchText);

  const userData = useSelector((state) => state.userData.userData);
  const userSelectedAddress = useSelector(
    (state) => state.userData.selectedAddress
  );
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [isOrderDrawerOpen, setIsOrderDrawerOpen] = useState(false);

  const handleOrderDrawerOpen = () => {
    setIsOrderDrawerOpen(true);
  };

  const handleOrderDrawerClose = () => {
    setIsOrderDrawerOpen(false);
  };

  const order = {
    restaurant: "Marugame Udon",
    items: [
      {
        name: "Chicken Katsu Rice",
        description:
          "Choose your Chicken Katsu Rice: Chicken Katsu Rice Large (£1.00)",
        quantity: 1,
        price: 12.95,
        image: Images.burger,
      },
    ],
    subtotal: 12.95,
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchSuggestionsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const getShopProductsHandler = async () => {
    try {
      let result = await CategoriesServices.getCategories();
      setShopProducts(result);
      setFilteredShopProducts(result); // Initially show all products
    } catch (error) {
      console.log("Error fetching shop products:", error);
    }
  };
  useEffect(() => {
    getShopProductsHandler();
  }, []);

  const fetchSearchData = useCallback(
    debounce(async (searchText) => {
      if (searchText.trim() === "") {
        setSearchResultData([]);
        setFilteredShopProducts(shopProducts);
        return;
      }

      try {
        let result = await SearchServices.getSearchData(
          searchText,
          key,
          page,
          limit,
          shopid ? shopid : "",
          basedOn ? basedOn : ""
        );
        setSearchResultData(result);
      } catch (error) {
        console.log("Error fetching search data:", error);
      }
    }, 1000),
    [key, page, limit, shopProducts]
  );

  useEffect(() => {
    // Update ref value to current searchText
    searchTextRef.current = searchText;
    fetchSearchData(searchText);
  }, [searchText, fetchSearchData, shopProducts]);

  const handleSearchTextChange = (event) => {
    const text = event.target.value;
    if (text.trim() === "") {
      setSearchText("");
      setSearch(""); // Reset search payload
      setFilteredShopProducts(shopProducts);
    } else {
      setSearch(text);
      setSearchText(text);
      setFilteredShopProducts([]);
    }
    setSearchSuggestionsOpen(true);
  };

  const isSmallScreen = useMediaQuery("(max-width:1068px)");
  return (
    <>
      <AppBar
        position="fixed"
        className="navbar"
        style={{ backgroundColor: "white", boxShadow: "none" }}
      >
        <Container sx={{ maxWidth: "1300px !important" ,pt:{md:"0px",xs:"6px"},pb:{md:"0px",xs:"6px"}}}>
          <Toolbar sx={{ p: "0 !important" }}>
            <Grid container alignItems="center " sx={{justifyContent:{md:"flex-start",sm:"flex-start",xs:"center"}}} spacing={{ xs: 1, sm: 1, md: 2 }}>
              <Grid item md={0.4} sm={0.4} xs={1.2}>
                <IconButton
                  edge="start"
                  color="black"
                  aria-label="menu"
                  onClick={toggleDrawer}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item md={0.8} sm={0.8} xs={1.8}>
                <Box className="logo" onClick={() => navigate("/")}>
                  <img src={Images.logo} alt="logo" className="\" style={{ width: "100%" }} />
                </Box>
              </Grid>
              <Grid item md={1.85} sm={3} xs={userData?.id ? 6 :9}>
                {/* <ToggleButton
                selectedType={selectedType}
                setSelectedType={setSelectedType}
              /> */}
                <ToggleButtonExample
                  selectedType={selectedType}
                  setSelectedType={setSelectedType}
                />
              </Grid>
              {userData?.id && (
                <>
                  <Grid
                    item
                    md={userSelectedAddress?.city ? 1.3 : 0.55}
                    sm={userSelectedAddress?.city ? 1.7 : 0.55}
                    xs={userSelectedAddress?.city ? 3 : 2.3}
                  >
                    <Box
                      className="location"
                      sx={{ cursor: "pointer" }}
                      onClick={() => setDialog(true)}
                    >
                      <LocationOnIcon style={{ color: "black", fontSize: "18px" }} />
                      <Typography variant="body2" style={{ color: "black" }}>
                        {userSelectedAddress?.city}
                      </Typography>
                      <ExpandMoreIcon style={{ color: "black" }} />
                    </Box>
                  </Grid>
                </>
              )}
              <Grid
                item
                md={!userData?.id ? 7.9 : !userSelectedAddress?.city ? 7.6:7}
                sm={!userData?.id ? 6 : !userSelectedAddress?.city? 6:5}
                xs={!userData?.id ? 9 : 10}
              >
                <Box sx={{
                  display: "flex",
                  width: "100%"
                }} ref={searchRef}>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      fullWidth
                      placeholder={"Search Let's Eat"}
                      inputProps={{ "aria-label": "search" }}
                      value={searchText}
                      onChange={handleSearchTextChange}
                      onClick={() => {
                        setIsSearchFocused(true);
                        setSearchSuggestionsOpen(true);
                      }}
                    />

                    {searchText && (
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <IconButton
                          onClick={() => {
                            navigate("/search-data", { state: searchResultData });
                            setSearchSuggestionsOpen(false);
                          }}
                          style={{
                            position: "absolute",
                            right: 30,
                            top: 0,
                            bottom: 0,
                            margin: "auto",
                            height: "100%",
                          }}
                        >
                          <SearchIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setSearchText("");
                          }}
                          style={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            bottom: 0,
                            margin: "auto",
                            height: "100%",
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    )}
                    {searchSuggestionsOpen && (
                      <SearchSuggestions
                        key={searchText}
                        setFilteredShopProducts={setFilteredShopProducts}
                        filteredShopProducts={filteredShopProducts}
                        searchResultData={searchResultData}
                        fetchSearchData={fetchSearchData}
                        searchText={searchText}
                        setKey={setKey}
                        setSearchSuggestionsOpen={setSearchSuggestionsOpen}
                      />
                    )}
                  </Search>
                </Box>
              </Grid>
              {!token && !userData?.id ? (
                <Grid item md={1}>
                  <Button
                    onClick={() => navigate("/login")}
                    sx={{
                      textTransform: "none",
                      border: "1px solid black",
                      color: Colors.black,
                      borderRadius: "20px",
                      padding: isSmallScreen ? "2px 10px" : "2px 14px", // Adjust padding based on screen size
                      fontSize: isSmallScreen ? "12px" : "14px", // Adjust font size for smaller screens
                    }}
                  >
                    Log in
                  </Button>
                </Grid>
              ) : (
                <Grid item md={0.55}>
                  <div className="navbar-right">
                    <IconButton
                      color="inherit"
                      type="button"
                      onClick={handleOrderDrawerOpen}
                    >
                      <Badge badgeContent={badge} color="primary">
                        <ShoppingCartIcon style={{ color: "black" }} />
                      </Badge>
                    </IconButton>
                  </div>
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      <OrderDrawer
        isOpen={isOrderDrawerOpen}
        badge={badge}
        setBadge={setBadge}
        onClose={handleOrderDrawerClose}
        order={order}
      />
      {dialog && (
        <>
          <SimpleDialog
            open={dialog}
            onClose={() => setDialog(false)}
            title="Select Address"
          >
            <Box>
              <Location setDialog={setDialog} />
            </Box>
          </SimpleDialog>
        </>
      )}
    </>
  );
};

export default Navbar;
