import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Backdrop,
  CircularProgress,
  TextField,
  Button,
  Typography,
  Box,
  Container,
  FormHelperText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthServices from "../../../api/AuthServices/auth.index";
import OTPInput from "../../../components/OTPINPUT";
import PhoneInput from "react-phone-input-2";
import { FormControl } from "@mui/base";
import SimpleDialog from "../../../components/Dialog";
import Colors from "../../../assets/styles";
import { setUserData } from "../../../redux/slices/UserDataSlices";
import { useDispatch } from "react-redux";

const GoogleAuth = ({ data }) => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0); // 0: Phone Entry, 1: OTP Verification
  const [country, setCountry] = useState(null);
  const [loader, setLoader] = useState(false);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [otpCode, setOTPCode] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [phoneNumber, setPhoneNumber] = useState("");
  const getDataFromLocal = localStorage.getItem("googleCredentials");
  const token = localStorage.getItem("token_id");
  const parsedData = JSON.parse(getDataFromLocal);
  const [firstName, lastName] = parsedData.displayName.split(/ (.*)/);
 
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const handleBackButtonClick = () => {
    navigate("/getStarted");
  };

  const checkPhoneExist = async (formData) => {
    let phoneNumber = String(formData.phone || "");
    let PhNumber = "+" + phoneNumber;
    try {
      let obj = {
        phone: PhNumber,
        login_by: "social",
      };

      let result = await AuthServices.checkPhoneExist(obj);
      setLoader(false);

      if (result?.status == "0") {
        setShowBottomSheet(true);
      } else if (token && token != null) {
        navigate("/home")
      }
    } catch (error) {
      setLoader(false);
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  const sendOTP = async (formData) => {
    setCurrentStep(1); // Move to OTP step
    setShowVerification(true);
    setShowBottomSheet(false);
    setSeconds(60);
    let PhNumber = "%2B" + phoneNumber;
    try {
      let obj = {
        phone: PhNumber,
      };
      let result = await AuthServices.sendOTP(PhNumber);
      setLoader(false);

      if (result?.status) {
        alert(result?.message);
      }
    } catch (error) {
      setLoader(false);
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  const validateOTP = async (formData) => {
    if (otpCode?.length < 6) {
      alert("Enter 6 digits OTP");
      return;
    }
    let phoneNumber = String(formData.phone || "");
    let PhNumber = "+" + phoneNumber;

    var obj = { phone: PhNumber, otp: otpCode };
    try {
      let res = await AuthServices.validateUrl(obj);
      if (res.status == 1) {
        googleLogin(formData);
      } else {
        console.log(res?.message);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  const googleLogin = async (formData) => {
    let phoneNumber = String(formData.phone || "");
    let PhNumber = "+" + phoneNumber;

    try {
      let obj = {
        name: formData.firstName + " " + formData.lastName,
        phone: PhNumber,
        email: parsedData?.providerData[0]?.email,
        socialAuth: parsedData?.providerData[0]?.uid,
        social_type: 'google',
        nonce: parsedData?.providerData[0]?.uid,
        login_by: "social",
      };
      let result = await AuthServices.registerUrl(obj);
      localStorage.setItem("token_id", result?.token);
      dispatch(setUserData(result));
      navigate("/home");
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };


  useEffect(() => {
    setValue("firstName", firstName);
    setValue("lastName", lastName);
  }, [setValue, firstName, lastName]);

  useEffect(() => {
    var myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (seconds === 0) {
        clearInterval(myInterval);
      } else {
        setSeconds(10);
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });
  return (
    <Container component="main" maxWidth="xs">
      <Backdrop open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop={8}
      >
        {currentStep === 0 && (
          <>
            <Box sx={{ fontWeight: "bold", fontSize: "20px", width: "350px" }}>
              Enter your Basic information below
            </Box>
            <form onSubmit={handleSubmit(checkPhoneExist)} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="firstName"
                placeholder="First Name"
                name="firstName"
                autoComplete="firstName"
                autoFocus
                {...register("firstName", {
                  required: "First name is required",
                })}
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName.message : ""}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="lastName"
                placeholder="Last Name"
                name="lastName"
                autoComplete="lastName"
                {...register("lastName", { required: "Last name is required" })}
                error={!!errors.lastName}
                helperText={errors.lastName ? errors.lastName.message : ""}
              />
              <FormControl
                sx={{
                  ".form-control": {
                    height: "56px !important",
                    background: "transparent !important",
                  },
                }}
                error={!!errors.phone}
                fullWidth
              >
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: "Phone number is required",
                    validate: (value) =>
                      value.trim() !== "" || "Phone Number is required",
                  }}
                  render={({ field }) => (
                    <PhoneInput
                      country={"ky"} // Set default country to Pakistan
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                        setPhoneNumber(value);
                      }}
                      inputStyle={{ width: "100%", height: "56px" }}
                      countryCodeEditable={false}
                      enableSearch={true} // Enable country search
                      preferredCountries={["ky"]} // Add Pakistan to preferred countries
                      enableTerritories={true} // Enable territories
                    />
                  )}
                />
                <FormHelperText sx={{ color: Colors.red }}>
                  {errors.phone ? errors?.phone?.message : ""}
                </FormHelperText>
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ margin: "24px 0 16px" }}
              >
                Continue
              </Button>
            </form>
          </>
        )}
        {currentStep === 1 && (
          <>
            <Box sx={{ fontWeight: "bold", fontSize: "20px", width: "350px" }}>
              Enter the 6-digit Code sent to you at {"+" + phoneNumber}
            </Box>
            <Box sx={{ mt: 2 }}>
              <OTPInput
                length={6}
                value={otpCode}
                onChange={setOTPCode}
                focusInputIndex={0} 
                separator={<span>-</span>}
              />
              <Box sx={{ display: "flex", gap: "10px", mt: 2 }}>
                {seconds > 0 ? (
                  <Box
                    sx={{
                      mr: 2,
                      borderRadius: "30px",
                      padding: "8px 16px",
                      background: Colors.grey,
                      color: "rgb(120 120 120)",
                    }}
                  >
                    I haven't received a code {seconds}
                  </Box>
                ) : (
                  <Button
                    sx={{
                      mr: 2,
                      borderRadius: "30px",
                      padding: "8px 16px",
                      background: Colors.grey,
                      color: Colors.black,
                      fontWeight: "bold",
                      ":hover": {
                        background: Colors.grey,
                      },
                    }}
                    onClick={sendOTP}
                  >
                    Resend OTP
                  </Button>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 4,
                }}
              >
                <Button
                  sx={{
                    mr: 2,
                    borderRadius: "30px",
                    padding: "8px 16px",
                    background: Colors.grey,
                    color: Colors.black,
                    fontWeight: "bold",
                    ":hover": {
                      background: Colors.grey,
                    },
                  }}
                  onClick={handleBack}
                >
                  Back
                </Button>
                <Button
                  sx={{
                    background: Colors.black,
                    color: Colors.white,
                    borderRadius:"20px",
                    ":hover": {
                      background: Colors.black,
                    },
                  }}
                  onClick={handleSubmit(validateOTP)}
                >
                  Verify OTP
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
      {showBottomSheet && (
        <SimpleDialog
        maxWidth="xs"
          open={showBottomSheet}
          onClose={() => setShowBottomSheet(false)}
          title=""
        >
          <Box sx={{ fontWeight: "bold" }}>
            It looks like this number is already associated with another account
            on Let's Eat.
          </Box>
          <Box sx={{ fontWeight: "200", mt: 2 }}>
            You need to verify your identity by using OTP to link this account
            with the one we have.
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button
              sx={{
                background: "#eeeeee",
                ":hover": {
                  background: "#eeeeee",
                },
              }}
              onClick={() => setShowBottomSheet(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={sendOTP}
              sx={{
                background: Colors.primary,
                color: Colors.white,
                ":hover": {
                  background: Colors.primary,
                },
              }}
            >
              Continue
            </Button>
          </Box>
        </SimpleDialog>
      )}
    </Container>
  );
};

export default GoogleAuth;
