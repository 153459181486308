import React from "react";
import { Grid } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./sideArrow.css"

export default function SliderComponent({ slidesToShow, slidesToScroll, children, prevArrowClassName, nextArrowClassName, className }) {
  console.log("🚀 ~ SliderComponent ~ nextArrowClassName:", nextArrowClassName)
  console.log("🚀 ~ SliderComponent ~ prevArrowClassName:", prevArrowClassName)
  const settings = {
  dots: false,
  infinite: true,
  speed: 3000,  
  autoplay: true,
  autoplaySpeed: 0, 
  cssEase: "linear",  
  slidesToShow: slidesToShow,
  slidesToScroll: 1,  
  initialSlide: 0,
  prevArrow: <div className={prevArrowClassName} />,
  nextArrow: <div className={nextArrowClassName} />,
  responsive: [
    {
      breakpoint: 1030,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,  // You want to scroll only one slide at a time for better effect.
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 980,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,  // Adjust here as well to keep the flow continuous.
        initialSlide: 3,
      },
    },
    {
      breakpoint: 763,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,  // Adjust to scroll one at a time.
      },
    },
    {
      breakpoint: 463,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};


  return (
    <>
      <Grid container justifyContent="space-between" sx={{ padding: "10px" }}></Grid>
      <Slider className={className} {...settings}>{children && React.Children.count(children) > 0 ? children : <div></div>}
      </Slider>
    </>
  );
}
