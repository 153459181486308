import { CategoryRoutes } from "./route";
import { post, get, put } from "../index";


const CategoriesServices = {
    getCategories: async () => {
    let result = get(CategoryRoutes.getCategories );
    return result;
  },
    getCategoriesShopDetail : async (shopid,day,time,basedOn,TypeID,userId) => {
    let result = get(CategoryRoutes.getCategoriesShopDetail +`?shopid=${shopid}&day=${day}&time=${time}&based_on=${basedOn}&TypeID=${TypeID}&user_id=${userId}` );
    return result;
  },

};

export default CategoriesServices;
