export const profileEndpoints = {
    getUserProfile: '/customer/GetProfile',
    getCustomercard: '/customer/GetCards',
    getWalletDetails: '/customer/GetWalletBalance',
  
    //dispute
    getDisputeDetails: '/data/GetDisputeDetails',
    addDispute: '/customer/adddispute',
    createTicket: '/customer/CreateTicket',
    uploadFile: '/docs/UploadFiles',
    editProfile: '/customer/UpdateProfile',
    getProfile: '/customer/GetProfile',
  };
  