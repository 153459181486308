import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { orderApiCall } from "../../../../api/OrderServices";
import { ErrorToaster } from "../../../../components/Toaster";
import Colors from "../../../../assets/styles";
import { Images } from "../../../../assets/images";
import PhoneIcon from "@mui/icons-material/Phone";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setConversation } from "../../../../redux/slices/chatSlice";
import cookingVideo from "../../../../assets/images/cooking.mp4";
import { paymentApiCall } from "../../../../api/PaymentServices/index";
import { profileApiCall } from "../../../../api/ProfileServices/index";
import moment from "moment";
import LiveOrderTrackingView from "./LiveOrderTracking";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DriverTip from "./DriverTip";
import SimpleDialog from "../../../../components/Dialog";
import { ArrowBackIosNew } from "@mui/icons-material";
import { saveAs } from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';


export default function OrderDetail() {
  const [loader, setLoader] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [show, setShow] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [orderPreparationTime, setOrderPreparationTime] = useState(20);
  const [orderDetails, setOrderDetails] = useState({});
  const [selectedCustCard, setSelectedCustCard] = useState("");
  const [customerCards, setCustomerCards] = useState([]);
  const [followDriver, setFollowDriver] = useState(true);
  const [latestArrivalTime, setLatestArrivalTime] = useState(null);
  const [selectedTipAmount, setSelectedTipAmount] = useState(10);
  const [paymentMethodDialog, setPaymentMethodDialog] = useState(false);
  const [driverLocation, setDriverLocation] = useState({
    latitude: 0.0,
    longitude: 0.0,
  });
  const [selectedTipBtn, setSelectedTipBtn] = useState(2);
const baseUrl = process.env.REACT_APP_BASE_URL
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const [region, setRegion] = useState({
    latitude: 19.35304730104592,
    latitudeDelta: 0.5692290568259217,
    longitude: -81.24708412215114,
    longitudeDelta: 0.3588731959462308,
  });
  const [estimatedArrivalTime, setEstimatedArrivalTime] = useState(null);
  const [customerAddress, setCustomerAddress] = useState({
    latitude: 0.0,
    longitude: 0.0,
  });
  const [creatingConversation, setCreatingConversation] = useState(false);
  const [activeStep, setActiveStep] = useState(0); // Stepper state

  const labels = [
    "Order Placed",
    "Order Confirmed",
    "Order Shipped",
    "Out for Delivery",
    "Order Delivered",
  ];

  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mapRef = useRef(null);

  console.log(state);

  const userData = useSelector((state) => state.userData.userData);

  const tipPaymentHandler = async () => {
    setLoader(true);
    let obj = {
      order_id: state,
      user_id: userData?.id,
      amount: selectedTipAmount,
      card_id: selectedCustCard ? selectedCustCard : 0,
      currency: "KYD",
      pm: selectedCustCard ? "CARD" : "WALLET",
    };
    console.log("🚀 ~ file: index.js:507 ~ tipPaymentHandler ~ obj:", obj);
    try {
      const result = await paymentApiCall.tipPayment(obj);
      getOrderDetails();
      setShow(false);
      setLoader(false);
    } catch (error) {
      console.log(
        "🚀 ~ file: index.js:514 ~ tipPaymentHandler ~ error:",
        error
      );
      setLoader(false);
      ErrorToaster(error);
    }
  };

  const getCustomerCards = async () => {
    setLoader(true);
    try {
      const result = await profileApiCall.getCustomerCards({
        user_id: userData?.id,
      });
      setCustomerCards(result);
      if (customerCards != undefined && customerCards?.length > 0) {
        setSelectedCustCard(customerCards[0]);
      }
      console.log("customerCards", customerCards);
    } catch (error) {
      ErrorToaster(error);
      console.log("file: index.js:37 => getCartData => error:", error);
    }
  };

  const renderDriverDetails = () => (
    <Box
      sx={{
        flexWrap: "wrap",
        padding: "15px 10px",
        backgroundColor: "white",
        marginBottom: 2,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {/* Driver avatar and bike view */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          gap: "80px",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Avatar
            src={""}
            alt="Driver"
            sx={{
              width: 70,
              height: 70,
              border: 1,
              borderColor: "white",
              zIndex: 99,
            }}
          />
          <Box
            component="img"
            src={Images.bike}
            sx={{
              width: 70,
              height: 65,
              position: "absolute",
              left: "45px",
              top: "10px",
            }}
          />
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center">
          <Box
            component="img"
            src={Images.relaxingWalk}
            sx={{ width: 15, height: 15, marginX: 1, color: "primary.main" }}
          />
          <Box display="flex" flexDirection="column" justifyContent="flex-end">
            <Typography
              sx={{ fontFamily: "medium", color: "primary.main", marginTop: 1 }}
            >
              {orderDetails?.driver?.driver ?? ""}
            </Typography>
            <Typography
              sx={{ fontFamily: "light", color: "black", fontSize: 12 }}
            >
              Bike
            </Typography>
          </Box>

          <Typography
            sx={{
              textAlignVertical: "center",
              fontFamily: "semiBold",
              fontSize: 5,
              paddingX: 1,
            }}
          >
            {" \u26AB "}
          </Typography>
          <Typography
            sx={{ textAlignVertical: "center", fontFamily: "semiBold" }}
          >
            {orderDetails?.driver?.driver_code ?? ""}
          </Typography>
        </Box>
      </Box>

      {/* Call and chat View */}
      <Box display="flex" flexDirection="row" alignItems="center" gap="10px" mt={2}>
        <Tooltip title={orderDetails?.driver?.driverphone}>
          <IconButton
            sx={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              backgroundColor: "#f2f2f2",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              window.location.href = `tel:${orderDetails?.driver?.driverphone}`;
            }}
          >
            <PhoneIcon />
          </IconButton>
        </Tooltip>

        {/* Send message view */}
        {/* <Button
          sx={{
            height: 40,
            borderRadius: 20,
            backgroundColor: "#f2f2f2",
            color: Colors.black,
            justifyContent: "center",
            paddingX: 4,
            marginX: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          onClick={() => {
            createConversation(state);
          }}
        >
          Send a message
          {creatingConversation && (
            <CircularProgress size={20} color="primary" />
          )}
        </Button> */}

        {/* Tip button */}
        <Button
          sx={{
            height: 40,
            borderRadius: 20,
            backgroundColor:
              orderDetails?.tip + orderDetails?.tip1 > 0
                ? "#f2f2f2"
                : "#f2f2f2",
            justifyContent: "space-evenly",
            alignItems: "center",
            paddingX: 2,
            display: "flex",
            flexDirection: "row",
          }}
          onClick={() => setShow(!show)}
        >
          {orderDetails?.tip + orderDetails?.tip1 > 0 ? (
            <CheckCircleIcon sx={{ color: Colors.primary }} />
          ) : (
            <AddIcon sx={{ color: Colors.black }} />
          )}
          <Typography
            sx={{
              color:
                orderDetails?.tip + orderDetails?.tip1 > 0 ? Colors.primary : "black",
            }}
          >
            {orderDetails?.tip + orderDetails?.tip1 > 0 ? "Tipped" : "Tip"}
          </Typography>
        </Button>
      </Box>
    </Box>
  );

  const createConversation = async (orderid) => {
    setCreatingConversation(true);
    let obj = {
      orderId: orderid,
      customerId: userData?.id,
      driverId: orderDetails?.driver?.driver_id,
      customerName: userData?.name,
      driverName: orderDetails?.driver?.driver,
    };
    axios
      .post(
        "https://letseatchat.azurewebsites.net/api/conversations/createConversation",
        obj
      )
      .then((response) => {
        console.warn(response);
        var conversation = response?.data?.data;
        dispatch(setConversation(conversation));
        navigate("/Chat", { state: orderDetails?.driver?.driver_id });
      })
      .catch((ex) => {
        ErrorToaster(
          "Unable to start conversation with " +
            orderDetails?.driver?.driver +
            " at the moment"
        );
        console.warn(ex);
      })
      .finally(() => {
        setCreatingConversation(false);
      });
  };

  const getOrderDetails = async () => {
    setLoader(true);
    try {
      const result = await orderApiCall.getOrderDetails({ order_id: state });
      console.log("🚀 ~ file: index.js:481 ~ getOrderDetails ~ result:", result);
      setOrderPreparationTime(result?.[0]?.order_ready_time ?? 20);
      setOrderDetails(result?.[0]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      ErrorToaster(error);
    }
  };
  useEffect(() => {

    if (initialLoad) {
      getOrderDetails(); 
      setInitialLoad(false);
    }

    const interval = setInterval(() => {
      getOrderDetails();
    }, 30000); 

    return () => clearInterval(interval); 
  }, [initialLoad, state]);
  
  

 
 
  useEffect(() => {
    const interval = setInterval(() => {
      if (orderDetails?.status === "DELIVERING") {
        console.log("i am called before driver location");
        getDriverLocation();
      } else {
        clearInterval(interval);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [orderDetails, followDriver]);

  const getDriverLocation = async () => {
    // Implement your driver location fetching logic here
  };

  useEffect(() => {
    // getOrderDetails(state);
    getCustomerCards();
  }, []);

  useEffect(() => {
    if (orderDetails?.status === "DELIVERING") {
      getDriverLocation();
    } else if (
      orderDetails?.status === "ACCEPTED" ||
      orderDetails?.status === "PROCESSING" ||
      orderDetails?.status === "ORDERED"
    ) {
      setEstimatedArrivalTime(
        "Will be prepared around " +
          moment(orderDetails?.created_at)
            .add(orderPreparationTime, "minutes")
            .format("hh:mm")
      );
      setLatestArrivalTime(
        "We received your order at " +
          moment(orderDetails?.created_at).format("hh:mm")
      );
    } else {
      setEstimatedArrivalTime(null);
      setLatestArrivalTime(null);
    }
  }, [orderDetails]);


  const loadHtml = () => {
    const html = `
    <html>
      <head>
        <meta name="viewport" content="width=device-width">
        <link href="/Content/publicCSS/bootstrap.min.css" rel="stylesheet">
        <link href="/Content/publicCSS/font-awesome.min.css" rel="stylesheet">
        <link href="/Content/publicCSS/ionicons.min.css" rel="stylesheet">
        <link href="/Content/publicCSS/materialdesignicons.min.css" rel="stylesheet">
        <link href="/Content/publicCSS/slick.css" rel="stylesheet">
        <link href="/Content/publicCSS/slick-theme.css" rel="stylesheet">
        <link href="/Content/toastr.css" rel="stylesheet">
        <link href="/Content/animate.min.css" rel="stylesheet">
        <link href="/Content/publicCSS/owl.carousel.min.css" rel="stylesheet">
        <link href="/Content/publicCSS/owl.theme.default.min.css" rel="stylesheet">
        <link href="/Content/publicCSS/aos.css" rel="stylesheet">
        <link href="/Content/publicCSS/publicCSS.min.css" rel="stylesheet">
        <title>Order# ${orderDetails?.orderid}</title>
        <style>
            .OrderHistory {
                position: relative;
                width: 100%;
            }

            .OrderDetails {
                position: relative;
                width: 100%;
                margin-bottom: 20px;
            }

            .OrderDetails b {
                width: 100% !important;
                display: inline-block;
            }

            .table {
                width: 100% !important;
                max-width: 100%;
                margin-bottom: 20px;
                background-color: red; /* Ensuring red background */
            }

            .table th, .table td {
                padding: 12px;
                vertical-align: top;
                border-top: 1px solid #dee2e6;
            }

            .table thead th {
                background: #5f5f5f !important;
                color: white;
                -webkit-print-color-adjust: exact;
            }

            .OrderLogo {
                position: absolute;
                width: 100px;
                right: 10px;
                top: 10px;
            }

            .noPrint {
                display: none;
            }

            .table-condensed {
                width: 100% !important;
            }

            @media print {
                html, body {
                    font-size: 110%;
                    -webkit-print-color-adjust: exact;
                }

                .noPrint, .modal-header {
                    display: none;
                }

                .table thead th {
                    color: white !important;
                }

                .OrderLogo {
                    position: absolute;
                    width: 70px;
                    right: 10px;
                    top: 10px;
                }
            }
        </style>
      </head>
      <body onload="setTimeout(() => { window.print(); }, 500);">
        <div class="OrderHistory" id="PrintSection">
          <div class="OrderDetails">
              <b>OrderID</b>: ${orderDetails?.orderid}<br>
              <b>Restaurant</b>: ${orderDetails?.shop?.shopname}<br>
              <b>Customer</b>: ${orderDetails?.customer?.customer}<br>
              <b>Address</b>: ${orderDetails?.customer?.custactualaddress}<br>
              <b>Landmark</b>: ${orderDetails?.customer?.custaddress}<br>
              <b>Phone</b>: ${orderDetails?.customer?.custphone}<br>
              <b>Date</b>: ${moment(orderDetails?.customer?.create_at).format('MM/DD/yyyy hh:mm:ss a')}<br>
              <b>Type</b>: ${orderDetails?.takeaway == 0 ? 'DELIVERY' : 'TAKEAWAY'}<br>
              <b>Status</b>: ${orderDetails?.status}<br>
              <b>Notes</b>: ${orderDetails?.note}
          </div>       
          <b>Items List:</b>
          <table class="table table-condensed table-bordered" style="width: 100% !important;">
              <thead>
                  <tr>
                      <th style="text-align: center;">Name</th>
                      <th style="text-align: center;">Price</th>
                      <th style="text-align: center;">Qty</th>
                      <th style="text-align: center;">ExtPrice</th>
                  </tr>
              </thead>
              <tbody>
                  ${renderItemHtml()}
                  <tr>
                      <td colspan="3" class="text-right"><b>Food Total</b></td>
                      <td class="text-right"><b>${orderDetails?.foodtotal}</b></td>
                  </tr>
                  <tr>
                      <td colspan="3" class="text-right"><b>Discount</b></td>
                      <td class="text-right"><b>${orderDetails?.discount}</b></td>
                  </tr>
                  <tr>
                      <td colspan="3" class="text-right"><b>Promo Amount</b></td>
                      <td class="text-right"><b>${orderDetails?.promocode_amount}</b></td>
                  </tr>
                  <tr>
                      <td colspan="3" class="text-right"><b>Delivery Charge</b></td>
                      <td class="text-right"><b>${orderDetails?.delfee}</b></td>
                  </tr>
                  <tr>
                      <td colspan="3" class="text-right"><b>Small Order Fee</b></td>
                      <td class="text-right"><b>${orderDetails?.small_order_fee}</b></td>
                  </tr>
                  <tr>
                      <td colspan="3" class="text-right"><b>Distance Fee</b></td>
                      <td class="text-right"><b>${orderDetails?.distance_fee}</b></td>
                  </tr>
                  <tr>
                      <td colspan="3" class="text-right"><b>Tip</b></td>
                      <td class="text-right"><b>${orderDetails?.tip}</b></td>
                  </tr>
                  <tr>
                      <td colspan="3" class="text-right"><b>Tip After</b></td>
                      <td class="text-right"><b>${orderDetails?.tip1}</b></td>
                  </tr>
                  <tr>
                      <td colspan="3" class="text-right"><b>Total</b></td>
                      <td class="text-right"><b>${orderDetails?.total_pay}</b></td>
                  </tr>
                  <tr>
                      <td colspan="4">Notes: ${orderDetails?.note}</td>
                  </tr>
              </tbody>
          </table>
        </div>
      </body>
    </html>`;
    return html;
};


const renderItemHtml = () => {
  var format = '';

  orderDetails?.items?.forEach(element => {
    format += `<tr>
      <td>${element?.name}<br>Notes: </td>
      <td>${element?.price}</td>
      <td>${element?.quantity}</td>
      <td>${element?.quantity * element?.price}</td>
    </tr>`;
    element?.ingredients?.forEach(ingredients => {
      format += `<tr>
        <td colspan="4" class="ingrTable">
          <table class="table w-100">
            <tbody>
              <tr>
                <td>
                  <span>&#8226; ${ingredients?.name}</span>
                </td>
                <td>${ingredients?.price}</td>
                <td>${ingredients?.value}</td>
                <td>${ingredients?.value * ingredients?.price}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>`;
    });
  });

  return format;
};


  const createPDF = async (htmlContent) => {
    // Convert HTML content to PDFMake format
    const pdfContent = htmlToPdfmake(htmlContent);
    
    const docDefinition = {
        content: pdfContent,
    };

    // Generate the PDF
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    // Download the PDF or Share it
    pdfDocGenerator.getBlob((blob) => {
        saveAs(blob, `Let's Eat Order Receipt.pdf`);
    });
};
  return (
    <Container>
      <Box sx={{ mt: 12 ,display:"flex" ,gap:"10px",alignItems:"center"}}>
      <Box sx={{background:Colors.grey,borderRadius:"50%",width:"40px"}}>
              <IconButton onClick={()=>navigate(-1)}> 
                <ArrowBackIosNew />
              </IconButton>
            </Box>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Order Detail
        </Typography>
      </Box>
      {loader ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress size={50} color="primary" />
        </Box>
      )  : (
        <Box>
          <Box sx={{ mt: 2 }}>
            <LiveOrderTrackingView
              orderStatus={orderDetails?.status}
              arrivingAt={
                estimatedArrivalTime != null ? estimatedArrivalTime : ""
              }
              latestArrival={latestArrivalTime != null ? latestArrivalTime : ""}
              shopName={orderDetails?.shopname}
              orderDetail={orderDetails}
            />
          </Box>
   
   {show && (
    <>
    <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            px: 2,
            py: 2,
            borderRadius:'20px'
          }}
        >
          {/* Replace with your DriverTip component */}

          {/* <SimpleDialog
            open={show}
            onClose={() => setShow(false)} // Close the Show when the background is clicked or close action is triggered
            title="Delivery Details"
          > */}
            <DriverTip
              setClose={() => setShow(false)} // Close the Show when the DriverTip component requests to close
              selectedTipBtn={selectedTipBtn}
              open={show}
              setSelectedTipBtn={setSelectedTipBtn}
              setSelectedTipAmount={setSelectedTipAmount}
              selectedTipAmount={selectedTipAmount}
              onPress={tipPaymentHandler} // This is your handler for the tip payment submission
              back={true} // Show the back button if needed
              submit={true} // Show the submit button
              driver={orderDetails?.driver} // Pass the driver details to DriverTip
              setPaymentMethodDialog={setPaymentMethodDialog} // Navigate to add payment method when this action is triggered
              paymentMethodDialog={paymentMethodDialog} // Navigate to add payment method when this action is triggered
            />
          {/* </SimpleDialog> */}
        </Box>
    </>
   )}
          {/* Delivery details */}
          <Card sx={{ mt: 2, boxShadow: "none" }}>
            <CardContent>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: 640,
                  mx: "auto",
                  mt: 2,
                  overflow: "hidden",
                }}
              >
                {orderDetails?.status === "COMPLETED" ? (
                  <img
                    style={{
                      width: "300px",
                      display: "flex",
                      margin: "0 auto",
                    }}
                    src={Images.popper}
                  />
                ) : (
                  <video width="100%" autoPlay={true} loop>
                    <source src={cookingVideo} type="video/mp4" />
                  </video>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2,
                  px: 2,
                  py: 1,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", fontSize: "18px" }}
                >
                  Delivery details
                </Typography>
                {orderDetails?.status === "COMPLETED" && (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: Colors.black,
                      px: 2,
                      py: 0.5,
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                    onClick={() => setShow(!show)}
                  >
                    <AddCircleIcon />
                    <Typography variant="button">Tip Driver</Typography>
                  </Button>
                )}
              </Box>
              {orderDetails?.status == "ASSIGNED" ||
              orderDetails?.status == "PROCESSING" ||
              orderDetails?.status == "PICKEDUP" ||
              orderDetails?.status == "ARRIVED" ||
              orderDetails?.status == "REACHED" ||
              orderDetails?.status == "QUEUE" ||
              orderDetails?.status == "DELIVERING"
                ? renderDriverDetails()
                : null}
              <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <strong>Order #:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          sx={{ textAlign: "right" }}
                        >
                          {orderDetails?.orderid}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <strong>Restaurant:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          sx={{ textAlign: "right" }}
                        >
                          {orderDetails?.shop?.shopname}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <strong>Address:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          sx={{ textAlign: "right" }}
                        >
                          {orderDetails?.customer?.custaddress +
                            " " +
                            orderDetails?.customer?.custactualaddress}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <strong>Instructions:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          sx={{ textAlign: "right" }}
                        >
                          {orderDetails?.note !== ""
                            ? orderDetails?.note
                            : "N/A"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          <strong>Service:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          sx={{ textAlign: "right" }}
                        >
                          {orderDetails?.priority === 0
                            ? "Standard"
                            : "Priority"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              {/* Order summary */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: 2,
                  px: 2,
                  py: 1,
                  backgroundColor: Colors.white,
                }}
              >
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    my: 2,
                    borderColor: Colors.lightGrayShade,
                    py: 1,
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "18px" }}
                    variant="h6"
                  >
                    Order summary
                  </Typography>
                  {/* <Button
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      color: Colors.black,
                    }}
                    onClick={()=>createPDF(loadHtml())}
                  >
                    <SaveAltIcon />
                    <Typography variant="body2">Save receipt</Typography>
                  </Button> */}
                </Box>

                <Grid container>
                  {orderDetails?.items?.map((item, index) => (
                    <Grid item key={index} xs={12}>
                      <Box sx={{ mb: 2 }}>
                        <Card sx={{ boxShadow: "none" }}>
                          <CardContent>
                            <Typography variant="h6" gutterBottom>
                              {item?.name}
                            </Typography>
                            <Grid container spacing={1}>
                              {item?.ingredients?.map((ingredient, index) => (
                                <Grid item xs={12} key={index}>
                                  <Box
                                    sx={{
                                      fontSize: "0.85rem",
                                      color: "lightgray",
                                    }}
                                  >
                                    <Typography component="span">
                                      {ingredient.name}
                                    </Typography>
                                    {ingredient.price !== 0 && (
                                      <Typography
                                        component="span"
                                        sx={{ ml: 0.5 }}
                                      >
                                        {`($${ingredient.price.toFixed(2)})`}
                                      </Typography>
                                    )}
                                  </Box>
                                </Grid>
                              ))}
                            </Grid>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              Price: ${item?.price.toFixed(2)}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              Quantity: {item?.quantity}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <Divider />
                <Box sx={{ p: 1, pt: 2 }}>
                  <Grid container spacing={2}>
                    {orderDetails?.foodtotal > 0 && (
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>Item Total:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              sx={{ textAlign: "right" }}
                            >
                              {parseFloat(
                                orderDetails?.total_pay -
                                  (orderDetails?.small_order_fee +
                                    orderDetails?.delfee +
                                    orderDetails?.distance_fee -
                                    orderDetails?.promocode_amount)
                              ).toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {orderDetails?.small_order_fee > 0 && (
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>Small Order Fee:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              sx={{ textAlign: "right" }}
                            >
                              {orderDetails?.small_order_fee}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {orderDetails?.delfee > 0 && (
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>Delivery Fee:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              sx={{ textAlign: "right" }}
                            >
                              {orderDetails?.delfee}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {orderDetails?.distance_fee > 0 && (
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>Distance Fee:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              sx={{ textAlign: "right" }}
                            >
                              {orderDetails?.distance_fee}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {orderDetails?.promocode_amount > 0 && (
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>Discount:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              sx={{ textAlign: "right" }}
                            >
                              -{orderDetails?.promocode_amount}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {(orderDetails?.tip >= 0 || orderDetails?.tip1 >= 0) && (
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>Tip:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              sx={{ textAlign: "right" }}
                            >
                              {parseFloat(
                                orderDetails?.tip + orderDetails?.tip1
                              ).toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Total:</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            sx={{ textAlign: "right" }}
                          >
                            {parseFloat(orderDetails?.total_pay).toFixed(2)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
    </Container>
  );
}
