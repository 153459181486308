import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  TextField
} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

// Define card type patterns
const cardTypes = [
  { type: 'visa', pattern: /^4/ },
  { type: 'master-card', pattern: /^5[1-5]/ },
  { type: 'american Express', pattern: /^3[47]/ },
  { type: 'discover', pattern: /^6(?:011|5)/ },
  { type: 'JCB', pattern: /^35(?:2[89]|[3-8])|^1800|^2131/ }
];

function AddCardModal({ modalVisible, setClose, addCard, loader, setActiveScreen }) {
  const [paymentCardField, setPaymentCardField] = useState({
    number: '',
    name: '',
    expiry: '',
    cvc: '',
    type: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Card number formatting
    if (name === 'number') {
      const formattedValue = value.replace(/\D/g, '').slice(0, 16);
      setPaymentCardField((prev) => ({
        ...prev,
        number: formattedValue.replace(/(.{4})/g, '$1 ').trim(),
        type: detectCardType(formattedValue) // Update card type
      }));
    } 
    // Expiry date formatting
    else if (name === 'expiry') {
      const formattedValue = value.replace(/\D/g, '').slice(0, 4);
      setPaymentCardField((prev) => ({
        ...prev,
        expiry: formattedValue.replace(/(.{2})/, '$1/').trim()
      }));
    } 
    // CVC field restriction
    else if (name === 'cvc') {
      const formattedValue = value.replace(/\D/g, '').slice(0, 3);
      setPaymentCardField((prev) => ({
        ...prev,
        cvc: formattedValue
      }));
    } 
    // Name field (no specific formatting needed)
    else {
      setPaymentCardField((prev) => ({
        ...prev,
        name: value
      }));
    }
  };

  const detectCardType = (number) => {
    for (const card of cardTypes) {
      if (card.pattern.test(number)) {
        return card.type;
      }
    }
    return '';
  };

  useEffect(() => {
    if (!modalVisible) {
      setPaymentCardField({
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        type: ''
      });
    }
  }, [modalVisible]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "#fff",
        height: "100vh",
        padding: 2, // Padding for the modal content
      }}
    >
      <Box
        sx={{
          color: "black", 
          display: "flex",
          alignItems: "center",
          width:"100%"
        }}
      >
        <IconButton
          onClick={() => setActiveScreen(0)}
          sx={{
            margin: "0px",
          }}
          color="inherit"
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">Add Card</Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          maxWidth: 500,
          marginBottom: 2,
          mt:2
        }}
      >
        <Cards
          number={paymentCardField.number}
          name={paymentCardField.name}
          expiry={paymentCardField.expiry}
          cvc={paymentCardField.cvc}
          type={paymentCardField.type} // Pass card type
        />
        <Box>
          <TextField
            name="number"
            label="Card Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={paymentCardField.number}
            onChange={handleInputChange}
          />
          <TextField
            name="name"
            label="Cardholder Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={paymentCardField.name}
            onChange={handleInputChange}
          />
          <TextField
            name="expiry"
            label="Expiry Date (MM/YY)"
            variant="outlined"
            fullWidth
            margin="normal"
            value={paymentCardField.expiry}
            onChange={handleInputChange}
          />
          <TextField
            name="cvc"
            label="CVC"
            variant="outlined"
            fullWidth
            margin="normal"
            value={paymentCardField.cvc}
            onChange={handleInputChange}
          />
        </Box>
      </Box>

      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => addCard(paymentCardField)}
          disabled={loader}
        >
          Add Card
        </Button>
      </Box>
    </Box>
  );
}

export default AddCardModal;
