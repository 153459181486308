// store.js
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import userDataReducer from '../redux/slices/UserDataSlices';
import cartDataReducer from '../redux/slices/CartSlice';
import cartDataReducerFrequent from './slices/cartSliceFrequent';
import chatReducer from './slices/chatSlice';

const persistConfig = {
  key: 'appData',
  storage,
};

const reducers = combineReducers({
  userData: userDataReducer,
  cartData: cartDataReducer,
  cartDataFrequent: cartDataReducerFrequent,
  chat: chatReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
