import React, { useState } from 'react';
import { TextField, Autocomplete, Button, Box } from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

function CaymanIslandsAutocomplete({selectedLocation ,setSelectedLocation,setActiveScreen,setValueName}) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      bounds: new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(19.2631, -81.4151),
        new window.google.maps.LatLng(19.8284, -80.9112)
      ),
      componentRestrictions: { country: 'KY' },
    },
    debounce: 300,
  });

  const [activeStep, setActiveStep] = useState(0);
  // const [selectedLocation, setSelectedLocation] = useState(null);

  const handleInput = (event, newValue) => {
    setValue(newValue);
    
  };
  const handleSelect = async (event, newValue) => {
    if (!newValue) return;

    setValue(newValue.description, false);
    
    setValueName(newValue.description)
    clearSuggestions();

    try {
      const results = await getGeocode({ address: newValue.description });
      const { lat, lng } = await getLatLng(results[0]);
      setSelectedLocation({ lat, lng });
      console.log('Selected location:', { lat, lng });
      handleNext(); // Move to the next step after selecting a location
    } catch (error) {
      console.error('Error getting geocode:', error);
    }
  };

  const handleNext = () => {
    setActiveScreen((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveScreen((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* {activeStep === 0 && ( */}
        <Autocomplete
          freeSolo
          disableClearable
          options={status === 'OK' ? data : []}
          getOptionLabel={(option) => option.description || ''}
          onInputChange={handleInput}
          onChange={handleSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Enter a new address"
              fullWidth
              disabled={!ready}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'gray',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'rgb(128 128 128)',
                    borderWidth: '2px',
                  },
                },
              }}
            />
          )}
        />
      {/* )} */}
      {/* {activeStep === 1 && selectedLocation && (
        <GoogleMap
          mapContainerStyle={{ height: '400px', width: '100%' }}
          center={selectedLocation}
          zoom={15}
        >
          <Marker
            position={selectedLocation}
            draggable
            onDragEnd={(event) => {
              const newLat = event.latLng.lat();
              const newLng = event.latLng.lng();
              setSelectedLocation({ lat: newLat, lng: newLng });
              console.log('New location:', { lat: newLat, lng: newLng });
            }}
          />
        </GoogleMap>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        {activeStep > 0 && (
          <Button onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
        )}
      </Box> */}
    </Box>
  );
}

export default CaymanIslandsAutocomplete;
