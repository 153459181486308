import { BannersRoutes } from "./route";
import { post, get, put } from "../index";


const BannerServices = {
    getBanners: async () => {
    let result = get(BannersRoutes.getBanners );
    return result;
  },
  
};

export default BannerServices;
