import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, Box, Button, CircularProgress } from "@mui/material";
import Colors from "../../../../assets/styles";

const CybersourceAuthenticationWebViewModal = ({
  modalVisible,
  setClose,
  authToken,
  onBackPress,
  onSuccess,
  url,
}) => {
  const [showModal, setShowModal] = useState(modalVisible);
  const iframeRef = useRef();
  console.log("Auth URL:", url);
  console.log("Auth Token:", authToken);

  const htmlContent = `
    <!DOCTYPE html>
    <html>
    <head>
        <title>Payment Form</title>
    </head>
    <body>
        <form
            id="step-up-form"
            method="post"
            action="${url}"
        >
            <input type="hidden" name="JWT" value="${authToken}">
            <input type="hidden" name="MD" value="optionally_include_custom_data_that_will_be_returned_as_is">
        </form>
        <script>
            // Submit the form as the page loads
            document.getElementById('step-up-form').submit();
        </script>
    </body>
    </html>
  `;

  useEffect(() => {
    if (iframeRef.current) {
      const doc = iframeRef.current.contentWindow.document;
      doc.open();
      doc.write(htmlContent);
      doc.close();
    }
  }, [authToken, htmlContent]);

  const handleIframeMessage = (event) => {
        console.log("Message received:", event);
    try {
      if (typeof event.data === "string" && event.data.trim().startsWith("{")) {
        const data = JSON.parse(event.data);
        console.log("Parsed data:", data);
        if (data.MessageType === "stepUp.error") {
          console.error("Step-up authentication error:", data);
        } else if (data.Status == true) {
          onSuccess("DEVICE_AUTHENTICATION");
        }
      }
    } catch (error) {
      console.error("Invalid JSON received:", event.data, error);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleIframeMessage);

    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, []);

  return (
    <Dialog
      open={showModal}
      onClose={setClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          //   backgroundColor: "rgba(0, 0, 0, 0.8)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "50vh",
        },
      }}
    >
      <Box
        sx={{
          width: "90%",
          height: "80%",
          backgroundColor: "white",
          borderRadius: 1,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box sx={{ flexDirection: "column", display: "flex",height:"100%",justifyContent:"center",alignItems:"center "}}>
            <Box>
          <CircularProgress />
            </Box>
          <Box >
            Authenticating, Please wait...
          </Box>
        </Box>
        <iframe
          ref={iframeRef}
          title="Cybersource Authentication"
          srcDoc={htmlContent}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
          sandbox="allow-scripts allow-forms allow-same-origin"
          onError={(err) => console.error("Iframe error:", err)}
        />
      </Box>
      <Button
        onClick={setClose}
        variant="contained"
        color="error"
        sx={{ mt: 2,width:"96%",background:Colors.black ,":hover":{
            background:Colors.black
        }}}
      >
        Close
      </Button>
    </Dialog>
  );
};

CybersourceAuthenticationWebViewModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  authToken: PropTypes.string.isRequired,
  onBackPress: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default CybersourceAuthenticationWebViewModal;
