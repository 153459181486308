import { AuthRoutes } from "./auth.route";
import { post, get, put } from "../index";


const AuthServices = {
  login: async (obj) => {
    let result = post(AuthRoutes.login ,obj );
    return result;
  },
  validateUrl: async (obj) => {
    let result = post(AuthRoutes.validateUrl ,obj );
    return result;
  },
  sendOTP: async (obj) => {
    let result = post(AuthRoutes.sendOTP + `?phone=${obj}` );
    return result;
  },
  registerUrl: async (obj) => {
    let result = post(AuthRoutes.registerUrl ,obj );
    return result;
  },
  checkPhoneExist: async (obj) => {
    let result = post(AuthRoutes.checkPhoneExist ,obj );
    return result;
  },
  changePinCode: async (data) => {
    let result = await post(AuthRoutes.changePinCode, data);
     return result;
   
  },
};

export default AuthServices;
