import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import BreadCrumbProvider from "./app/hoc/BreadCrumbProvider";
import AuthProvider from "./app/hoc/AuthProvider";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Provider } from "react-redux";
import {store} from "./app/redux/store"
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from 'redux-persist';



const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
<Provider store={store}>
<PersistGate loading={null} persistor={persistor}>
  <AuthProvider>
    <BreadCrumbProvider>
      <App />
    </BreadCrumbProvider>
  </AuthProvider>
  </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
