import { PublicDataRoutes } from "./route";
import { post, get, put } from "../index";


const PublicDataHome = {
  getPublicDataHome: async (day,time) => {
    let result = get(PublicDataRoutes.getPublicDataHome  + `?day=${day}&time=${time}`);
    return result;
  },

};

export default PublicDataHome;
