import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  IconButton,
  Modal,
  CircularProgress,
} from '@mui/material';
import { GiftedChat, Bubble, Time, Send } from 'react-gifted-chat';
import { io } from 'socket.io-client';
import uuid from 'react-uuid';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

import { useSelector, useDispatch } from 'react-redux';

import { ErrorToaster } from '../../../../components/Toaster';
import Colors from '../../../../assets/styles';
import { useLocation } from 'react-router-dom';

const ChatDiver = ({ navigation, route }) => {
    const {state} = useLocation()
    console.log(state)
  const baseUrl = 'https://letseatchat.azurewebsites.net';
  console.log(baseUrl)
  const { driverId } = state || {};
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalImg, setModalImg] = useState('');
  const [visible, setVisible] = useState(false);
  
  const userConversation = useSelector(state => state.chat.userConversation);
  const userData = useSelector(state => state.userData.userData);
  const dispatch = useDispatch();

  // Memoize the socket instance
  const socket = useMemo(() => io(baseUrl), [baseUrl]);
console.log(socket)
  useEffect(() => {
    // Connect the socket
    socket.on('connect', () => {
      console.log("I'm connected with the back-end");
    });

    // Emit event to add user
    socket.emit('addUser', {
      userId: userData?.id,
      conversationId: userConversation?._id,
    });

    // Listen for incoming messages
    socket.on('receiveMessageEvent', msg => {
      console.log('Receiving message', msg);
      onReceive([
        {
          _id: uuid(),
          text: msg,
          createdAt: new Date(),
          user: {
            conversationId: userConversation?._id,
            _id: userConversation?.senderId,
            name: userData?.name,
            avatar: userData?.imgUrl,
          },
        },
      ]);
    });

    // Load previous chat history
    loadPreviousChat(userConversation?._id);

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, [socket, userConversation?._id, userData]);

  const loadPreviousChat = conversationId => {
    setLoading(true);
    axios
    .get(`${baseUrl}/api/messages/${conversationId}`)
    .then(response => {
      console.log(response.data); // Log the response data
      const messageHistory = response?.data?.map(msg => ({
        _id: uuid(),
        text: msg?.text || '', // Handle missing text
        createdAt: new Date(msg?.createdAt) || new Date(), // Handle missing createdAt
        user: {
          conversationId: msg?._id || '', // Handle missing _id
          _id: msg?.senderId || '', // Handle missing senderId
          name: msg?.senderId === userData?.id ? userData?.name : 'Driver',
          avatar: userData?.imgUrl || '', // Handle missing avatar
        },
      }));
      setMessages(messageHistory.reverse());
    })
    .catch((error) => {
      console.error('Error loading previous conversations:', error); // Log the error
      ErrorToaster('Unable to load previous conversations');
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const onReceive = useCallback((messages = []) => {
    setMessages(previousMessages =>
      GiftedChat.append(previousMessages, messages),
    );
  }, []);

  const onSend = useCallback((messages = []) => {
    const newMessage = messages[0];
    socket.emit('sendMessageEvent', {
      conversationId: newMessage.user.conversationId,
      senderId: newMessage.user._id,
      receiverId: driverId,
      message: newMessage.text,
      type: 'txt',
      url: '',
      user_type: 'user',
    });
    setMessages(previousMessages =>
      GiftedChat.append(previousMessages, messages),
    );
  }, [driverId, socket]);

  const renderBubble = props => (
    <Bubble
      {...props}
      wrapperStyle={{
        right: {
          backgroundColor: Colors.grey,
        },
        left: {
          backgroundColor: '#f0f0f0',
        },
      }}
      textStyle={{
        right: {
          color: '#000',
        },
        left: {
          color: '#000',
        },
      }}
    />
  );

  const renderTime = props => (
    <Time
      {...props}
      timeTextStyle={{
        left: { color: '#3c3c434d', fontSize: 10 },
        right: { color: '#3c3c434d', fontSize: 10 },
      }}
    />
  );

  const renderSend = props => (
    <Send {...props}>
      <IconButton color="primary">
        <Send />
      </IconButton>
    </Send>
  );

  const handleQuickReply = message => {
    const replyMessage = {
      _id: uuid(),
      text: message.value,
      createdAt: new Date(),
      user: {
        _id: userData?.id,
      },
    };
    setMessages(previousMessages =>
      GiftedChat.append(previousMessages, [replyMessage]),
    );
  };

  return (
    <Box sx={{ flex: 1, backgroundColor: '#fff', height: '100vh' }}>
      <Box>Chat with Rider</Box>
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      )}
      <GiftedChat
        renderBubble={renderBubble}
        messages={messages}
        onSend={messages => onSend(messages)}
        renderTime={renderTime}
        user={{
          conversationId: userConversation?._id,
          _id: userData?.id,
          name: userData?.name,
        }}
      />
      <Modal open={visible} onClose={() => setVisible(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 10, right: 10 }}
            onClick={() => setVisible(false)}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={modalImg}
            alt="Modal"
            style={{ maxWidth: '100%', maxHeight: 400 }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default ChatDiver;
