import { LocationRoutes } from "./route";
import { post, get, put } from "../tokenindex";

const LocationAddressServices = {
  saveAddressDetails: async (obj) => {
    let result = await post(LocationRoutes.saveAddressDetails, obj);
    return result;
  },
  getCustomerAddress: async (param) => {
    let result = await get(LocationRoutes.getCustomerAddress, param);
    return result;
  },
  getParcelAddress: async (param) => {
    let result = await get(LocationRoutes.getParcelAddress, param);
    return result;
  },
  addAddressDetails: async (obj) => {
    let result = await post(LocationRoutes.addAddressDetails, obj);
    return result;
  },
  addParcelAddressDetails: async (obj) => {
    let result = await post(LocationRoutes.addParcelAddressDetails, obj);
    return result;
  },
  updatedAddressDetails: async (obj) => {
    let result = await post(LocationRoutes.updatedAddressDetails, obj);
    return result;
  },
  updatedParcelAddressDetails: async (obj) => {
    let result = await post(LocationRoutes.updatedParcelAddressDetails, obj);
    return result;
  },
  deleteCustomerAddress: async (param) => {
    let result = await get(LocationRoutes.deleteCustomerAddress, param);
    return result;
  },
  deleteParcelAddress: async (param) => {
    let result = await get(LocationRoutes.deleteParcelAddress, param);
    return result;
  },
  getShopAddress: async (param) => {
    let result = await get(LocationRoutes.getShopAddress, param);
    return result;
  },
  getStoreAddress: async (param) => {
    let result = await get(LocationRoutes.getStoreAddress, param);
    return result;
  },
  getParcelAddressDistance: async (param) => {
    let result = await get(LocationRoutes.getParcelAddressDistance, param);
    return result;
  },
};

export default LocationAddressServices;
