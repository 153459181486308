import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { useSelector } from 'react-redux';
import OTP from '../../components/OTPINPUT'; // Assuming OTP component is placed in this directory
import AuthServices from '../../api/AuthServices/auth.index';
import { ErrorToaster, SuccessToaster } from '../Toaster';

const ChangePIN = ({ setPinDialog }) => {
  const [pinCode, setPinCode] = useState('');
  const [confirmPinCode, setConfirmPinCode] = useState('');
  const [loader, setLoader] = useState(false);
  const PINCODE_LENGTH = 4;
  const userData = useSelector(state => state.userData.userData);

  const firstInputRef = useRef(null);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  const handlePinChange = (value) => {
    setPinCode(value);
  };

  const handleConfirmPinChange = (value) => {
    setConfirmPinCode(value);
  };

  const SubmitChangePIN = async () => {
    setLoader(true);
    const params = {
      UserID: userData?.id,
      OldPincode: pinCode,
      Pincode: confirmPinCode,
    };

    try {
      const result = await AuthServices.changePinCode(params);
      if (result?.status === '0') {
        ErrorToaster(result?.message);
      } else {
        SuccessToaster('Your PIN has been changed.');
        setPinDialog(false);
      }
    } catch (error) {
      ErrorToaster('Error changing PIN:', error);
    } finally {
      setLoader(false);
    }
  };

  const isButtonDisabled = 
    pinCode.length !== PINCODE_LENGTH || confirmPinCode.length !== PINCODE_LENGTH || pinCode === confirmPinCode;

  return (
    <Container maxWidth="sm" style={{ marginTop: '20px' }}>
      <Typography variant="h5" fontWeight="bold" style={{ marginBottom: '20px' }}>
        Change PIN
      </Typography>

      <Box mb={2}>
        <Typography variant="h6" style={{ marginBottom: '10px' }}>
          Enter your 4-Digits unique PIN
        </Typography>
        <Typography variant="body2" style={{ color: 'gray', marginBottom: '20px' }}>
          This unique PIN will help you to login without OTP.
        </Typography>

        <Typography variant="body1" fontWeight="bold" style={{ marginBottom: '10px' }}>
          Enter your old PIN
        </Typography>
        <Box>
          <OTP
            length={PINCODE_LENGTH}
            value={pinCode}
            onChange={handlePinChange}
            separator={<span>-</span>}
            inputRef={firstInputRef}
            focusInputIndex={0} 
          />
        </Box>
      </Box>

      <Box mb={2}>
        <Typography variant="body1" fontWeight="bold" style={{ marginBottom: '10px' }}>
          Enter your new backup PIN
        </Typography>
        <Box>
          <OTP
            length={PINCODE_LENGTH}
            value={confirmPinCode}
            onChange={handleConfirmPinChange}
            separator={<span>-</span>}
          />
        </Box>
      </Box>

      {pinCode === confirmPinCode && pinCode.length === PINCODE_LENGTH && (
        <Typography color="error" variant="body2" style={{ marginBottom: '10px' }}>
          You can't have similar PIN, try different PIN
        </Typography>
      )}

      <Box textAlign="center">
        <Button
          variant="contained"
          color="primary"
          disabled={isButtonDisabled}
          onClick={SubmitChangePIN}
          style={{ marginTop: '20px', padding: '10px 20px' }}
        >
          {loader ? <CircularProgress sx={{ color: 'white' }} size={24} /> : 'Update PIN'}
        </Button>
      </Box>
    </Container>
  );
};

export default ChangePIN;
