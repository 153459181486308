export const orderEndpoints = {
    // getOrders: '/customer/GetOrders',
    getOrders: '/customer/GetOrdersWithPagination',
    getOrderDetails: '/data/GetOrderDetails',
    getPreviousOrderFeedback: '/data/GetFeedback',
    giveFeedback: 'data/SubmitFeedback',
    placeReorder: 'cart/ReOrder',
    getRecentOrders: 'customer/GetRecentOrders',
    getRecentOrderedShops: '/customer/GetRecentOrdersShops',
    getRecentShopOrders: '/customer/GetRecentShopOrders',
    cancelOrder: '/customer/CancelOrder',
    getActiveOrders: '/customer/GetActiveOrders',
  };
  