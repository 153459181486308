import { yellow } from "@mui/material/colors";

const Colors = {
  backgroundColor: "#F1FEFD",
  //dashboardBgColor: "#e0e8e9",
  dashboardBgColor: "#ECF9F8",
  primary: "#EF4023",
  yellow: "#ffb749",
  green: "#06c167",
  red: "#ff2900",
  white: "#FFFFFF",
  black: "#000000",
  grey: "rgb(232 232 232)",
};

export default Colors;
