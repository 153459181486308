import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  CircularProgress,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Grid,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { animated, useSpring } from '@react-spring/web';
import GiftCard from '../../../../components/GiftCard.js';
import { giftCardApiCall } from '../../../../api/giftCard/index.js';
import Redeemed from './redeemed.js';
import Colors from '../../../../assets/styles/index.js';
import SimpleDialog from '../../../../components/Dialog/index.js';

const Gift = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [giftCardCategories, setGiftCardCategories] = useState([]);
  const userData = useSelector((state) => state.userData.userData);

  const getGiftCardCategories = async () => {
    setLoader(true);
    try {
      const result = await giftCardApiCall.getGiftCards();
      setGiftCardCategories(result);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getGiftCardCategories();
  }, []);

  const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  return (
    <Container  sx={{ backgroundColor: '#ffffff', minHeight: '100vh', paddingTop: 3,maxWidth:"1300px" }}>
      <AppBar sx={{ mt: 10, backgroundColor: 'white !important', boxShadow: 'none !important' }} position="static">
        <Toolbar>
          <IconButton
            edge="start"
            sx={{ background: Colors.grey, borderRadius: '50%' }}
            color="black"
            onClick={() => navigate(-1)}
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h5"
            style={{ flexGrow: 1, color: Colors.black, fontWeight: 'bold', marginLeft: '8px' }}
          >
             Gift Cards
          </Typography>
          <Button
          onClick={()=>setOpenDialog(true)}
            color="inherit"
            sx={{ color: Colors.black, background: Colors.grey, borderRadius: '20px', padding: '4px 15px' }}
          >
            Redeem
          </Button>
        </Toolbar>
      </AppBar>

      {loader ? (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '60vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <animated.div style={props}>
          <Box mt={3} ml={1}>
            <Typography variant="h5" fontWeight="bold" mb={2}>
              Shop gift cards
            </Typography>

            {giftCardCategories?.map((category, categoryIndex) => (
              <Box key={categoryIndex} mb={5}>
                <Typography sx={{fontWeight:"600"}} variant="h6" mb={2}>
                  {category?.CategoryName}
                </Typography>
                <Grid container spacing={3}>
                  {category?.Covers?.map((cover, coverIndex) => (
                    <Grid item xs={12} sm={6} md={4} key={coverIndex}>
                      <GiftCard
                        data={cover}
                        onClick={()=>navigate('/customize-gift-card', { state: { data: cover } }) }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
          </Box>
        </animated.div>
      )}
     {openDialog && (
        <>
        <SimpleDialog open={openDialog}
         onClose={() => setOpenDialog(false)}
         title=" Redeem Gift Cards">
          <Redeemed />
        </SimpleDialog>
        </>
      )}
    </Container>
  );
};

export default Gift;
