export const paymentEndpoints = {
    addCard: '/customer/addCard',
    addToWallet: '/customer/Add2wallet_new',
    pay3d: '/customer/Pay3ds_new',
    // pay3d: '/customer/Payment3ds', // Latest with Payer auth but it is not working.
  
    deleteCard: '/customer/DeleteCard',
    getCard: '/customer/GetCards_new',
    tipPayment: '/customer/TipPayment_new',
  };
  