import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  cartData: [],
  menuTree: [],
  required: [],
  selectedData: {},
  parentListName: '',
  onlineCartData: [],
  paymentCardData: {},
  isWallet: false,
};

export const userDataSlice = createSlice({
  name: 'cartDataReducerFrequent',
  initialState,
  reducers: {
    setCartData: (state, action) => {
      let findIndex = state.cartData.findIndex(
        item => item.product_id == action.payload.product_id,
      );
      if (findIndex !== -1) {
        let spreadData = [...state.cartData];
        let findIndex = state.cartData.findIndex(
          item => item.product_id == action.payload.product_id,
        );
        spreadData[findIndex] = {
          ...spreadData[findIndex],
          qty: spreadData[findIndex].qty + action.payload.qty,
        };
        state.cartData = spreadData;
      } else {
        state.cartData = [...state.cartData, action.payload];
      }
    },
    setReplaceCartData: (state, action) => {
      state.cartData = [action.payload];
    },
    setDeleteItem: (state, action) => {
      let spreadData = [...state.cartData];
      let findIndex = state.cartData.findIndex(
        item => item.product_id == action.payload.product_id,
      );
      spreadData.splice(findIndex, 1);
      state.cartData = spreadData;
    },
    setIncrementItem: (state, action) => {
      let spreadData = [...state.cartData];
      let findIndex = state.cartData.findIndex(
        item => item.product_id == action.payload.product_id,
      );
      spreadData[findIndex] = {
        ...spreadData[findIndex],
        qty: spreadData[findIndex].qty + 1,
      };
      state.cartData = spreadData;
    },
    setDecrementItem: (state, action) => {
      let spreadData = [...state.cartData];
      let findIndex = state.cartData.findIndex(
        item => item.product_id == action.payload.product_id,
      );
      spreadData[findIndex] = {
        ...spreadData[findIndex],
        qty: spreadData[findIndex].qty - 1,
      };
      state.cartData = spreadData;
    },
    setMenuTree: (state, action) => {
      var tempMenuTree = state.menuTree;
      tempMenuTree.push(action.payload);
      state.menuTree = tempMenuTree;
    },
    clearMenuTree: (state, action) => {
      state.menuTree = [];
    },
    popMenuTree: (state, action) => {
      state.menuTree.pop();
    },
    setSelectedProductData: (state, action) => {
      state.selectedData = action.payload;
    },
    clearSelectedData: (state, action) => {
      state.selectedData = {};
    },
    setParentListName: (state, action) => {
      state.parentListName = action.payload;
    },
    setRequiredList: (state, action) => {
      state.required = action.payload;
    },
    removeRequiredList: (state, action) => {
      const shallowCopy = [...state.required];
      const index = shallowCopy.indexOf(action.payload);
      if (index != -1) {
        shallowCopy.splice(index, 1);
      }
      state.required = shallowCopy;
    },
    setOnlineCartData: (state, action) => {
      state.onlineCartData = action.payload;
    },
    setPaymentCardData: (state, action) => {
      state.paymentCardData = action.payload;
    },
    setIsWallet: (state, action) => {
      state.isWallet = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setReplaceCartData,
  setCartData,
  setDeleteItem,
  setIncrementItem,
  setDecrementItem,
  setMenuTree,
  popMenuTree,
  clearMenuTree,
  setSelectedProductData,
  clearSelectedData,
  setParentListName,
  setRequiredList,
  removeRequiredList,
  setOnlineCartData,
  setPaymentCardData,
  setIsWallet,
} = userDataSlice.actions;

export default userDataSlice.reducer;
