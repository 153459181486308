import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  AppBar,
  Toolbar,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import Colors from '../../../../assets/styles/index.js';
import { styled } from "@mui/material/styles";

import { giftCardApiCall } from '../../../../api/giftCard';
import GiftCard from '../../../../components/GiftCard.js';
import { useNavigate } from 'react-router-dom';
import SimpleDialog from '../../../../components/Dialog/index.js';
import Redeemed from './redeemed.js';

const ToggleSlider = styled(Box)(({ transformX ,selectedType}) => ({
  transition: 'transform 300ms ease-in-out',
  transform: `translateX(${transformX}%)`,
  position: 'absolute',
  width: selectedType === 'Redeemed' ? "100%" :"50%",
  height: '100%',
  backgroundColor: '#ffffff',
  borderRadius: '20px',
}));

const ToggleButtonContainer = styled(Box)({
  display: 'flex',
  position: 'relative',
  width: '180px',
  height: '40px',
  borderRadius: '20px',
  backgroundColor: '#f0f0f0',
  overflow: 'hidden',
  border: '2px solid #aeaeae47',
});

const ToggleOption = styled(Button)(({ active }) => ({
  flex: 1,
  height: '100%',
  borderRadius: '20px',
  backgroundColor: 'transparent',
  color: active ? 'black' : 'grey',
  fontWeight: active ? 'bold' : 'normal',
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.3)',
  },
}));

const ToggleButton = ({ selectedType, setSelectedType }) => {
  const [transformX, setTransformX] = useState(0);

  useEffect(() => {
    setTransformX(selectedType === 'Redeemed' ? 50 : 0); // Move slider to 50% if Redeemed is selected
  }, [selectedType]);

  return (
    <ToggleButtonContainer>
      <ToggleSlider selectedType={selectedType} transformX={transformX} />
      <ToggleOption
        active={selectedType === 'Active'}
        onClick={() => setSelectedType('Active')}
      >
        Active
      </ToggleOption>
      <ToggleOption
        active={selectedType === 'Redeemed'}
        onClick={() => setSelectedType('Redeemed')}
      >
        Redeemed
      </ToggleOption>
    </ToggleButtonContainer>
  );
};

const MyGiftCard = () => {
  const isFocused = true;
  const scrollOffsetY = useRef(0);
  const [loader, setLoader] = useState(false);
  const [isRedeem, setIsRedeem] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [myGiftCards, setMyGiftCards] = useState([]);
  const userData = useSelector(state => state.userData.userData);
  const navigate = useNavigate();

  const getMyGiftCards = async () => {
    setLoader(true);
    try {
      const { MyGiftCards, RedeemGiftCards } = await giftCardApiCall.getMyGiftCards(userData?.id);
      setMyGiftCards(isRedeem ? RedeemGiftCards : MyGiftCards);
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getMyGiftCards();
  }, [isFocused, isRedeem]);

  return (
    <Container style={{ maxWidth: '1300px' }}>
      <AppBar sx={{ mt: 10, backgroundColor: 'white !important', boxShadow: 'none !important' }} position="static">
        <Toolbar>
          <IconButton
            edge="start"
            sx={{ background: Colors.grey, borderRadius: '50%' }}
            color="black"
            onClick={() => navigate(-1)}
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h5"
            style={{ flexGrow: 1, color: Colors.black, fontWeight: 'bold', marginLeft: '8px' }}
          >
            My Gift Cards
          </Typography>
          <Button
          onClick={()=>setOpenDialog(true)}
            color="inherit"
            sx={{ color: Colors.black, background: Colors.grey, borderRadius: '20px', padding: '4px 15px' }}
          >
            Redeem
          </Button>
        </Toolbar>
      </AppBar>

      <Box my={2} sx={{display:"flex",justifyContent:"end"}}>
        <ToggleButton
          selectedType={isRedeem ? 'Redeemed' : 'Active'}
          setSelectedType={e => setIsRedeem(e === 'Redeemed')}
        />
      </Box>

      {loader ? (
        <Box display="flex" justifyContent="center" alignItems="center" style={{ minHeight: '60vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box style={{ paddingBottom: '10%' }} onScroll={e => (scrollOffsetY.current = e.target.scrollTop)}>
          {myGiftCards?.length <= 0 ? (
            <Box display="flex" justifyContent="center" alignItems="center" style={{ minHeight: '60vh' }}>
              <Typography variant="body2" color="textSecondary">
                You don't have any gift cards yet.
              </Typography>
            </Box>
          ) : (
            <List>
              <Grid container spacing={2}>
                {myGiftCards.map((item, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4}>
                    <GiftCard data={item} />
                  </Grid>
                ))}
              </Grid>
            </List>
          )}
        </Box>
      )}


      {openDialog && (
        <>
        <SimpleDialog open={openDialog}
         onClose={() => setOpenDialog(false)}
         title=" Redeem Gift Cards">
          <Redeemed getMyGiftCards={getMyGiftCards} setOpenDialog={setOpenDialog} />
        </SimpleDialog>
        </>
      )}
    </Container>
  );
};

export default MyGiftCard;
