import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, Polyline, useLoadScript } from "@react-google-maps/api";
import { Box, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from "moment";
import Colors from "../../assets/styles";

function MapComponent({ address ,status,openTime}) {

    const mapKey = 'AIzaSyC1Gh661ToYHtDs98IVxaZHi5_XqVMwy6Y';

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: mapKey,
    });

    const defaultCoordinates1 = { lat: 19.3133, lng: -81.2546 }; // Near George Town, Grand Cayman
    const defaultCoordinates2 = { lat: 19.3171, lng: -81.2423 }; // Approximately 0.9 miles away

    const [startPoint, setStartPoint] = useState(defaultCoordinates1);
    const [endPoint, setEndPoint] = useState(defaultCoordinates2);

    const path = [startPoint, endPoint];

    if (loadError) {
        console.error("Error loading maps:", loadError);
        return "Error loading maps";
    }
    if (!isLoaded) {
        console.log("Maps are loading...");
        return "Loading maps";
    }

    return (
        <Box sx={{ height: '250px', position: 'relative' }}>
            <GoogleMap
                mapContainerStyle={{ height: '100%', width: '100%',borderTopLeftRadius:"20px",borderTopRightRadius:"20px" }}
                center={startPoint}
                zoom={14}
                options={{
                    disableDefaultUI: true,
                    gestureHandling: 'none',
                    zoomControl: false
                }}
            >
                <Marker 
                    position={startPoint}
                />
                <Marker 
                    position={endPoint}
                />

                <Polyline path={path} options={{ strokeColor: Colors.primary, strokeWeight: 5 }} />
            </GoogleMap>
            <Typography
                sx={{
                    position: 'absolute',
                    top: '40%',
                    left: '40%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    padding: '2px 5px',
                    borderRadius: '5px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                }}
            >
                {`0.9 mi`}
            </Typography>

            <Box sx={{ 
            border: '1px solid #e0e0e0', 
            borderBottomLeftRadius: '12px', 
            borderBottomRightRadius: '12px', 
            padding: '16px', 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'flex-start'
        }}>
            {/* Address Section */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <LocationOnIcon sx={{ mr: 1 }} />
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {address}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {address.details}
                    </Typography>
                </Box>
               
            </Box>

            {/* Status Section */}
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <AccessTimeIcon sx={{ mr: 1 }} />
                <Box>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        {status}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                    {moment(openTime, "HH:mm").format("h:mm")}
                    </Typography>
                </Box>
            </Box>
        </Box>
   
        </Box>
    );
}

export default MapComponent;
