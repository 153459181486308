// TwoButtonModal.js
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Colors from '../../assets/styles';


const TwoButtonModal = ({
  modalVisible,
  text,
  dispatch,
  pressCancelBtn,
  successBtnText,
  rejectBtnText,
  loading,
}) => {
  return (
    <Dialog
      open={modalVisible}
      onClose={pressCancelBtn}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center', backgroundColor: Colors.black, color: Colors.white }}>
        Confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center', color: Colors.black ,marginTop:"30px"}}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {loading ? (
          <CircularProgress size={24} color="primary" />
        ) : (
          <>
            <Button onClick={pressCancelBtn} style={{ backgroundColor: Colors.grey, color: Colors.black }}>
              {rejectBtnText}
            </Button>
            <Button onClick={dispatch} style={{ backgroundColor: Colors.primary, color: Colors.white }} autoFocus>
              {successBtnText}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TwoButtonModal;
