// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider,FacebookAuthProvider  } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCl5oLnLbYWuG8UYONi9HItdbqpVLnXgEE",
  authDomain: "lets-eat-839d1.firebaseapp.com",
  projectId: "lets-eat-839d1",
  storageBucket: "lets-eat-839d1.appspot.com",
  messagingSenderId: "825431255728",
  appId: "1:825431255728:web:e150cf5408b0c8f47a6975",
  measurementId: "G-CKJSY9LYPL"
};
// const firebaseConfig = {
//   apiKey: "AIzaSyAuoQSNu8Ct-jXbHFdjGLyhYy5Y_mNm3Y4",
//   authDomain: "focus-storm-267913.firebaseapp.com",
//   databaseURL: "https://focus-storm-267913.firebaseio.com",
//   projectId: "focus-storm-267913",
//   storageBucket: "focus-storm-267913.appspot.com",
//   messagingSenderId: "379678460809",
//   appId: "1:379678460809:web:80f7351d8937ea56976f58",
//   measurementId: "G-099P0D834Z"
// };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
     

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);


// Set up Google provider
const provider = new GoogleAuthProvider();
const provider1 = new FacebookAuthProvider();

export { auth, provider,provider1, signInWithPopup };