import {orderEndpoints} from './route';
import {get, post, patch, deleted} from '../tokenindex';

export const orderApiCall = {
  getOrders: async param => {
    let result = await get(orderEndpoints.getOrders, param);
   return result
    
  },
  getOrderDetails: async param => {
    let result = await get(orderEndpoints.getOrderDetails, param);
   return result
    
  },
  getPreviousOrderFeedback: async param => {
    let result = await get(orderEndpoints.getPreviousOrderFeedback, param);
   return result
    
  },
  giveFeedback: async param => {
    let result = await post(orderEndpoints.giveFeedback, param);
   return result
    
  },
  placeReorder: async param => {
    let result = await post(orderEndpoints.placeReorder, param);
   return result
    
  },
  cancelOrder: async param => {
    let result = await post(orderEndpoints.cancelOrder, param);
   return result
    
  },
  getRecentOrders: async param => {
    let result = await get(orderEndpoints.getRecentOrders, param);
   return result
    
  },
  getRecentOrderedShops: async param => {
    let result = await get(orderEndpoints.getRecentOrderedShops, param);
   return result
    
  },
  getRecentShopOrders: async param => {
    let result = await get(orderEndpoints.getRecentShopOrders, param);
   return result
    
  },
  getActiveOrders: async param => {
    let result = await get(orderEndpoints.getActiveOrders, param);
   return result
    
  },
};
