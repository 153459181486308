import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Grid } from '@mui/material';
// import { useHistory } from 'react-router-dom';

const GiftCard = ({ data , onClick }) => {
    console.log(data)
//   const history = useHistory();
  const [giftCardCode, setGiftCardCode] = useState([]);

  useEffect(() => {
    const code = data?.code?.split('');
    setGiftCardCode(code);
  }, [data]);
const baseUrl = process.env.REACT_APP_BASE_URL
  return (
    <Card
      onClick={ onClick
        // history.push('/previewGift', {
        //   data: {
        //     toName: data?.to,
        //     fromName: data?.from,
        //     senderNote: data?.description,
        //     giftAmount: data?.amount,
        //     giftData: { pic: data?.pic },
        //     giftCardCode: data?.code,
        //     isShare: true,
        //   },
        // });
      }
      sx={{
        backgroundColor: 'white',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        margin: '10px',
        cursor: 'pointer',
      }}
    >
      <CardContent sx={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
        <CardMedia
          component="img"
          height="180"
          image={baseUrl +"/docs/files/giftCards/" + data?.pic}
          alt="Gift Card"
          sx={{
            borderRadius: '10px',
            backgroundColor: 'black',
            marginBottom: '20px',
          }}
        />
        <Grid container spacing={1} justifyContent="center" alignItems="center" style={{ marginBottom: '10px' }}>
          {giftCardCode?.map((item, i) => (
            <Typography
              key={i}
              variant="h6"
              fontWeight="bold"
              sx={{
                fontSize: '1rem',
                color: 'black',
              }}
            >
              {item}
            </Typography>
          ))}
        </Grid>
        {data?.amount && (
            <Typography variant="h4" fontWeight="bold" sx={{ fontSize: '1.875rem', color: 'black' }}>
          ${parseFloat(data?.amount).toFixed(2)} KYD
        </Typography>
        )}
        {data?.from && (

        <Box display="flex" flexDirection="row" sx={{ marginTop: '10px' }}>
          <Typography variant="body1" fontWeight="bold" sx={{ marginRight: '3px' }}>
            From :
          </Typography>
          <Typography variant="body1" fontWeight="bold" color="textSecondary">
            {data?.from}
          </Typography>
        </Box>
        )}
        {data?.to && (

        <Box display="flex" flexDirection="row">
          <Typography variant="body1" fontWeight="bold" sx={{ marginRight: '3px' }}>
            To :
          </Typography>
          <Typography variant="body1" fontWeight="bold" color="textSecondary">
            {data?.to}
          </Typography>
        </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default GiftCard;
