import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Colors from '../../../../assets/styles';
import { WindPower } from '@mui/icons-material';

const LiveOrderTrackingView = ({
  orderStatus,
  arrivingAt,
  latestArrival,
  shopName,
  orderDetail,
}) => {
  const styles = {
    container: {
      justifyContent: 'center',
      padding: '16px',
      backgroundColor: '#ffffff',
    },
    progressContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '16px 0',
      height: '5px',
      width: '100%',
      justifyContent: 'space-evenly',
    },
    active: {
      flex: 0.22,
      height: '4px',
      borderColor: Colors.primary, // Assuming a primary color similar to MUI's default
      display: 'flex',
      flexDirection: 'row',
      margin: '0 4px',
    },
    inactive: {
      flex: 0.22,
      height: '4px',
      backgroundColor: '#f0f0f0',
      borderColor: Colors.primary,
      margin: '0 4px',
    },
    topHalf: {
      flex: 2,
      backgroundColor: Colors.primary,
    },
    bottomHalf: {
      flex: 1,
      backgroundColor: '#f0f0f0',
    },
    textBold: {
      fontWeight: 'bold',
      color: '#000',
    },
    text: {
      color: '#000',
      margin: '5px 0',
    },
  };

  let text = '';
  if (orderDetail?.service_type === 'parcel') {
    switch (orderStatus) {
      case 'ORDERED':
        text = 'We have received your order for the shipment.';
        break;
      case 'ASSIGNED_TO_COURIER_COMPANY':
        text = `Order# ${orderDetail?.orderid} – Your order for the shipment has been assigned to the courier company!`;
        break;
      case 'ACCEPTED':
        text = `Order# ${orderDetail?.orderid} - The order for the shipment has been accepted by the courier company!`;
        break;
      case 'DRIVER_ASSIGNED':
        text = `Order# ${orderDetail?.orderid} – Driver has been assigned to pick up your shipment!`;
        break;
      case 'ON_HIS_WAY':
        text = `Order# ${orderDetail?.orderid} – Your driver is on his way to the pick up your shipment!`;
        break;
      case 'REACHED':
        text = `Order# ${orderDetail?.orderid} – Your driver has reached to the pick up location!`;
        break;
      case 'PICKED_UP':
        text = `Order# ${orderDetail?.orderid} - Your shipment has been picked up!`;
        break;
      case 'OUT_FOR_DELIVERY':
        text = `Order# ${orderDetail?.orderid} - Your shipment is out for delivery!`;
        break;
      case 'ARRIVED_AT_DELIVERY_LOCATION':
        text = `Order# ${orderDetail?.orderid} - Your driver has reached the destination! Please collect your courier.`;
        break;
      case 'COMPLETED':
        text = `Order# ${orderDetail?.orderid} – Your shipment has been delivered. We hope you like it!`;
        break;
      case 'CANCELLED':
        text = `Order# ${orderDetail?.orderid} – has been cancelled.`;
        break;
      case 'QUEUE':
        text = `Order# ${orderDetail?.orderid} – Your driver is currently delivering another shipment in your area. He will be there shortly!`;
        break;
      case 'DELIVERING':
        text = `Order# ${orderDetail?.orderid} – Your driver is on his way to deliver your shipment. He will be there shortly!`;
        break;
      default:
        break;
    }
  } else {
    switch (orderStatus) {
      case 'PROCESSING':
        text = `Order# ${orderDetail?.orderid} – Your driver is on his way to the restaurant to pick up your delicious meal!`;
        break;
      case 'ORDERED':
        text = 'Hold on! your order is being placed.';
        break;
      case 'ACCEPTED':
        text = `Order# ${orderDetail?.orderid} - The restaurant has accepted your order and is preparing your food now!`;
        break;
      case 'ASSIGNED':
        text = `Order# ${orderDetail?.orderid} – Your driver is on his way to the restaurant to pick up your delicious meal!`;
        break;
      case 'REACHED':
        text = `Order# ${orderDetail?.orderid} – Your driver has reached to the restaurant to pick up your delicious meal!`;
        break;
      case 'PICKEDUP':
        text = `Order# ${orderDetail?.orderid} - Your food has been picked up and is on its way!`;
        break;
      case 'ARRIVED':
        text = `Order# ${orderDetail?.orderid} - Your driver has arrived! Waiting for you`;
        break;
      case 'RECEIVED':
        text = `Order# ${orderDetail?.orderid} - Hold on! We have received your order.`;
        break;
      case 'COMPLETED':
        text = `Order# ${orderDetail?.orderid} – Your food has been delivered. Enjoy your meal!`;
        break;
      case 'CANCELLED':
        text = `Order# ${orderDetail?.orderid} – has been cancelled.`;
        break;
      case 'QUEUE':
        text = `Order# ${orderDetail?.orderid} – Your driver is currently delivering another order in your area. He will be there shortly!`;
        break;
      case 'DELIVERING':
        text = `Order# ${orderDetail?.orderid} – Your driver is on his way to deliver your order. He will be there shortly!`;
        break;
      case 'PICKUP_USER':
        text = `Order# ${orderDetail?.orderid} – You have picked up your order from the restaurant. Enjoy your meal!`;
        break;
      default:
        break;
    }
  }

  const activeIndicator = () => {
    return (
      <Box sx={styles.active}>
        <Box sx={styles.topHalf}></Box>
        <Box sx={styles.bottomHalf}></Box>
      </Box>
    );
  };

  const fullActiveIndicator = () => {
    return (
      <Box sx={styles.active}>
        <Box sx={styles.topHalf}></Box>
      </Box>
    );
  };

  const inactiveIndicator = () => {
    return <Box sx={styles.inactive}></Box>;
  };

  const renderProgress = () => {
    if (orderDetail?.service_type === 'parcel') {
      switch (orderStatus) {
        case 'ORDERED':
        case 'ASSIGNED_TO_COURIER_COMPANY':
          return (
            <Box sx={styles.progressContainer}>
              {fullActiveIndicator()}
              {inactiveIndicator()}
              {inactiveIndicator()}
              {inactiveIndicator()}
              {inactiveIndicator()}
            </Box>
          );
        case 'ACCEPTED':
        case 'ON_HIS_WAY':
        case 'DRIVER_ASSIGNED':
          return (
            <Box sx={styles.progressContainer}>
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {inactiveIndicator()}
              {inactiveIndicator()}
              {inactiveIndicator()}
            </Box>
          );
        case 'PICKED_UP':
        case 'REACHED':
          return (
            <Box sx={styles.progressContainer}>
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {activeIndicator()}
              {inactiveIndicator()}
            </Box>
          );
        case 'OUT_FOR_DELIVERY':
        case 'ARRIVED_AT_DELIVERY_LOCATION':
          return (
            <Box sx={styles.progressContainer}>
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {inactiveIndicator()}
            </Box>
          );
        case 'COMPLETED':
          return (
            <Box sx={styles.progressContainer}>
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {fullActiveIndicator()}
            </Box>
          );
        case 'CANCELLED':
          return <Box sx={styles.progressContainer}></Box>;
        default:
          return null;
      }
    } else {
      switch (orderStatus) {
        case 'ORDERED':
        case 'ASSIGNED':
        case 'PROCESSING':
          return (
            <Box sx={styles.progressContainer}>
              {fullActiveIndicator()}
              {inactiveIndicator()}
              {inactiveIndicator()}
              {inactiveIndicator()}
              {inactiveIndicator()}
            </Box>
          );
        case 'RECEIVED':
        case 'ACCEPTED':
        case 'REACHED':
        case 'ARRIVED':
          return (
            <Box sx={styles.progressContainer}>
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {inactiveIndicator()}
              {inactiveIndicator()}
              {inactiveIndicator()}
            </Box>
          );
        case 'PICKEDUP':
          return (
            <Box sx={styles.progressContainer}>
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {activeIndicator()}
              {inactiveIndicator()}
            </Box>
          );
        case 'QUEUE':
        case 'DELIVERING':
        case 'PICKUP_USER':
          return (
            <Box sx={styles.progressContainer}>
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {inactiveIndicator()}
            </Box>
          );
        case 'COMPLETED':
          return (
            <Box sx={styles.progressContainer}>
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {fullActiveIndicator()}
              {fullActiveIndicator()}
            </Box>
          );
        case 'CANCELLED':
          return <Box sx={styles.progressContainer}></Box>;
        default:
          return null;
      }
    }
  };
 
  return (
    <Box sx={styles.container}>
      <Typography variant="h6" component="div" sx={styles.textBold}>
        {text}
      </Typography>
      <Typography variant="body2" component="div" sx={styles.text}>
        {arrivingAt}
      </Typography>
      <Box>{renderProgress()}</Box>
      <Typography variant="body2" component="div" sx={styles.text}>
        {latestArrival}
      </Typography>
    </Box>
  );
};

export default LiveOrderTrackingView;
