import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  Badge,
  Grid,
  Box,
  Hidden,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Avatar,
  CircularProgress,
  Collapse,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Search as SearchIcon,
  ShoppingCart as ShoppingCartIcon,
  LocationOn as LocationOnIcon,
  ExpandMore as ExpandMoreIcon,
  AccountCircle as AccountCircleIcon,
  Favorite as FavoriteIcon,
  Wallet as WalletIcon,
  Fastfood as FastfoodIcon,
  Help as HelpIcon,
  LocalOffer as LocalOfferIcon,
  CardGiftcard as CardGiftcardIcon,
  LocalDining as LocalDiningIcon,
  Business as BusinessIcon,
  Restaurant as RestaurantIcon,
  Apple as AppleIcon,
  Android as AndroidIcon,
  ListAlt as ListAltIcon,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { Images } from "../../../assets/images";
import "./Drawer.css";
import { useDispatch, useSelector } from "react-redux";
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import {
  setAppliedPromoCode,
  setCartCounter,
  setPreviousOrderFeedback,
  setUserData,
  setUserLocation,
} from "../../../redux/slices/UserDataSlices";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  setCartData,
  setOnlineCartData,
  setRequiredList,
} from "../../../redux/slices/CartSlice";
import Promotions from "../../../views/admin/pages/Promotion";
import SimpleDialog from "../../../components/Dialog";
import Colors from "../../../assets/styles";
import Location from "../../../components/Location/index"
import ChangePIN from "../../../components/ChangePin";
import EditAccount from "../../../components/EditAccount";

const Drawer = ({ isOpen, toggleDrawer }) => {
  const navigate = useNavigate();
  const googleCredentials = localStorage.getItem("googleCredentials");
  const phoneCredentails = localStorage.getItem("phoneCredentails");
  const parsedData = JSON.parse(googleCredentials);
  const parsedData1 = JSON.parse(phoneCredentails);
  const [promotiondialog, setPromotionDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locationDialog, setLocationDialog] = useState(false);
  const [openManageAccount, setOpenManageAccount] = useState(false); // State for managing collapse
  const [pinDialog, setPinDialog] = useState(false); // State for managing collapse
  const [profileDialog, setProfileDialog] = useState(false); // State for managing collapse

  const userData = useSelector((state) => state.userData.userData);
  console.log(userData);
  const dispatch = useDispatch();
  const removeToken = () => {
    setTimeout(() => {
      localStorage.removeItem("token_id");
      localStorage.removeItem("googleCredentials");
      localStorage.clear();
      dispatch(setUserData({}));
      dispatch(setUserLocation({}));
      dispatch(setOnlineCartData([]));
      dispatch(setCartData([]));
      dispatch(setAppliedPromoCode([]));
      dispatch(setCartCounter(0));
      dispatch(setRequiredList([]));
      dispatch(setPreviousOrderFeedback([]));
      setLoading(false);
      toggleDrawer();
    }, 1000);
  };

  const handleSignOut = () => {
    setLoading(true);
    removeToken();
  };

  return (
    <>
      <MuiDrawer anchor="left" open={isOpen} onClose={toggleDrawer}>
        <Box className="drawer" onClick={(e) => e.stopPropagation()}>
          {userData?.id ? (
            <>
              <Box className="drawer-header">
                <AccountCircleIcon className="user-icon" />
                <Box className="user-info">
                  <Box className="user-name">
                    {userData?.name || userData?.displayName}
                  </Box>
                  <Box
                    className="manage-account"
                    onClick={() => setOpenManageAccount(!openManageAccount)}
                    sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                  >
                    Manage account
                    <ExpandMoreIcon
                      sx={{
                        transform: openManageAccount ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Collapse in={openManageAccount} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button onClick={() => {navigate("/home");toggleDrawer()}}>
                    <ListItemIcon className="menu-icon">
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" className="menu-text" />
                  </ListItem>
                  <ListItem button onClick={() => setLocationDialog(true)}>
                    <ListItemIcon className="menu-icon">
                      <LocationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Address" className="menu-text" />
                  </ListItem>
                  <ListItem button onClick={() => {setPinDialog(true)}}>
                    <ListItemIcon className="menu-icon">
                      <LockIcon />
                    </ListItemIcon>
                    <ListItemText primary="Change PIN" className="menu-text" />
                  </ListItem>
                  <ListItem button onClick={() => {setProfileDialog(true)}}>
                    <ListItemIcon className="menu-icon">
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Edit Profile" className="menu-text" />
                  </ListItem>
                </List>
                <Divider />
              </Collapse>
              <List className="drawer-menu">
                <ListItem
                  button
                  onClick={() => {
                    navigate("/orders-list");
                    toggleDrawer();
                  }}
                >
                  <ListItemIcon className="menu-icon">
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Orders" className="menu-text" />
                </ListItem>
                <ListItem button onClick={() => {navigate("/favorites");toggleDrawer()}}>
                  <ListItemIcon className="menu-icon">
                    <FavoriteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Favorites" className="menu-text" />
                </ListItem>
                <ListItem button onClick={() => {navigate("/wallet");toggleDrawer()}}>
                  <ListItemIcon className="menu-icon">
                    <WalletIcon />
                  </ListItemIcon>
                  <ListItemText primary="Wallet" className="menu-text" />
                </ListItem>

                <ListItem button onClick={() => setPromotionDialog(true)}>
                  <ListItemIcon className="menu-icon">
                    <LocalOfferIcon />
                  </ListItemIcon>
                  <ListItemText primary="Promotions" className="menu-text" />
                </ListItem>
                <ListItem button onClick={() => {navigate("/my-gift");toggleDrawer()}}>
                  <ListItemIcon className="menu-icon">
                    <CardGiftcardIcon />
                  </ListItemIcon>
                  <ListItemText primary="My Gifts" className="menu-text" />
                </ListItem>
                <ListItem button onClick={() => {navigate("/send-gift");toggleDrawer()}}>
                  <ListItemIcon className="menu-icon">
                    <CardGiftcardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Send a Gift" className="menu-text" />
                </ListItem>
                <ListItem sx={{ mt: 5, cursor: "pointer" }} onClick={handleSignOut}>
                  <ListItemIcon className="menu-icon">
                    <LogoutIcon />
                  </ListItemIcon>
                  <Typography className="footer-item">
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Sign out"
                    )}
                  </Typography>
                </ListItem>
              </List>
              <Divider />
            </>
          ) : (
            <>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/login")}
              >
                <Box
                  sx={{
                    background: Colors.black,
                    color: Colors.white,
                    textAlign: "center",
                    fontWeight: "500",
                    mt: 2,
                    p: 2,
                    width: "80%",
                    margin: " 21px auto",
                    borderRadius: "8px",
                  }}
                >
                  Sign Up
                </Box>
                <Box
                  sx={{
                    background: Colors.grey,
                    color: Colors.black,
                    textAlign: "center",
                    fontWeight: "500",
                    mt: 2,
                    p: 2,
                    width: "80%",
                    margin: " 11px auto",
                    borderRadius: "8px",
                  }}
                >
                  Log In
                </Box>
              </Box>
            </>
          )}
          <div
            className="drawer-footer"
            style={{ position: "absolute", bottom: "0px", width: "88%" }}
          >
            <div className="app-promo">
              <img src={Images.logo} alt="Uber Eats" className="app-logo" />
              <div className="promo-text">
                There's more to love
                <br />
                in the app.
              </div>
            </div>
            <div className="app-buttons">
              <Link to="https://play.google.com/store/apps/details?id=com.letseat.app&hl=en&gl=US" target="blank" className="app-button">
                <AppleIcon className="app-icon" />
                <span>iPhone</span>
              </Link>
              <Link  className="app-button" to="https://apps.apple.com/pk/app/lets-eat-cayman/id1503188422" target="blank" >
                <AndroidIcon className="app-icon" />
                <span>Android</span>
              </Link>
            </div>
          </div>
        </Box>
      </MuiDrawer>

      {promotiondialog && (
        <>
          <SimpleDialog
            open={promotiondialog}
            onClose={() => setPromotionDialog(false)}
            title="Add Promotions"
          >
            <Box>
              <Promotions setPromotionDialog={setPromotionDialog} />
            </Box>
          </SimpleDialog>
        </>
      )}
       {locationDialog && (
        <>
           <SimpleDialog   open={locationDialog}
          onClose={() => setLocationDialog(false)}
          title="Select Address ">
          <Box>
            <Location setLocationDialog={setLocationDialog} />

          </Box>
        </SimpleDialog>
        </>
      )}
       {setPinDialog && (
        <>
           <SimpleDialog   open={pinDialog}
          onClose={() => setPinDialog(false)}
          title="Change PIN ">
          <Box>
            <ChangePIN setPinDialog={setPinDialog} />

          </Box>
        </SimpleDialog>
        </>
      )}
       {profileDialog && (
        <>
           <SimpleDialog   open={profileDialog}
          onClose={() => setProfileDialog(false)}
          title="Edit Account ">
          <Box>
            <EditAccount setProfileDialog={setProfileDialog} />

          </Box>
        </SimpleDialog>
        </>
      )}
    </>
  );
};

export default Drawer;
  