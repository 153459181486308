import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types'; // for prop type validation
import { Dialog, Box, Button, Typography } from '@mui/material'; // MUI components
// import Header from '../../../component/Header/Header'; // Ensure this is compatible with web

const CybersourceAuthenticationWebViewModal = ({
  modalVisible,
  setClose,
  authToken,
  onBackPress,
  onSuccess,
}) => {
  const [showModal, setShowModal] = useState(modalVisible);
  const iframeRef = useRef();

  const htmlContent = `
    <!DOCTYPE html>
    <html>
    <head>
        <title>Payment Form</title>
    </head>
    <body>
        <form
            id="step-up-form"
            method="post"
            action="https://centinelapistag.cardinalcommerce.com/V2/Cruise/StepUp"
        >
            <input type="hidden" name="JWT" value="${authToken}">
            <input type="hidden" name="MD" value="optionally_include_custom_data_that_will_be_returned_as_is">
        </form>
        <script>
            // Submit the form as the page loads
            document.getElementById('step-up-form').submit();
        </script>
    </body>
    </html>
  `;

  useEffect(() => {
    if (iframeRef.current) {
      const doc = iframeRef.current.contentWindow.document;
      doc.open();
      doc.write(htmlContent);
      doc.close();
    }
  }, [authToken, htmlContent]);

  const handleIframeMessage = (event) => {
    try {
      // Check the event source to ensure it's from a trusted source
      if (event.origin !== 'expected-origin') return; // Replace 'expected-origin' with the actual origin
  
      console.log('Received message:', event.data);
  
      // Attempt to parse the event data
      const data = JSON.parse(event.data);
  
      // Check if the parsed data contains a success property
      if (data.success) {
        onSuccess('ENROLLMENT_AUTH', data);
      }
    } catch (error) {
      console.error('Error processing message:', error);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  return (
    <Dialog
      open={showModal}
      onClose={setClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        },
      }}
    >
     
      <Box
        sx={{
          width: '90%',
          height: '80%',
          backgroundColor: 'white',
          borderRadius: 1,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
         <Box>Authenticating, Please wait...</Box>
        <iframe
          ref={iframeRef}
          title="Cybersource Authentication"
          srcDoc={htmlContent}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
          sandbox="allow-scripts allow-same-origin"
          onError={(err) => console.error('Iframe error:', err)}
        />
      </Box>
      <Button
        onClick={setClose}
        variant="contained"
        color="error"
        sx={{ mt: 2 }}
      >
        Close
      </Button>
    </Dialog>
  );
};

CybersourceAuthenticationWebViewModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  authToken: PropTypes.string.isRequired,
  onBackPress: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default CybersourceAuthenticationWebViewModal;
