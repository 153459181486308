import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Rating,
  // ToggleButton,
  // ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Colors from "../../../../assets/styles";
import { Images } from "../../../../assets/images";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import InfoIcon from "@mui/icons-material/Info";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import SliderComponent from "../../../../components/Slider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ContextApi } from "../../../../store/context";
import CategoriesServices from "../../../../api/CategoriesServices";
import moment from "moment";
import SearchServices from "../../../../api/SearchServices";
import { debounce } from "lodash";
import { Skeleton } from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear"; // Import the Clear icon
import { useDispatch, useSelector } from "react-redux";
import { convertTime, dayNTimeHandler } from "../../../../utils/helper";
import { groupOrderApiCall } from "../../../../api/GroupOrderServices";
import { clearMenuTree } from "../../../../redux/slices/CartSlice";
import CartServices from "../../../../api/CartServices";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MapComponent from "../../../../components/MapContent";
import StarIcon from "@mui/icons-material/Star";
import axios from "axios";
import { ArrowBackIosNew } from "@mui/icons-material";

function DropDown({ anchorEl, openDropdown, handleClose, setActiveFavourite }) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={openDropdown}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          width: 300,
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          borderRadius: "10px",
          ".MuiSvgIcon-root": {
            width: 50,
            height: 30,
            ml: 0.5,
            mr: 0.5,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 50,
            height: 10,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem sx={{ fontWeight: "600" }}>
        <ListItemIcon>
          <PersonAddIcon sx={{ fontSize: "20px", fill: Colors.black }} />
        </ListItemIcon>
        Start Group Order
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />

      <MenuItem sx={{ fontWeight: "600" }}>
        <ListItemIcon>
          <InfoIcon sx={{ fontSize: "20px", fill: Colors.black }} />
        </ListItemIcon>
        Stores Info
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem sx={{ fontWeight: "600" }}>
        <ListItemIcon>
          <FavoriteBorderIcon
            onClick={() => setActiveFavourite(true)}
            sx={{ fontSize: "20px", fill: Colors.black }}
          />
        </ListItemIcon>
        Add to Favorite
      </MenuItem>
    </Menu>
  );
}

const ToggleSlider = styled(Box)(({ transformX }) => ({
  transition: "transform 300ms ease-in-out",
  transform: `translateX(${transformX}%)`,
  position: "absolute",
  width: "50%", // Make sure the slider covers half the container
  height: "100%",
  backgroundColor: "#ffffff", // Slider color
  borderRadius: "20px", // Rounded corners for slider
}));

const ToggleButtonContainer = styled(Box)({
  display: "flex",
  position: "relative",
  width: "180px", // Set a fixed width for the container
  height: "40px",
  borderRadius: "20px",
  backgroundColor: "#f0f0f0", // Background color for non-active area
  overflow: "hidden",
  border: "2px solid #aeaeae47", // Border color adjusted for visibility
});

const ToggleOption = styled(Button)(({ active }) => ({
  flex: 1,
  height: "100%",
  borderRadius: "20px",
  backgroundColor: "transparent", // Remove background color here
  color: "black", // Black text for all states
  "&:hover": {
    backgroundColor: "rgba(255,255,255,0.3)", // Light hover effect
  },
}));

const ToggleButton = ({
  selectedType,
  setSelectedType,
  isTakeaway,
  deliveryDisabled,
  pickupDisabled,
}) => {
  const [transformX, setTransformX] = useState(0);

  useEffect(() => {
    if (pickupDisabled) {
      setTransformX(0);
    } else if (deliveryDisabled) {
      setTransformX(50); // 50% of the container width to right side
    } else {
      setTransformX(selectedType === "Pickup" ? 100 : 0); // Correctly aligns with the options
    }
  }, [pickupDisabled, deliveryDisabled, selectedType]);

  return (
    <>
      <Box>
        <Box>
          <ToggleButtonContainer>
            <ToggleSlider transformX={transformX} />
            <ToggleOption
              disabled={deliveryDisabled}
              active={selectedType === "Delivery"}
              onClick={() => setSelectedType("Delivery")}
            >
              Delivery
            </ToggleOption>
            <ToggleOption
              active={selectedType === "Pickup"}
              onClick={() => setSelectedType("Pickup")}
            >
              Pickup
            </ToggleOption>
          </ToggleButtonContainer>
        </Box>
      </Box>
    </>
  );
};

export default function ProductDetail() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [currentCategoryInView, setCurrentCategoryInView] = useState(null);
  const [selectedType, setSelectedType] = useState("Delivery");
  const HeaderHeight = 200;
  const [activeFavourite, setActiveFavourite] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deliveryMethod, setDeliveryMethod] = useState("delivery");
  const [value, setValue] = useState("delivery");
  const [activeCategory, setActiveCategory] = useState(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const paramsId = useParams();
  const sectionsRefs = useRef({});
  const [searchText, setSearchText] = useState("");
  const [activeSection, setActiveSection] = useState("");
  const [detailData, setDetailData] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1000);
  const [searchResultData, setSearchResultData] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const [isGroupOrder, setIsGroupOrder] = useState(false);
  const [loader, setLoader] = useState(true);
  const [shopItemsInCart, setShopItemsInCart] = useState([]);
  const [totalCartItems, setTotalCartItems] = useState(0);
  const [address, setAddress] = useState("");
  console.log(groupId);
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let sleep = () => new Promise((r) => setTimeout(r, 1000));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  const handleDeliveryMethodChange = (event, newMethod) => {
    if (newMethod !== null) {
      setDeliveryMethod(newMethod);
    }
  };

  const openDropdown = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  
  // const scrollLeft = () => {
  //   scrollRef.current.scrollBy({ left: -100, behavior: "smooth" });
  // };

  // const scrollRight = () => {
  //   scrollRef.current.scrollBy({ left: 100, behavior: "smooth" });
  // };

  // const handleScrollOnClick = (item) => {
  //   const sectionId = `section-${item.category_id}`;
  //   const sectionElement = document.getElementById(sectionId);

  //   if (sectionElement) {
  //     const topPos =
  //       sectionElement.getBoundingClientRect().top + window.scrollY;
  //     const dynamicOffset = topPos - HeaderHeight;
  //     window.scrollTo({
  //       top: dynamicOffset,
  //       behavior: "smooth",
  //       inline: "center", 
  //     });

  //     setActiveCategory(item.category_id);
  //   }
  // };

  // const handleScroll = () => {
  //   const sections = document.querySelectorAll("[id^='section-']");
  //   let currentCategory = null;

  //   sections.forEach((section) => {
  //     const sectionTop = section.getBoundingClientRect().top;
  //     if (sectionTop <= HeaderHeight && sectionTop > -section.offsetHeight) {
  //       currentCategory = section.getAttribute("id").split("-")[1];
  //     }
  //   });

  //   if (currentCategory !== activeCategory) {
  //     setActiveCategory(currentCategory);
  //   }
  // };
   
  
 
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [activeCategory]);
  // Scroll Left and Right Functions
  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -150, behavior: "smooth" });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 150, behavior: "smooth" });
  };

  // Check if activeCategory is out of view and scroll accordingly
  const checkActiveCategoryPosition = () => {
    if (scrollRef.current) {
      const activeElement = scrollRef.current.querySelector(`[data-id='${activeCategory}']`);
      if (activeElement) {
        const { left, right } = activeElement.getBoundingClientRect();
        const containerLeft = scrollRef.current.getBoundingClientRect().left;
        const containerRight = scrollRef.current.getBoundingClientRect().right;

        if (left < containerLeft) {
          // If active item is to the left, scroll left
          scrollLeft();
        } else if (right > containerRight) {
          // If active item is to the right, scroll right
          scrollRight();
        }
      }
    }
  };

  const handleScrollOnClick = (item) => {
    const sectionId = `section-${item.category_id}`;
    const sectionElement = document.getElementById(sectionId);

    if (sectionElement) {
      const topPos = sectionElement.getBoundingClientRect().top + window.scrollY;
      const dynamicOffset = topPos - HeaderHeight;
      window.scrollTo({
        top: dynamicOffset,
        behavior: "smooth",
      });

      setActiveCategory(item.category_id);
    }
  };

  const handleScroll = () => {
    const sections = document.querySelectorAll("[id^='section-']");
    let currentCategory = null;

    sections.forEach((section) => {
      const sectionTop = section.getBoundingClientRect().top;
      if (sectionTop <= HeaderHeight && sectionTop > -section.offsetHeight) {
        currentCategory = section.getAttribute("id").split("-")[1];
      }
    });

    if (currentCategory !== activeCategory) {
      setActiveCategory(currentCategory);
    }
  };

  useEffect(() => {
    checkActiveCategoryPosition(); // Check if scrolling is needed when activeCategory changes
  }, [activeCategory]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeCategory]);
  

  const day = moment().format("ddd");
  const time = moment().format("HH:mm");
  const shop_id = paramsId?.id;
  const basedOn = "shop";
  const TypeID = 1;
  const userId = userData ? userData?.id : "";

  const debouncedSearch = useCallback(
    debounce(async (text) => {
      try {
        const result = await SearchServices.getSearchData(
          text,
          "All",
          page,
          limit,
          shop_id,
          basedOn
        );
        setSearchResultData(result);
      } catch (error) {
        console.log("Error fetching search data:", error);
      }
    }, 300),
    [page, limit, shop_id, basedOn]
  );

  const handleSearch = (event) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
    debouncedSearch(newSearchText);
  };

  const getShopProductsHandler = async () => {
    setLoader(true);
    let param = {
      user_id: userData?.id,
      shopid: paramsId?.id,
      based_on: basedOn,
      day: dayNTimeHandler().day,
      time: dayNTimeHandler().time,
      TypeID: 1,
    };
    try {
      await sleep();
      const result = await CategoriesServices.getCategoriesShopDetail(
        param?.shopid,
        param?.day,
        param?.time,
        param?.based_on,
        param?.TypeID,
        param?.user_id
      );

      if (userData?.id != undefined) {
        const res = await groupOrderApiCall.getGroupOrderCartList({
          user_id: userData?.id,
        });
        console.log(res);
        for (let index = 0; index < res.length; index++) {
          console.log(res[index]?.shop_id);
          if (res[index]?.shop_id === result?.shopid) {
            setIsGroupOrder(true);
            setGroupId(res[index]?.group_id);
            break;
          } else {
            setIsGroupOrder(false);
          }
        }
      }
      console.log(result);
      setDetailData(result);
      setLoader(false);
    } catch (error) {
      console.warn("get shop product handler error ", error);

      console.log(
        "🚀 ~ file: RestaurantDetail.js:55 ~ getShopProductsHandler ~ error:",
        error
      );
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };
  const getOnlineCartData = async () => {
    setLoader(true);
    try {
      const result = await CartServices.getCartDetails(userData?.id);

      console.warn("get cart data result ", result);

      let count = 0;
      for (let index = 0; index < result?.length; index++) {
        if (
          shop_id == result?.[index].shop_id &&
          result?.[index].based_on === "store"
        ) {
          setShopItemsInCart(result?.[index]?.items);
        }
        count += result?.[index]?.items.length;
      }
      setTotalCartItems(count);
      // dispatch(setOnlineCartData(result?.items));
    } catch (error) {
      console.warn("get cart data error ", RangeError);
      console.log("file: index.js:37 => getCartData => error:", error);
    } finally {
      // setLoader(false);
    }
  };
  useEffect(() => {
    getOnlineCartData();
    getShopProductsHandler();
  }, []);
  console.log(shopItemsInCart);
  const scrollRef = useRef(null);

  const latitude = detailData.latitude;
  const longitude = detailData?.longitude;
  const mapKey = "AIzaSyC1Gh661ToYHtDs98IVxaZHi5_XqVMwy6Y";
  
 
  const fetchAddress = async () => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${mapKey}`
      );
      if (response.data.status === "OK") {
        const address = response.data.results[0].formatted_address;
        setAddress(address);
      } else {
        console.error("Geocoding failed:", response.data.status);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  setTimeout(() => {
    fetchAddress();
  }, 1000);

  useEffect(() => {
    const checkOverflow = () => {
      const el = scrollRef.current;
      if (el) {
        setIsOverflowing(el.scrollWidth > el.clientWidth);
      }
    };
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [detailData]);
  return (
    
    <Container sx={{ mt: 10, maxWidth: "1300px !important" }}>
      <Grid container rowGap={3}>
        <Grid item md={12} sm={12} xs={12}>
          {loader ? (
            <Skeleton variant="rectangular" width="100%" height={250} />
          ) : (
            <Card
              sx={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "10px",
                  left: "15px",
                  background: Colors.white,
                  borderRadius: "50%",
                  mb: 2,
                }}
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIosNew sx={{ fontSize: "20px" }} />
                </IconButton>
              </Box>
              <IconButton
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  right: "50px",
                  top: "10px",
                  background: Colors.white,
                  color: Colors.black,
                  "&:hover": {
                    background: Colors.white,
                  },
                }}
              >
                {activeFavourite ? (
                  <FavoriteIcon
                    onClick={() => setActiveFavourite(false)}
                    sx={{ fontSize: "20px", fill: Colors.black }}
                  />
                ) : (
                  <FavoriteBorderIcon
                    onClick={() => setActiveFavourite(true)}
                    sx={{ fontSize: "20px", fill: Colors.black }}
                  />
                )}
              </IconButton>
              <IconButton
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  right: "10px",
                  top: "10px",
                  background: Colors.white,
                  color: Colors.black,
                  "&:hover": {
                    background: Colors.white,
                  },
                }}
                onClick={handleClick}
              >
                <MoreHorizIcon sx={{ fontSize: "20px", fill: Colors.black }} />
              </IconButton>
              <DropDown
                anchorEl={anchorEl}
                openDropdown={openDropdown}
                handleClose={handleClose}
                setActiveFavourite={setActiveFavourite}
              />
              <Grid container>
                <Grid item md={12} xs={12}>
                  <CardMedia
                    component="img"
                    sx={{ height: "250px" }}
                    image={
                      baseUrl +
                      "/docs/files/shops/banner/" +
                      detailData?.default_banner
                    }
                  />
                </Grid>
              </Grid>
            </Card>
          )}
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container>
            <Grid item md={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {loader ? (
                  <Skeleton
                    sx={{ marginTop: "10px" }}
                    variant="rectangular"
                    width="80%"
                    height={25}
                  />
                ) : (
                  <Box
                    sx={{
                      fontWeight: "700",
                      fontSize: "36px",
                      fontFamily: "Noto Sans",
                    }}
                  >
                    {detailData?.name}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container>
            <Grid item md={12} xs={12} sx={{ width: "100%" }}>
              {selectedType === "Delivery" && (
                <>
                  <Box
                    sx={{
                      position: "sticky",
                      top: { md: "8.5%", sm: "8.5%", xs: "13.7%" },
                      zIndex: "1200",
                      background: "white",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        {loader ? (
                          <Skeleton
                            sx={{ marginTop: "10px" }}
                            variant="rectangular"
                            width="40%"
                            height={25}
                          />
                        ) : (
                          <>
                            <Typography
                              variant={"subtitle1"}
                              sx={{ fontWeight: "bold" }}
                            >
                              Menu
                            </Typography>
                            <Typography>
                              {detailData?.start_time +
                                "-" +
                                detailData?.end_time}
                            </Typography>
                          </>
                        )}
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "end" }}>
                        {loader ? (
                          <Skeleton
                            sx={{ marginTop: "10px", borderRadius: "25px" }}
                            variant="rectangular"
                            width="100%"
                            height={25}
                          />
                        ) : (
                          <TextField
                            variant="outlined"
                            value={searchText}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                width: { md: "356px", sm: "100%", xs: "100%" },
                                background: Colors.grey,
                                height: "35px",
                                borderRadius: "25px",
                                paddingRight: "8px",
                                "& fieldset": {
                                  borderColor: "#E0E0E0",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#BDBDBD",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#BDBDBD",
                                },
                              },
                            }}
                            onChange={handleSearch}
                            placeholder={`Search in ${
                              detailData?.name || "..."
                            }`}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </Box>
                    </Box>

                    {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        ref={scrollRef}
                        sx={{
                          overflowX: "auto",
                          display: "flex",
                          background: "white",
                          height: "62px",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                          scrollbarWidth: "none",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                          flexGrow: 1,
                        }}
                      >
                        {detailData?.shopCategories?.map((item, index) => (
                          <>
                            {loader ? (
                              <Skeleton
                                key={item.id}
                                sx={{ marginTop: "10px" }}
                                variant="rectangular"
                                width="10%"
                                height={25}
                              />
                            ) : (
                              <Box
                                key={item.id}
                                sx={{
                                  borderBottom:
                                    activeCategory == item.category_id
                                      ? "4px solid black"
                                      : `4px solid ${Colors.grey}`,
                                  "&:hover": {
                                    backgroundColor: Colors.grey,
                                  },
                                  cursor: "pointer",
                                }}
                                onClick={() => handleScrollOnClick(item)}
                              >
                                <Typography
                                  sx={{
                                    py: "10px",
                                    px: "10px",

                                    fontWeight:
                                      activeCategory === item.category_id
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {item?.name}
                                </Typography>
                              </Box>
                            )}
                          </>
                        ))}
                      </Box>
                      {isOverflowing && (
                        <>
                          <IconButton
                            sx={{
                              backgroundColor: "#f0f0f0",
                              borderRadius: "50%",
                              p: 1,
                              mx: 1,
                              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            }}
                            onClick={scrollLeft}
                          >
                            <ArrowBackIcon />
                          </IconButton>
                          <IconButton
                            sx={{
                              backgroundColor: "#f0f0f0",
                              borderRadius: "50%",
                              p: 1,
                              mx: 1,
                              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            }}
                            onClick={scrollRight}
                          >
                            <ArrowForwardIcon />
                          </IconButton>
                        </>
                      )}
                    </Box> */}

<Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        ref={scrollRef}
        sx={{
          overflowX: "auto",
          display: "flex",
          background: "white",
          height: "62px",
          alignItems: "center",
          whiteSpace: "nowrap",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": { display: "none" },
          flexGrow: 1,
        }}
      >
        {detailData?.shopCategories?.map((item) => (
          loader ? (
            <Skeleton
              key={item.id}
              sx={{ marginTop: "10px" }}
              variant="rectangular"
              width="10%"
              height={25}
            />
          ) : (
            <Box
              key={item.id}
              data-id={item.category_id}
              sx={{
                borderBottom:
                  activeCategory == item.category_id
                    ? "4px solid black"
                    : `4px solid ${Colors.grey}`,
                "&:hover": {
                  backgroundColor: Colors.grey,
                },
                cursor: "pointer",
              }}
              onClick={() => handleScrollOnClick(item)}
            >
              <Typography
                sx={{
                  py: "10px",
                  px: "10px",
                  fontWeight: activeCategory === item.category_id ? "bold" : "normal",
                }}
              >
                {item?.name}
              </Typography>
            </Box>
          )
        ))}
      </Box>
      {isOverflowing && (
        <>
          <IconButton
            sx={{
              backgroundColor: "#f0f0f0",
              borderRadius: "50%",
              p: 1,
              mx: 1,
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            onClick={scrollLeft}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            sx={{
              backgroundColor: "#f0f0f0",
              borderRadius: "50%",
              p: 1,
              mx: 1,
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            onClick={scrollRight}
          >
            <ArrowForwardIcon />
          </IconButton>
        </>
      )}
    </Box>
                  </Box>

                  <Box>
                    {loader ? (
                      <>
                        <Skeleton
                          sx={{ marginTop: "10px" }}
                          variant="rectangular"
                          width="30%"
                          height={25}
                        />

                        <Grid container spacing={2}>
                          {Array.from({ length: 4 }).map((_, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                              <Skeleton
                                sx={{
                                  marginTop: "10px",
                                  borderRadius: "20px",
                                }}
                                variant="rectangular"
                                width="100%"
                                height="150px"
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    ) : searchText === "" ? (
                      detailData?.shopCategories?.map((item) => (
                        <>
                          <div
                            key={item.category_id}
                            id={`section-${item.category_id}`}
                          >
                            {loader ? (
                              <Skeleton
                                sx={{ marginTop: "10px" }}
                                variant="rectangular"
                                width="30%"
                                height="45px"
                              />
                            ) : (
                              <Box
                                sx={{
                                  fontSize: "24px",
                                  fontWeight: "700",
                                  mt: 2,
                                  fontFamily: "Noto Sans",
                                }}
                                ref={(el) =>
                                  (sectionsRefs.current[item.category_id] = el)
                                }
                                id={`section-${item.category_id}`}
                              >
                                {item?.name.toUpperCase()}
                              </Box>
                            )}
                            <Grid
                              container
                              spacing={2}
                              sx={{ mt: 2 }}
                              id="scroll_grid"
                            >
                              {item?.products?.map((elem, index) => {
                                const hasImage = elem?.pic !== null;
                                const gridColumnSpan = hasImage ? 7 : 12;

                                return (
                                  <Grid item key={index} xs={12} sm={6} md={6}>
                                    {loader ? (
                                      <Skeleton
                                        sx={{
                                          marginTop: "10px",
                                          borderRadius: "20px",
                                        }}
                                        variant="rectangular"
                                        width="100%"
                                        height="200px"
                                      />
                                    ) : (
                                      <Card
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          position: "relative",
                                          borderRadius: "20px",
                                          cursor: "pointer",
                                          boxShadow: "none",
                                          border: "1px solid rgb(243 243 243)",
                                          height: "160px",
                                        }}
                                      >
                                        <Grid
                                          container
                                          sx={{ flexWrap: "nowrap !important" }}
                                          onClick={() => {
                                            dispatch(clearMenuTree(null));
                                            navigate(
                                              `/product/detail/${elem.product_id}`,
                                              {
                                                state: {
                                                  elem,
                                                  group_id: groupId,
                                                  shopData: shopItemsInCart,
                                                  basedOn: "shop",
                                                },
                                              }
                                            );
                                          }}
                                        >
                                          <Grid
                                            item
                                            md={gridColumnSpan}
                                            xs={12}
                                          >
                                            <CardContent
                                              sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 1,
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: "700",
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {elem?.name}
                                              </Typography>
                                              <Box
                                                sx={{
                                                  fontFamily: "Noto Sans",
                                                  fontWeight: "400",
                                                  fontSize: "13px",
                                                  display: "flex",
                                                }}
                                              >
                                                ${elem?.price}
                                              </Box>
                                              <Typography
                                                variant="caption"
                                                sx={{
                                                  color: "rgb(117, 117, 117)",
                                                }}
                                              >
                                                {elem?.description}
                                              </Typography>
                                            </CardContent>
                                          </Grid>
                                          {hasImage && (
                                            <Grid item md={5} xs={12}>
                                              <CardMedia
                                                component="img"
                                                sx={{
                                                  height: "100%",
                                                  opacity: "0.9",
                                                  // objectFit:"contain",
                                                }}
                                                image={`${baseUrl}/docs/files/products/${elem?.pic}`}
                                              />
                                            </Grid>
                                          )}
                                        </Grid>
                                      </Card>
                                    )}
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </div>
                        </>
                      ))
                    ) : (
                      <>
                        {searchResultData?.map((elem) => (
                          // Iterate over products inside each elem
                          <Grid container spacing={2} sx={{ mt: 2 }}>
                            {elem.products?.map((product, index) => {
                              const hasImage = product?.pic !== null;
                              const gridColumnSpan = hasImage ? 7 : 12;
                              return (
                                <Grid item key={index} xs={12} sm={6} md={6}>
                                  {loader ? (
                                    <Skeleton
                                      sx={{
                                        marginTop: "10px",
                                        borderRadius: "20px",
                                      }}
                                      variant="rectangular"
                                      width="100%"
                                      height="200px"
                                    />
                                  ) : (
                                    <Card
                                      sx={{
                                        margin: "0 2px",
                                        display: "flex",
                                        justifyContent: "center",
                                        position: "relative",
                                        borderRadius: "20px",
                                        cursor: "pointer",
                                        boxShadow: "none",
                                        border: "1px solid rgb(243 243 243)",
                                      }}
                                    >
                                      <Grid
                                        container
                                        sx={{ flexWrap: "nowrap !important" }}
                                        onClick={() => {
                                          dispatch(clearMenuTree(null));

                                          navigate(
                                            `/product/detail/${product.product_id}`,
                                            {
                                              state: {
                                                product,
                                                group_id: groupId,
                                                shopData: shopItemsInCart,
                                                basedOn: "shop",
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        <Grid item md={gridColumnSpan} xs={12}>
                                          <CardContent>
                                            <Typography
                                              sx={{
                                                fontWeight: "700",
                                                fontSize: "20px",
                                              }}
                                            >
                                              {product?.name}
                                            </Typography>
                                            <Box
                                              sx={{
                                                fontWeight: "400",
                                                fontSize: "15px",
                                                display: "flex",
                                              }}
                                            >
                                              ${product?.price}
                                            </Box>
                                            <Typography
                                              variant="body2"
                                              sx={{
                                                fontSize: "15px",
                                                color: "rgb(117, 117, 117)",
                                              }}
                                            >
                                              {product?.description}
                                            </Typography>
                                          </CardContent>
                                        </Grid>
                                        {hasImage && (
                                          <Grid item md={5} xs={12}>
                                            <CardMedia
                                              component="img"
                                              sx={{
                                                height: "200px",
                                                opacity: "0.9",
                                              }}
                                              image={`${baseUrl}/docs/files/products/${product?.pic}`}
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Card>
                                  )}
                                </Grid>
                              );
                            })}
                          </Grid>
                        ))}
                      </>
                    )}
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
