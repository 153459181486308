import { CartRoutes } from "./route";
import { post, get, put } from "../tokenindex";

const CartServices = {
  addToCart: async (obj) => {
    let result = await post(CartRoutes.addToCart, obj);
    return result;
  },
  getCartDetails: async (userId) => {
    console.log(userId);
    let result = await get(CartRoutes.getCartDetails + `?user_id=${userId}`);
    return result;
  },
  
  removeProductFromCart: async (obj) => {
    let result = await get(CartRoutes.removeProductFromCart, obj);
    return result;
  },
  updateAddToCart: async (obj) => {
    let result = await post(CartRoutes.updateAddToCart, obj);
    return result;
  },
  setupPayerAuth: async (obj) => {
    let result = await post(CartRoutes.setupPayerAuth, obj);
    return result;
  },
  payerAuthenticationValidationService: async (obj) => {
    let result = await post(
      CartRoutes.payerAuthenticationValidationService,
      obj
    );
    return result;
  },
  placeOrder: async (obj) => {
    let result = await post(CartRoutes.placeOrder, obj);
    return result;
  },
  payerAuthenticationCheckEnrollmentService: async (obj) => {
    let result = await post(
      CartRoutes.payerAuthenticationCheckEnrollmentService,
      obj
    );
    return result;
  },
};
export default CartServices;
