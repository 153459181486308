import { walletEndpoints } from './route';
import { get, post, patch, deleted } from '../tokenindex';

 const WalletApiCall = {
  getWalletBalance: async params => {
    let result = await get(walletEndpoints.getWalletBalance, params);
    return result
  
  },
  getWalletHistory: async params => {
    let result = await get(walletEndpoints.getWalletHistory, params);
    return result
  
  }
};
export default WalletApiCall