import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Container,
  Grid,
  TextField,
  Modal,
  CircularProgress,
  Checkbox,
  Radio,
  Skeleton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import cartApiCall from "../../../../api/CartServices";
import { groupOrderApiCall } from "../../../../api/GroupOrderServices";
import ProductServices from "../../../../api/ProductServices";
import SimpleDialog from "../../../../components/Dialog";
import { ErrorToaster } from "../../../../components/Toaster";

import {
  calculateCheckBox,
  calculateQty,
  calculationFunc,
  handleMultipleValues,
  extractDataFromJSON,
} from "../../../../utils/helper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  removeRequiredList,
  setCartData,
  setMenuTree,
  setParentListName,
  setRequiredList,
  setSelectedProductData,
} from "../../../../redux/slices/CartSlice";
import Colors from "../../../../assets/styles";
import SubItemData from "./subItem";
import CartServices from "../../../../api/CartServices";
import ReactImageMagnify from "react-image-magnify";
import BreadCrumbProvider from "../../../../hooks/useBreadCrumb";
import { ArrowBackIosNew } from "@mui/icons-material";

const Max_Header_Height = 150;
const Min_Header_Height = 80;
const Scroll_Distance = Max_Header_Height - Min_Header_Height;
const baseUrl = process.env.REACT_APP_BASE_URL;

const CheckBoxComponent = React.memo(
  ({
    item,
    parentId,
    selectedRadioOpt,
    setSelectedRadioOpt,
    listTitle,
    minValue,
    maxValue,
    requiredList,
    totalCalcPrice,
    setTotalCalcPrice,
    dispatch,
  }) => {
    const handleSelection = (element) => {
      let isRequired = true;
      let spreadselectedRadioOpt = { ...selectedRadioOpt };
      console.log(spreadselectedRadioOpt);
      if (Object.keys(selectedRadioOpt).includes(listTitle)) {
        let listLength =
          selectedRadioOpt[listTitle]?.length === undefined
            ? 1
            : selectedRadioOpt[listTitle]?.length + 1;
        let selectedValues = [...selectedRadioOpt[listTitle]];
        var findItem = selectedValues.findIndex(
          (e) => e.ProductName === element.ProductName
        );
        if (findItem !== -1) {
          let sortElements = [...selectedValues];
          sortElements.splice(findItem, 1);
          if (sortElements.length === 0) {
            delete spreadselectedRadioOpt[listTitle];
          } else {
            spreadselectedRadioOpt[listTitle] = sortElements;
          }
          if (sortElements.length < minValue) {
            const requiredArray = [...requiredList];
            isRequired = false;
            requiredArray.push(parentId);
            dispatch(setRequiredList([...new Set(requiredArray)]));
          }
          spreadselectedRadioOpt[listTitle] = sortElements;
          setSelectedRadioOpt(spreadselectedRadioOpt);
          let calculatedData = calculateCheckBox(
            element,
            totalCalcPrice,
            findItem
          );
          setTotalCalcPrice(calculatedData);
        } else if (listLength <= minValue) {
          selectedValues.push(element);
          spreadselectedRadioOpt[listTitle] = selectedValues;
          setSelectedRadioOpt(spreadselectedRadioOpt);
          let calculatedData = calculateCheckBox(
            element,
            totalCalcPrice,
            findItem
          );
          setTotalCalcPrice(calculatedData);
        } else if (listLength <= maxValue) {
          selectedValues.push(element);
          spreadselectedRadioOpt[listTitle] = selectedValues;
          setSelectedRadioOpt(spreadselectedRadioOpt);
          let calculatedData = calculateCheckBox(
            element,
            totalCalcPrice,
            findItem
          );
          setTotalCalcPrice(calculatedData);
        } else {
          ErrorToaster(`${listTitle} can be select upto ${maxValue}`);
        }
      } else {
        let holdValues = [];
        holdValues.push(element);
        spreadselectedRadioOpt[listTitle] = holdValues;
        setSelectedRadioOpt(spreadselectedRadioOpt);
        let calculatedData = calculateCheckBox(
          element,
          totalCalcPrice,
          findItem
        );
        setTotalCalcPrice(calculatedData);
      }
      if (spreadselectedRadioOpt[listTitle]?.length >= minValue) {
        if (isRequired) {
          dispatch(removeRequiredList(parentId));
        }
      }
    };

    const isChecked =
      Object.keys(selectedRadioOpt).includes(listTitle) &&
      selectedRadioOpt[listTitle]?.some(
        (e) => e.ProductName === item.ProductName
      );

    return (
      <Box
        onClick={() => handleSelection(item)}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          p: 1,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              {item.ProductName}
            </Typography>
            {item.price > 0 && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                +${item.price}
              </Typography>
            )}
          </Box>
          <Checkbox
            checked={isChecked}
            color="primary"
            onChange={() => handleSelection(item)} // Handles checkbox click
          />
        </Box>
        {item.SubGroups.length > 0 && <ArrowForwardIosIcon sx={{ ml: 1 }} />}
      </Box>
    );
  }
);

const QtyComponent = React.memo(
  ({
    item,
    parentId,
    selectedRadioOpt,
    setSelectedRadioOpt,
    listTitle,
    minValue,
    maxValue,
    setQty,
    qty,
    requiredList,
    totalCalcPrice,
    setTotalCalcPrice,
    dispatch,
  }) => {
    const handleSelection = (element, sumVal) => {
      let isRequired = true;
      let spreadselectedRadioOpt = { ...selectedRadioOpt };
      let groupQty = { ...qty };

      if (Object.keys(selectedRadioOpt).includes(listTitle)) {
        let listLength =
          selectedRadioOpt[listTitle]?.length == undefined
            ? 1
            : selectedRadioOpt[listTitle]?.length + 1;

        let selectedValues = [...selectedRadioOpt[listTitle]];
        const findItem = selectedValues.find(
          (e) => e.ProductName == element.ProductName
        );
        const findIndex = selectedValues.findIndex(
          (e) => e.ProductName == element.ProductName
        );

        if (findIndex !== -1) {
          let result = handleMultipleValues(
            findItem,
            sumVal,
            listTitle,
            listLength,
            minValue,
            maxValue,
            spreadselectedRadioOpt,
            findIndex,
            selectedValues,
            element
          );

          if (result !== null) {
            groupQty[listTitle] = result.qtyCount;
            setQty(groupQty);
            setSelectedRadioOpt(result?.spreadselectedRadioOpt);

            if (minValue > result.qtyCount) {
              const requiredArray = [...requiredList];
              isRequired = false;

              if (sumVal == "decrement") {
                var found = requiredArray.includes("" + parentId);
                if (!found) {
                  requiredArray.push(parentId);
                }
              }
              dispatch(setRequiredList(requiredArray));
            }

            let calculatedData = calculateQty(
              result.spreadselectedRadioOpt,
              totalCalcPrice,
              listTitle,
              findIndex,
              sumVal
            );
            setTotalCalcPrice(calculatedData);
          } else {
            alert("filled");
          }
        } else {
          selectedValues.push({ ...element, qty: 1 });
          spreadselectedRadioOpt[listTitle] = selectedValues;
          setSelectedRadioOpt(spreadselectedRadioOpt);
          groupQty[listTitle] = (groupQty[listTitle] || 0) + 1;
          setQty(groupQty);
        }
      } else {
        spreadselectedRadioOpt[listTitle] = [{ ...element, qty: 1 }];
        setSelectedRadioOpt(spreadselectedRadioOpt);
        groupQty[listTitle] = 1;
        setQty(groupQty);
      }

      if (isRequired) {
        dispatch(removeRequiredList(parentId));
      }
    };

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 1,
        }}
      >
        <Box sx={{ flex: 0.7 }}>
          <Typography variant="subtitle1" fontWeight="bold">
            {item.ProductName}
          </Typography>
          {item.price > 0 && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              +${item.price}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {Object.keys(selectedRadioOpt).includes(listTitle) &&
            selectedRadioOpt[listTitle]?.map((subItem) => {
              if (subItem.ProductName == item.ProductName && subItem.qty > 0) {
                return (
                  <>
                    <IconButton
                      onClick={() => handleSelection(item, "decrement")}
                      disabled={subItem.qty == 0}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <Typography variant="body1" sx={{ mx: 1 }}>
                      {subItem.qty > 0 ? subItem.qty : 0}
                    </Typography>
                  </>
                );
              }
            })}
          <IconButton
            onClick={() => handleSelection(item, "increment")}
            disabled={qty[listTitle] >= maxValue}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
    );
  }
);

const RadioComponent = React.memo(
  ({
    navigation,
    parentId,
    item,
    selectedRadioOpt,
    setSelectedRadioOpt,
    listTitle,
    minValue,
    maxValue,
    dispatch,
    setLastSelectedRadio,
    requiredList
  }) => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const handleSelection = (element) => {
      let spreadselectedRadioOpt = { ...selectedRadioOpt };

      if (Object.keys(selectedRadioOpt).includes(listTitle)) {
        let listLength =
          selectedRadioOpt[listTitle]?.length === undefined
            ? 1
            : selectedRadioOpt[listTitle]?.length + 1;
        let selectedValues = [...selectedRadioOpt[listTitle]];
        const findItem = selectedValues.findIndex(
          (e) => e.ProductName === element.ProductName
        );
        if (findItem !== -1) {
          for (var key in spreadselectedRadioOpt) {
            if (
              (spreadselectedRadioOpt[key][0]?.parentName ||
                spreadselectedRadioOpt[key][0]?.parentName === undefined) &&
              key === spreadselectedRadioOpt[key][0]?.parentName
            ) {
              delete spreadselectedRadioOpt[listTitle];
              delete spreadselectedRadioOpt[key];
            }
          }

          setSelectedRadioOpt(spreadselectedRadioOpt);
          var obj = JSON.stringify({
            data: element.SubGroups,
            productName: element.ProductName,
          });

          if (element.SubGroups.length) {
            dispatch(setMenuTree(obj));
            dispatch(setParentListName(element.ProductName));
            // navigate('orderProductItems', {
            //   data: element.SubGroups,
            //   productName: element.ProductName,
            // });

            // setOpenModal(true);
          }
        } else if (listLength >= minValue && listLength <= maxValue) {
          selectedValues.push(element);
          spreadselectedRadioOpt[listTitle] = selectedValues;

          setSelectedRadioOpt(spreadselectedRadioOpt);
        } else {
          for (var key in spreadselectedRadioOpt) {
            if (
              spreadselectedRadioOpt[key][0]?.parentName &&
              element.ProductName !== spreadselectedRadioOpt[key][0]?.parentName
            ) {
              delete spreadselectedRadioOpt[key];
            }
          }
          let holdValues = [];
          holdValues.push(element);
          spreadselectedRadioOpt[listTitle] = holdValues;

          setSelectedRadioOpt(spreadselectedRadioOpt);

          var obj = JSON.stringify({
            data: element.SubGroups,
            productName: element.ProductName,
          });

          if (element.SubGroups.length) {
            dispatch(setMenuTree(obj));
            dispatch(setParentListName(element.ProductName));
            // navigate('orderProductItems', {
            //   data: element.SubGroups,
            //   productName: element.ProductName,
            // });
            // setOpenModal(true);
          }
        }
      } else {
        let holdValues = [];
        holdValues.push(element);
        spreadselectedRadioOpt[listTitle] = holdValues;
        setSelectedRadioOpt(spreadselectedRadioOpt);
      }

      if (
        element.SubGroups.length &&
        selectedRadioOpt[listTitle]?.length !== minValue &&
        selectedRadioOpt[listTitle]?.length !== maxValue
      ) {
        var obj = JSON.stringify({
          data: element.SubGroups,
          productName: element.ProductName,
        });

        dispatch(setMenuTree(obj));
        dispatch(setParentListName(element.ProductName));
        setLastSelectedRadio({ name: listTitle, price: 0 });
        // navigate('orderProductItems', {
        //   data: element.SubGroups,
        //   productName: element.ProductName,
        // });
        // setOpenModal(true);
      }

      if (element?.SubGroups.length > 0) {
        const requiredArray = [...requiredList];
        element?.SubGroups?.forEach((e) => {
          if (e.Required) {
            requiredArray.push(e.GroupName);
          }
        });
        dispatch(setRequiredList(requiredArray));
      }

      dispatch(removeRequiredList(parentId));
    };

    const isSelected =
      Object.keys(selectedRadioOpt).includes(listTitle) &&
      selectedRadioOpt[listTitle]?.some(
        (e) => e.ProductName === item.ProductName
      );
    const onClose = () => {
      setOpenModal(false);
    };
    return (
      <Box >
        <Box
          onClick={() => {
            // handleSelection(item);
            console.log(openModal);
          }}
          
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            p: 1,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: 1 }} onClick={() => setOpenModal(true)}>

            <Typography variant="subtitle1" fontWeight="bold">
              
              {item.ProductName}
            </Typography>
            {item.price > 0 && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                +${item.price}
              </Typography>
            )}
          </Box>

          <Box
            sx={{ display: "flex", alignItems: "center" }}
            onClick={() => setOpenModal(true)}
          >
            <Radio
              checked={isSelected}
              color="primary"
              onChange={() => handleSelection(item)} 
            />
            {item.SubGroups.length > 0 ? (
              <ArrowForwardIosIcon sx={{ ml: 1 }} />
            ) : (
              <Box sx={{ width: 20, height: 20 }} />
            )}
          </Box>

          {item.SubGroups && item.SubGroups.length > 0 && (
            <>
              <SimpleDialog
                open={openModal}
                onClose={() => setOpenModal(false)}
                maxWidth="sm"
              >
                <SubItemData
                  open={openModal}
                  onClose={() => setOpenModal(false)}
                  setOpenModal={setOpenModal}
                  data={item.SubGroups}
                  productName={item.ProductName}
                />
              </SimpleDialog>
            </>
          )}
        </Box>
      </Box>
    );
  }
);

const ListItem = (item, basedOn) => {
  const navigate = useNavigate();
  return (
    <Box
      key={item?.id}
      sx={{ p: 2, cursor: "pointer" }}
      onClick={() => navigate(`/product/detail/${item.item.ProductId}`)}
    >
      <Typography variant="h6" fontWeight="bold">
        {item?.item?.ProductName}
      </Typography>

      <Typography variant="subtitle1">${item?.item.price}</Typography>
    </Box>
  );
};

const OrderSelectionScreen = ({ navigation, route }) => {
  const params = useParams();
  const { state } = useLocation();

  const { getOrderDetail } = BreadCrumbProvider();

  useEffect(() => {
    // Scroll to top on pathname change
    window.scrollTo(0, 0);
  }, [state]);

  const shopData = state?.shopData;
  const groupOrderDetails = state?.elem?.is_groupOrderAvail
    ? state?.elem?.groupOrder
    : null;
  const groupId = state?.group_id;
  console.log(groupId);
  const productId = state?.elem?.product_id;
  const basedOn = state?.based_on;

  console.log(state);

  const isFocused = useRef(true).current;
  const cardData = useSelector((state) => state.cartData);
  const selectedData = useSelector((state) => state.cartData.selectedData);

  const requiredList = useSelector((state) => state.cartData.required);
  console.log(requiredList)
  const userData = useSelector((state) => state.userData.userData);
  const [qty, setQty] = useState({});

  const [quantity, setQuantity] = useState(1);
  const [productDetails, setProductDetails] = useState(null);
  const [loader, setLoader] = useState(true);
  const [totalCalcPrice, setTotalCalcPrice] = useState(0);
  const [lastSelectedRadio, setLastSelectedRadio] = useState({
    name: "",
    price: 0,
  });
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [updateCart, setUpdateCart] = useState(false);
  const [updateCartData, setUpdateCartData] = useState();

  const { control, register, watch, getValues, handleSubmit } = useForm();
  let sleep = () => new Promise((r) => setTimeout(r, 2000));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getProductDetailsHandler = async () => {
    setLoader(true)

    try {
      await sleep();
      let result = await ProductServices.getProductDetail(params.id);
      if (result?.ingredients.length > 0) {
        const requiredId = [];
        let spreadselectedRadioOpt = { ...selectedData };
        for (let index = 0; index < result?.ingredients.length; index++) {
          if (
            result?.ingredients[index].Minimum >= 0 &&
            result?.ingredients[index].Maximum >= 1 &&
            result?.ingredients[index].NoOfTimes > 1
          ) {
            let holdArray = [];
            for (
              let j = 0;
              j < result?.ingredients[index].Properties.length;
              j++
            ) {
              const element = {
                ...result?.ingredients[index].Properties[j],
                qty: 0,
              };
              holdArray.push(element);
            }
            spreadselectedRadioOpt[result?.ingredients[index].GroupName] =
              holdArray;
          }
          if (result?.ingredients[index].Required) {
            requiredId.push(result.ingredients[index]?.$id);
          }
        }
        dispatch(setSelectedProductData(spreadselectedRadioOpt));
        dispatch(setRequiredList(requiredId));
      }
      setProductDetails(result);
      getOnlineCartData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const ListHeader = () => {
    return (
      <>
        <Box
          sx={{
            position: { md: "sticky" },
            top: { md: "70px" },
            height: "100%",
          }}
        >
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: "Propercorn Sweet & Salty",
                isFluidWidth: true,
                src: baseUrl + "/docs/files/products/" + productDetails?.pic,
                style: { height: "600px !important" },
              },
              largeImage: {
                src: baseUrl + "/docs/files/products/" + productDetails?.pic,
                width: 1200,
                height: 1000,
              },
              enlargedImageContainerStyle: { zIndex: 9 },
              enlargedImageContainerDimensions: {
                width: "100%",
                height: "100%",
              },
              enlargedImagePosition: "over",
            }}
          />
        </Box>
      </>
    );
  };

  const ListFooter = () => {
    const handleSubmitCartData = async (formData) => {
      try {
        setLoader(true);
        if (userData?.id === undefined) {
          navigation.replace("login");
          return;
        }
        let obj = {
          ...shopData,
          ...productDetails,
          qty: quantity,
          basedOn: basedOn,
          selectedData,
          totalCalcPrice,
        };

        dispatch(setCartData(obj));

        const ingredientsArray = [];
        const extractedData = extractDataFromJSON(selectedData);

        extractedData.forEach((element) => {
          let ingredientObj = {
            ingredient_prop_id: element?.ProductId,
            modifier: element?.key,
            value: element?.qty ? element?.qty * quantity : 1 * quantity,
          };
          ingredientsArray.push(ingredientObj);
        });
        const note = getValues("note");
        const saveCart = {
          user_id: userData?.id,
          shop_id: productDetails?.shop_id,
          product_id: productDetails?.product_id,
          quantity: quantity,
          based_on: state?.basedOn,
          note: note,
          ingredients: ingredientsArray,
        };
        const groupOrderCart = {
          user_id: userData?.id,
          shop_id: productDetails?.shop_id,
          group_id: groupId,
          product_id: productDetails?.product_id,
          quantity: quantity,
          based_on: state?.based_on,
          note: note,
          ingredients: ingredientsArray,
        };

        if (groupId) {
          if (route?.params?.cartId) {
            await groupOrderApiCall.deleteItemFromGroupOrder(
              route?.params?.cartId
            );
            await groupOrderApiCall.addGroupOrderToCart(groupOrderCart);
          }
          if (!route?.params?.cartId) {
            await groupOrderApiCall.addGroupOrderToCart(groupOrderCart);
          }
        } else {
          await cartApiCall.addToCart(saveCart);
        }
        dispatch(removeRequiredList([]));
        dispatch(setSelectedProductData({}));
        dispatch(setRequiredList([]));
        navigate(-1)

        await getOrderDetail();
      } catch (error) {
        console.error(error);
      } finally {
        setLoader(false);
      }
    };
    ;
    const checkDisableLogic = () => {
      if (userData?.id !== undefined) {
        if (requiredList.length > 0) {
          return true;
        } else {
          if (groupOrderDetails != null && groupOrderDetails !== undefined) {
            if (groupOrderDetails?.spending_limit < calculateFinalTotal()) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      } else {
        if (groupOrderDetails != null && groupOrderDetails !== undefined) {
          if (groupOrderDetails?.spending_limit < calculateFinalTotal()) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    };

    return (
      <Box sx={{ p: 2 }}>
        {userData?.id ? (
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "100%" }}
            disabled={checkDisableLogic()}
            onClick={handleSubmit(handleSubmitCartData)}
          >
            {loader ? (
              <CircularProgress size={24} />
            ) : (
              "Add to Basket " + calculateFinalTotal()
            )}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "100%" }}
            disabled={checkDisableLogic()}
            onClick={() => navigate("/login")}
          >
            {loader ? (
              <CircularProgress size={24} />
            ) : (
              "Login to add to basket " + calculateFinalTotal()
            )}
          </Button>
        )}
      </Box>
    );
  };

  const calculateFinalTotal = () => {
    var total = calculateQty(selectedData);
    total = (productDetails?.price + total) * quantity;
    return parseFloat(total).toFixed(2);
  };

  const getRecommendedProducts = async () => {
    try {
      let params = {
        ProductID: productId,
      };
      let result = await ProductServices.getRecommendedProducts(params);
      if (result?.length > 0) {
        setRecommendedProducts(result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const ListSeparator = () => {
    return (
      <Box sx={{ height: 2, width: "100%", bgcolor: "lightgray", my: 2 }} />
    );
  };

  const renderListItem = (item) => {
    let groupQty = { ...qty };
    if (
      item.item?.Minimum >= 0 &&
      item.item?.Maximum > 1 &&
      item.item?.NoOfTimes > 1
    ) {
      if (Object.keys(selectedData).includes(item.item.GroupName)) {
        let tQty = 0;
        selectedData[item.item.GroupName]?.forEach((element) => {
          tQty += element?.qty ? element?.qty : 0;
        });
        groupQty[item.item.GroupName] = tQty;
      }
    }
    return (
      <Box key={item.item.name} sx={{ p: 2, bgcolor: "white" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" fontWeight="bold">
              {item.item.GroupName}
            </Typography>
            {item.item.Minimum === 1 &&
              item.item.Maximum === 1 &&
              item.item.NoOfTimes > 1 ? (
              <></>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {item.item.Minimum === 0 ? (
                  <Typography variant="body2" fontWeight="semiBold">
                    Choose up to {item.item.Maximum}{" "}
                    {item.item.Maximum <= 1 ? "item" : "items"}
                  </Typography>
                ) : (
                  <Typography variant="body2" fontWeight="semiBold">
                    Choose at least {item.item.Minimum}{" "}
                    {item.item.Minimum <= 1 ? "item" : "items"}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
          {console.log(requiredList.includes(item?.item?.$id))}
          {item.item.Required && (
            <Box
              sx={{
                bgcolor: requiredList.includes(item?.item?.$id)
                  ? "primary.main"
                  : "success.main",
                borderRadius: "20px",
                padding: "4px 16px",
                display: "flex",
                alignItems: "center",
                m: 0,
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: Colors.white }}
                fontWeight="semiBold"
              >
                Required
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          <Typography variant="body2" fontWeight="semiBold">
            {item.item.description}
          </Typography>
        </Box>

        {item?.item?.Properties.map((subItem) =>
          item.item.Minimum === 1 &&
            item.item.Maximum === 1 &&
            item.item.NoOfTimes === 1 ? (
            <RadioComponent
              parentId={item?.item?.$id}
              listTitle={item.item.GroupName}
              minValue={item.item.Minimum}
              maxValue={item.item.Maximum}
              selectedRadioOpt={selectedData}
              item={subItem}
              setSelectedRadioOpt={(e) => dispatch(setSelectedProductData(e))}
              navigation={navigation}
              dispatch={dispatch}
              requiredList={requiredList}
              setLastSelectedRadio={setLastSelectedRadio}
            />
          ) : item.item.Minimum >= 0 &&
            item.item.Maximum >= 1 &&
            item.item.NoOfTimes === 1 ? (
            <CheckBoxComponent
              parentId={item?.item?.$id}
              item={subItem}
              listTitle={item.item.GroupName}
              minValue={item.item.Minimum}
              maxValue={item.item.Maximum}
              selectedRadioOpt={selectedData}
              setSelectedRadioOpt={(e) => dispatch(setSelectedProductData(e))}
              navigation={navigation}
              dispatch={dispatch}
              requiredList={requiredList}
              totalCalcPrice={totalCalcPrice}
              setTotalCalcPrice={setTotalCalcPrice}
            />
          ) : (
            <QtyComponent
              parentId={item?.item?.$id}
              listTitle={item.item.GroupName}
              minValue={item.item.Minimum}
              maxValue={item.item.Maximum}
              selectedRadioOpt={selectedData}
              item={subItem}
              setSelectedRadioOpt={(e) => dispatch(setSelectedProductData(e))}
              navigation={navigation}
              setQty={setQty}
              qty={groupQty}
              requiredList={requiredList}
              dispatch={dispatch}
              totalCalcPrice={totalCalcPrice}
              setTotalCalcPrice={setTotalCalcPrice}
            />
          )
        )}
      </Box>
    );
  };

  const goToBack = () => {
    dispatch(setSelectedProductData({}));
    dispatch(setRequiredList([]));
    navigation.goBack();
  };

  useEffect(() => {
    if (isFocused) {
      let result = calculationFunc(
        selectedData,
        totalCalcPrice,
        lastSelectedRadio
      );
      setLastSelectedRadio({
        name: lastSelectedRadio.name,
        price: result,
      });
      setTotalCalcPrice(result);
    }
  }, [isFocused, selectedData]);

  useEffect(() => {
    if (isFocused) {
      if (productDetails?.ingredients.length > 0) {
        const requiredId = [];
        const key = Object.keys(selectedData);
        for (
          let index = 0;
          index < productDetails?.ingredients.length;
          index++
        ) {
          const selectedIndex = key.indexOf(
            productDetails?.ingredients[index]?.GroupName
          );
          if (productDetails?.ingredients[index].Required) {
            if (selectedIndex !== -1) {
              let selectedQty = 0;
              if (selectedData[key[selectedIndex]][0]?.qty >= 0) {
                selectedData[key[selectedIndex]]?.forEach((j) => {
                  if (j?.qty) {
                    selectedQty += j?.qty;
                  }
                });
                if (productDetails?.ingredients[index]?.Minimum > selectedQty) {
                  requiredId.push(productDetails.ingredients[index]?.$id);
                }
              }
            } else {
              requiredId.push(productDetails.ingredients[index]?.$id);
            }
          }
        }
        dispatch(setRequiredList(requiredId));
      }
    }
  }, [isFocused]);

  useEffect(() => {
    dispatch(setSelectedProductData({}));
    dispatch(setRequiredList([]));
    getProductDetailsHandler();
  }, [params.id]);

  useEffect(() => {
    getRecommendedProducts();
  }, []);

  useEffect(() => {
    // const unsubscribe = navigation.addListener('focus', () => {});
    return () => {
      dispatch(setSelectedProductData({}));
      dispatch(setRequiredList([]));
      // unsubscribe();
    };
  }, []);

  const getOnlineCartData = async (shopDetail) => {
    try {
      if (groupId) {
        const res = await groupOrderApiCall.getGroupOrder({
          user_id: userData?.id,
          group_id: groupId,
        });

        if (shopDetail?.shop_id === res?.shop_id) {
          const memberIndex = res?.members.findIndex(
            (e) => e.user_id === userData?.id
          );

          for (let i = 0; i < res?.members[memberIndex]?.items.length; i++) {
            if (res?.members[memberIndex]?.items[i]?.product_id === productId) {
              setUpdateCartData(res?.members?.[memberIndex]?.items[i]);
              setQuantity(res?.members?.[memberIndex]?.items[i]?.quantity);
              setUpdateCart(true);
              break;
            } else {
              setUpdateCart(false);
            }
          }
        }
        return res;
      } else {
        const result = await CartServices.getCartDetails(userData?.id);
        for (let index = 0; index < result?.length; index++) {
          if (shopDetail?.shop_id === result?.[index].shop_id) {
            const j = result?.[index]?.items.findIndex(
              (e) => e.product_id === productId
            );
            if (j !== -1) {
              setUpdateCartData(result?.[index]?.items[j]);
              setQuantity(result?.[index]?.items[j]?.quantity);
              setUpdateCart(true);
            }
            break;
          }
        }
        return result;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container sx={{ maxWidth: "1300px !important" }}>
        <Box sx={{ background: Colors.grey, borderRadius: "50%", mb: 2, mt: {lg:10,md:10,sm:10,xs:12  }, width: "40px" }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosNew />
          </IconButton>
        </Box>
        <Grid container spacing={4} sx={{ marginTop: 2 }}>
          {productDetails?.pic && (
            
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              position: "relative",
              height: "100%",
              position: { md: "sticky" },
              top: { md: "70px" },
            }}
          >
            <Box sx={{ mb: 2 }}>
              {loader ? (
                <Skeleton variant="rectangular" width="100%" height={650} />
              ) : (
                <ListHeader />
              )}
            </Box>
          </Grid>
          )}
          <Grid item xs={12} md={productDetails?.pic ? 6:12}>
            <Box sx={{ p: 2, bgcolor: "white", mt: 1 }}>
              {loader ? (
                <>
                  <Skeleton variant="text" width="60%" height={40} />
                  <Skeleton variant="text" width="40%" height={35} />
                  <Skeleton variant="text" width="100%" height={80} />
                </>
              ) : (
                <>
                  <Typography variant="h4" fontWeight="bold">
                    {productDetails?.name}
                  </Typography>
                  <Typography variant="h5" fontWeight="bold">
                    $
                    {productDetails?.price === 0
                      ? ""
                      : parseFloat(productDetails?.price)?.toFixed(2)}
                  </Typography>
                  <Typography variant="body1" fontWeight="medium">
                    {productDetails?.description}
                  </Typography>
                </>
              )}
            </Box>
            <Box>
              {loader ? (
                Array.from({ length: 3 }).map((_, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Skeleton variant="rectangular" width="100%" height={30} />
                    {index < 2 && (
                      <Skeleton variant="rectangular" width="100%" height={10} sx={{ mt: 1 }} />
                    )}
                  </Box>
                ))
              ) : (
                productDetails?.ingredients.map((item, index) => (
                  <Box key={index}>
                    {renderListItem({ item, index })}
                    {index < productDetails?.ingredients.length - 1 && (
                      <ListSeparator />
                    )}
                  </Box>
                ))
              )}
            </Box>
            <Box sx={{ my: 2 }}>
              {loader ? (
                <Skeleton variant="rectangular" width="100%" height={100} />
              ) : (
                <>
                  <Typography variant="h6" fontWeight="semiBold">
                    Add Custom Notes
                  </Typography>
                  <TextField
                    control={control}
                    name="note"
                    {...register("note")}
                    multiline
                    minRows={4}
                    maxRows={6}
                    variant="outlined"
                    fullWidth
                    placeholder="Write your custom note for this item here."
                  />
                </>
              )}
            </Box>
            <Box>
              {loader ? (
                <Skeleton variant="rectangular" width="100%" height={60} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: '10px'
                  }}
                >
                  <IconButton
                    sx={{ background: Colors.grey }}
                    onClick={() => setQuantity(Math.max(1, quantity - 1))}
                    disabled={quantity <= 1}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <Typography variant="h6">{quantity}</Typography>
                  <IconButton sx={{ background: Colors.grey }} onClick={() => setQuantity(quantity + 1)}>
                    <AddIcon />
                  </IconButton>
                </Box>
              )}
              {loader ? (
                <Skeleton variant="rectangular" width="100%" height={50} />
              ) : (
                <ListFooter />
              )}
            </Box>
          </Grid>
        </Grid>

        {recommendedProducts?.length > 0 && (
          <Box sx={{ mt: 4 }}>
            {loader ? (
              <>
                <Skeleton variant="text" width="60%" height={40} />
                <Skeleton variant="rectangular" width="100%" height={150} />
              </>
            ) : (
              <>
                <Typography variant="h6" fontWeight="bold" mb={2}>
                  Frequently bought together
                </Typography>
                {recommendedProducts.map((item, index) => (
                  <ListItem
                    key={index}
                    item={item}
                    navigation={navigation}
                    basedOn={basedOn}
                  />
                ))}
              </>
            )}
          </Box>
        )}

      </Container>
    </Box>
  );
};

export default OrderSelectionScreen;
