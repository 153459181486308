import {promotionsEndpoints} from './route';
import {get, post, patch, deleted} from '../tokenindex';

export const PromotionServices = {
  validatePromoCode: async params => {
    let result = await get(promotionsEndpoints.validatePromoCode, params);
   return result;
  },
  getOffers: async params => {
    let result = await get(promotionsEndpoints.getOffers, params);
   return result;
  },
};
