import React, { useState, useEffect } from 'react';
import CartServices from '../api/CartServices';
import { useSelector } from 'react-redux';


function useProvideSetting() {
  const [cartData, setCartData] = useState(null);
  const [badge, setBadge] = useState(null);
  const [quantities, setQuantities] = useState({});
  const userData = useSelector((state) => state.userData.userData);
  const userId = userData.id;

  const getOrderDetail = async () => {
    try {
      const data = await CartServices.getCartDetails(userId);
      setCartData(data);
      setBadge(data.length);
     
      // Initialize quantities for each item
      const initialQuantities = {};
      data.forEach((shop, shopIndex) => {
        shop.items.forEach((item, itemIndex) => {
          initialQuantities[`${shopIndex}-${itemIndex}`] = item.quantity;
        });
      });
      setQuantities(initialQuantities);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderDetail();
  }, []);

  return {
    cartData,
    quantities,
    setCartData,
    setQuantities,
    badge,
    setBadge,
    getOrderDetail
  }
}

export default useProvideSetting;