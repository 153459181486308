import React from "react";
import {
  Grid,
  Link,
  Typography,
  Box,
  IconButton,
  Container,
} from "@mui/material";
import PsLogo from "../../assets/images/playstore_button.png";
import AsLogo from "../../assets/images/appstore_button.png";
import logo from "../../assets/images/footerLogo.png";
import "./FooterV2.css";
import Colors from "../../assets/styles";
import { useNavigate } from "react-router-dom";

const FooterV2 = () => {
  const navigate = useNavigate();
  return (
    <Box component="footer" className="footerV2">
      <Container sx={{ maxWidth: "1300px !important" }}>
        <Grid
          container
          className="footer-top"
          spacing={4}
          sx={{ width: "100%" }}
          justifyContent={"center"}
        >
          <Grid item xs={12} sm={12} md={3} className="footer-logo">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                ml: "30px",
              }}
            >
              <img src={logo} alt="logo" className="FooterLogo" />
              <Typography
                variant="body2"
                sx={{
                  color: Colors.white,
                  textAlign: { md: "left", xs: "center" },
                }}
              >
                This site is protected by reCAPTCHA and the Google Privacy
                Policy and Terms of Service apply.
              </Typography>
              <Box
                className="social-icons"
                sx={{
                  justifyContent: {
                    md: "flex-start",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >
                <IconButton
                  href="/enquirydelivery"
                  color="inherit"
                  sx={{
                    width: "48px",
                    height: "48px",
                    border: `2px solid #ffffff`,
                    ":hover": {
                      background: "#db4a39",
                      border: "2px solid #db4a39",
                    },
                  }}
                >
                  {/* <Twitter /> */}
                  <svg
                    viewBox="0 0 1024 1024"
                    fill="#ffffff"
                    height="16px"
                    width="16px"
                  >
                    <path d="M881 442.4H519.7v148.5h206.4c-8.9 48-35.9 88.6-76.6 115.8-34.4 23-78.3 36.6-129.9 36.6-99.9 0-184.4-67.5-214.6-158.2-7.6-23-12-47.6-12-72.9s4.4-49.9 12-72.9c30.3-90.6 114.8-158.1 214.7-158.1 56.3 0 106.8 19.4 146.6 57.4l110-110.1c-66.5-62-153.2-100-256.6-100-149.9 0-279.6 86-342.7 211.4-26 51.8-40.8 110.4-40.8 172.4S151 632.8 177 684.6C240.1 810 369.8 896 519.7 896c103.6 0 190.4-34.4 253.8-93 72.5-66.8 114.4-165.2 114.4-282.1 0-27.2-2.4-53.3-6.9-78.5z" />
                  </svg>
                </IconButton>
                <IconButton
                  href="https://www.facebook.com/letseat.ky"
                  target="_blank"
                  color="inherit"
                  sx={{
                    width: "48px",
                    height: "48px",
                    border: `2px solid #ffffff`,
                    ":hover": {
                      background: "#4267b2",
                      border: "2px solid #4267b2",
                    },
                  }}
                >
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    height="16px"
                    width="16px"
                  >
                    <path
                      fill="#ffffff"
                      d="M9.198 21.5h4v-8.01h3.604l.396-3.98h-4V7.5a1 1 0 011-1h3v-4h-3a5 5 0 00-5 5v2.01h-2l-.396 3.98h2.396v8.01z"
                    />
                  </svg>
                  {/* <Facebook /> */}
                </IconButton>
                <IconButton
                  href="https://instagram.com/letseat.ky?igshid=18wroa0v69vkw"
                  target="_blank"
                  color="inherit"
                  sx={{
                    width: "48px",
                    height: "48px",
                    border: `2px solid #ffffff`,
                    ":hover": {
                      background: "#c13584",
                      border: "2px solid #c13584",
                    },
                  }}
                >
                  {/* <Instagram /> */}
                  <svg
                    viewBox="0 0 1024 1024"
                    fill="#ffffff"
                    height="16px"
                    width="16px"
                  >
                    <path d="M512 306.9c-113.5 0-205.1 91.6-205.1 205.1S398.5 717.1 512 717.1 717.1 625.5 717.1 512 625.5 306.9 512 306.9zm0 338.4c-73.4 0-133.3-59.9-133.3-133.3S438.6 378.7 512 378.7 645.3 438.6 645.3 512 585.4 645.3 512 645.3zm213.5-394.6c-26.5 0-47.9 21.4-47.9 47.9s21.4 47.9 47.9 47.9 47.9-21.3 47.9-47.9a47.84 47.84 0 00-47.9-47.9zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zm-88 235.8c-7.3 18.2-16.1 31.8-30.2 45.8-14.1 14.1-27.6 22.9-45.8 30.2C695.2 844.7 570.3 840 512 840c-58.3 0-183.3 4.7-235.9-16.1-18.2-7.3-31.8-16.1-45.8-30.2-14.1-14.1-22.9-27.6-30.2-45.8C179.3 695.2 184 570.3 184 512c0-58.3-4.7-183.3 16.1-235.9 7.3-18.2 16.1-31.8 30.2-45.8s27.6-22.9 45.8-30.2C328.7 179.3 453.7 184 512 184s183.3-4.7 235.9 16.1c18.2 7.3 31.8 16.1 45.8 30.2 14.1 14.1 22.9 27.6 30.2 45.8C844.7 328.7 840 453.7 840 512c0 58.3 4.7 183.2-16.2 235.8z" />
                  </svg>
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid
              container
              justifyContent={"center"}
              sx={{
                ml: 1,
                flexWrap: "nowrap ",
                gap: { md: "40px", sm: "40px", xs: "20px" },
                textAlign: "center",
              }}
              className="footer-column"
            >
              <Grid item md={3.5}>
                <Grid container rowGap="2px" sx={{ flexDirection: "column" }}>
                  <Grid item md={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: Colors.white,
                        fontWeight: 600,
                        fontSize: { md: "15px", xs: "13px" },
                      }}
                    >
                      Company
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Typography
                      component={"a"}
                      sx={{
                        color: "#858b90",
                        fontFamily: "Noto Sans",
                        fontSize: { md: "15px", sm: "13px", xs: "12px" },
                        ":hover": {
                          color: Colors.red,
                        },
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/send-gift")}
                    >
                      Buy gift cards
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Typography
                      component={"a"}
                      sx={{
                        color: "#858b90",
                        fontFamily: "Noto Sans",
                        fontSize: { md: "15px", sm: "13px", xs: "12px" },

                        ":hover": {
                          color: Colors.red,
                        },
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/consumerterms")}
                    >
                      About Us
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4}>
                <Grid container rowGap={"2px"} sx={{ flexDirection: "column" }}>
                  <Grid item md={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: Colors.white,
                        fontWeight: 600,
                        fontSize: { md: "15px", xs: "13px" },
                      }}
                    >
                      Contact Us
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Typography
                      component={"a"}
                      underline="none"
                      sx={{
                        color: "#858b90",
                        fontFamily: "Noto Sans",
                        fontSize: { md: "15px", sm: "13px", xs: "12px" },

                        ":hover": {
                          color: Colors.red,
                        },
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/privacy-policy")}
                    >
                      Privacy Policy
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Typography
                      component={"a"}
                      underline="none"
                      sx={{
                        color: "#858b90",
                        fontFamily: "Noto Sans",
                        fontSize: { md: "15px", sm: "13px", xs: "12px" },

                        ":hover": {
                          color: Colors.red,
                        },
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/consumerterms")}
                    >
                      Terms & Conditions
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Typography
                      component={"a"}
                      underline="none"
                      sx={{
                        color: "#858b90",
                        fontFamily: "Noto Sans",
                        fontSize: { md: "15px", sm: "13px", xs: "12px" },

                        ":hover": {
                          color: Colors.red,
                        },
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/consumerterms")}
                    >
                      Contact Us
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3}>
                <Grid container rowGap={"2px"} sx={{ flexDirection: "column" }}>
                  <Grid item md={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: Colors.white,
                        fontWeight: 600,
                        fontSize: { md: "15px", xs: "13px" },
                      }}
                    >
                      Join Us
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Typography
                      component={"a"}
                      sx={{
                        color: "#858b90",
                        fontFamily: "Noto Sans",
                        fontSize: { md: "15px", sm: "13px", xs: "12px" },

                        ":hover": {
                          color: Colors.red,
                        },
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/login")}
                    >
                      Log in
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Typography
                      component={"a"}
                      sx={{
                        color: "#858b90",
                        fontFamily: "Noto Sans",
                        fontSize: { md: "15px", sm: "13px", xs: "12px" },

                        ":hover": {
                          color: Colors.red,
                        },
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/login")}
                    >
                      Register
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={2.5} className="footer-apps">
            <Box
              sx={{
                display: "flex",
                flexDirection: { md: "column", sm: "row", xs: "row" },
                justifyContent: { md: "center", sm: "center", xs: "center" },
                gap: 2,
                img: {
                  width: { md: "190px", sm: "190px", xs: "130px" },
                },
              }}
            >
              <a
                target="_blank"
                href="https://apps.apple.com/pk/app/lets-eat-cayman/id1503188422"
              >
                <img
                  width="190px"
                  src={AsLogo}
                  alt="Download on the App Store"
                />
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.letseat.app&hl=en&gl=US"
              >
                <img width="190px" src={PsLogo} alt="Get it on Google Play" />
              </a>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          className="footer-bottom"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              variant="body2"
              sx={{ color: "#858b90" }}
              align="center"
            >
              &copy; 2024 Let's Eat Ltd.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FooterV2;
