import { favoriteEndpoints } from './route.js';
import { get, post, patch, deleted } from '../tokenindex.js';

export const favoriteApiCall = {
  getFavorites: async (params) => {
    let result = await get(favoriteEndpoints.getFavorites, params);
    return result
  },
  addToFavorite: async (userId, shopId, basedOn, status) => {
    let result = await post(favoriteEndpoints.addToFavorite + `?user_id=${userId}&shop_id=${shopId}&based_on=${basedOn}&status=${status}`);
    return result
    
  },
};
