import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  Grid,
  TextField,
} from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useDispatch, useSelector } from "react-redux";
import { ErrorToaster, SuccessToaster } from "../../../../components/Toaster";
import { PromotionServices } from "../../../../api/PromotionServices";
import { setAppliedPromoCode } from "../../../../redux/slices/UserDataSlices";
import "./Promotion.css";
import { Images, Svgs } from "../../../../assets/images/index";
import moment from "moment";

const Promotions = () => {
  const [promocode, setPromoCode] = useState("");
  const [promocodeInfo, setPromoCodeInfo] = useState([]);
  const [availablePromotions, setAvailablePromotions] = useState([]);

  const userData = useSelector((state) => state.userData);
  const appliedPromoCode = useSelector(
    (state) => state.userData.appliedPromoCode
  );
  const dispatch = useDispatch();
  console.log(userData);
  const validatePromoCode = async () => {
    if (!promocode) {
      ErrorToaster("Enter promo code");
      return;
    }
    try {
      const result = await PromotionServices.validatePromoCode({
        user_id: userData?.userData?.id,
        promo_code: promocode,
      });
      console.log(result);

      setPromoCodeInfo(result);
      dispatch(setAppliedPromoCode(result));

      if (result?.length > 0) {
        SuccessToaster("Promotion Applied");
        console.log(result);
      } else {
        ErrorToaster("Promo code is invalid");
      }
    } catch (error) {
      ErrorToaster(error.message || "An error occurred");
    }
  };

  const getPromotions = async () => {
    try {
      let result = await PromotionServices.getOffers({
        user_id: userData?.userData?.id,
      });
      console.log("promotions result", result);
      setAvailablePromotions(result);

      console.log(result);
      // setPromotions(result);
      // call api here for promotions
    } catch (error) {
      ErrorToaster(error);
      console.log("file: index.js:37 => getOffers => error:", error);
    }
  };

  const renderFreeDeliveryPromotions = (item, index) => (
    <Box
      key={index}
      sx={{
        border: 1,
        borderColor:
          item?.$id === appliedPromoCode?.[0]?.$id
            ? "primary.main"
            : "grey.500",
        borderRadius: 2,
        p: 2,
        mb: 2,
      }}
    >
      <Typography
        children={item?.offerType}
        fontWeight="bold"
        sx={{ fontSize: 20 }}
      />
      <Typography
        children={
          item?.minOrderAmount
            ? `$${item.minOrderAmount.toFixed(
                2
              )} Minimum Order • Delivery orders only`
            : "• Delivery orders only"
        }
        fontWeight="semiBold"
        sx={{ color: "black", mt: 1 }}
      />
      {item?.maxOrderAmount && (
        <Typography
          children={`$${item.maxOrderAmount.toFixed(
            2
          )} Maximum Order • Delivery orders only`}
          fontWeight="semiBold"
          sx={{ color: "black", mt: 1 }}
        />
      )}
      <Typography
        children={`Avail before ${moment(item?.toDate).format(
          "ddd MMM yyyy "
        )}`}
        fontWeight="medium"
        sx={{ mt: 1 }}
      />
      <Box sx={{ display: "flex", mt: 2 }}>
        <Button
          variant="contained"
          color={
            item?.$id === appliedPromoCode?.[0]?.$id ? "secondary" : "primary"
          }
          onClick={() => {
            if (item?.$id !== appliedPromoCode?.[0]?.$id) {
              dispatch(setAppliedPromoCode([item]));
            } else {
              dispatch(setAppliedPromoCode([]));
            }
          }}
          sx={{ mr: 2 }}
        >
          {item?.$id === appliedPromoCode?.[0]?.$id ? "Selected" : "Select"}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            /* Navigate to promotion details */
          }}
        >
          Detail
        </Button>
      </Box>
    </Box>
  );

  const renderDiscountPromotions = (item, index) => (
    <Box
      key={index}
      sx={{ border: 1, borderColor: "grey.500", borderRadius: 2, p: 2, mb: 2 }}
    >
      <Typography
        children={
          item?.percentageType === "Amount"
            ? `$${item?.discount} Off`
            : `%${item?.discount} Off`
        }
        fontWeight="bold"
        sx={{ fontSize: 20 }}
      />
      <Typography
        children={`$${item?.minOrderAmount?.toFixed(2)} Minimum Order`}
        fontWeight="semiBold"
        sx={{ color: "black", mt: 1 }}
      />
      {item?.maxOrderAmount && (
        <Typography
          children={`$${item.maxOrderAmount.toFixed(
            2
          )} Maximum Order • Delivery orders only`}
          fontWeight="semiBold"
          sx={{ color: "black", mt: 1 }}
        />
      )}
      <Typography
        children={item?.description}
        fontWeight="medium"
        sx={{ color: "black", mt: 1 }}
      />
      <Typography
        children={`Avail before ${moment(item?.toDate).format(
          "ddd MMM yyyy "
        )}`}
        fontWeight="medium"
        sx={{ mt: 1 }}
      />
      <Box sx={{ display: "flex", mt: 2 }}>
        <Button
          variant="contained"
          color={
            item?.$id === appliedPromoCode?.[0]?.$id ? "secondary" : "primary"
          }
          onClick={() => {
            if (item?.$id !== appliedPromoCode?.[0]?.$id) {
              dispatch(setAppliedPromoCode([item]));
            } else {
              dispatch(setAppliedPromoCode([]));
            }
          }}
          sx={{ mr: 2 }}
        >
          {item?.$id === appliedPromoCode?.[0]?.$id ? "Selected" : "Select"}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            /* Navigate to promotion details */
          }}
        >
          Detail
        </Button>
      </Box>
    </Box>
  );

  const renderAppliedPromotion = () => (
    <Box sx={{ mb: 4 }}>
      <Typography
        children="Applied promotion"
        fontWeight="bold"
        sx={{ fontSize: 18 }}
      />
      {appliedPromoCode?.map((promo, index) => (
        <Box
          key={index}
          sx={{
            border: 1,
            borderColor: "transparent",
            borderRadius: 2,
            backgroundColor: "#F2F9F9",
            p: 2,
            mb: 1,
          }}
        >
          <Typography
            children={
              promo.percentageType === "Amount" ||
              promo.promocode_type === "amount"
                ? `$${promo.discount.toFixed(2)} Off`
                : `%${promo.discount} Off`
            }
            fontWeight="bold"
            sx={{ fontSize: 20, mt: 1 }}
          />
          {promo.minOrderAmount && (
            <Typography
              children={`$${promo.minOrderAmount.toFixed(2)} Minimum Order`}
              fontWeight="semiBold"
              sx={{ color: "black", mt: 1 }}
            />
          )}
          {promo.coupon_user_limit && (
            <Typography
              children={`Coupon limit is ${promo.coupon_user_limit}`}
              fontWeight="regular"
              sx={{ color: "black", mt: 1 }}
            />
          )}
          <Typography
            children={promo.description}
            fontWeight="medium"
            sx={{ color: "black", mt: 1 }}
          />
          <Typography
            children={`Avail before ${moment(promo.toDate).format(
              "DD MMM yyyy"
            )}`}
            fontWeight="medium"
            sx={{ mt: 1 }}
          />
        </Box>
      ))}
    </Box>
  );

  useEffect(() => {
    getPromotions();
  }, []);
  return (
    <>
      <Box display="flex" alignItems="center" mb={2} mr={1}>
        <TextField
          placeholder="Enter promo code"
          variant="outlined"
          fullWidth
          value={promocode}
          onChange={(e) => {
            setPromoCode(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocalOfferIcon />
              </InputAdornment>
            ),
            className: "promo-input",
          }}
          className="promo-textfield"
        />
        <Button
          onClick={validatePromoCode}
          variant="contained"
          className="apply-button"
        >
          Apply
        </Button>
      </Box>

      {appliedPromoCode?.length < 0 ? (
        <Box textAlign="center" mb={8}>
          <Box
            className="promoImg"
            dangerouslySetInnerHTML={{ __html: Svgs["promotions"] }}
          ></Box>
          <Typography variant="body2" className="promoText">
            You currently don’t have any promotions
          </Typography>
        </Box>
      ) : (
        <Box sx={{ mt: 2 }}>
          {appliedPromoCode?.length > 0 && renderAppliedPromotion()}

          <Grid container spacing={2}>
            {availablePromotions?.length > 0 &&(
              availablePromotions.map((item, index) =>
                item?.offerType === "FreeDelivery"
                  ? renderFreeDeliveryPromotions(item, index)
                  : renderDiscountPromotions(item, index)
              )
            ) 
          }

           {appliedPromoCode.length == 0 &&  (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "20vh",
                  }}
                >
                  <Typography sx={{ fontSize: 18 }}>
                    You do not have any promotions yet
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      {/* <Box textAlign="left" mt={2} className="promo-banner">
        <Box className="promo-text">
          <Typography variant="body2" className="promo-title">
            Try Let's Eat free for 4 weeks
          </Typography>
          <Button variant="contained" className="join-now-button">
            Join now
          </Button>
        </Box>
        <Box
          component="img"
          src={Images.logo}
          alt="Uber promo"
          className="promo-image"
        />
      </Box>
      <Box textAlign="center" p={2}>
        <Button variant="contained" fullWidth className="done-button">
          Done
        </Button>
      </Box> */}
    </>
  );
};

export default Promotions;
