import { FormEndPoints } from './route.js';
import { get, post, patch, deleted } from '../tokenindex.js';

export const FormServices = {
 
  submitForm: async (obj) => {
    let result = await post(FormEndPoints.submitForm, obj);
     return result
  },
};
