//////////////////////pharmacy./////////////
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  // ToggleButton,
  // ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Colors from "../../../../assets/styles";
import { Images } from "../../../../assets/images";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import InfoIcon from "@mui/icons-material/Info";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import SliderComponent from "../../../../components/Slider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ContextApi } from "../../../../store/context";
import CategoriesServices from "../../../../api/CategoriesServices";
import moment from "moment";
import SearchServices from "../../../../api/SearchServices";
import { debounce } from "lodash";

import ClearIcon from "@mui/icons-material/Clear"; // Import the Clear icon
import { useDispatch, useSelector } from "react-redux";
import { convertTime, dayNTimeHandler } from "../../../../utils/helper";
import { groupOrderApiCall } from "../../../../api/GroupOrderServices";
import { clearMenuTree } from "../../../../redux/slices/CartSlice";
import CartServices from "../../../../api/CartServices";
import StoreDataCard from "../../../../components/ShopCard";
import { ArrowBackIosNew } from "@mui/icons-material";

function DropDown({ anchorEl, openDropdown, handleClose, setActiveFavourite }) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={openDropdown}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          width: 300,
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          borderRadius: "10px",
          ".MuiSvgIcon-root": {
            width: 50,
            height: 30,
            ml: 0.5,
            mr: 0.5,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 50,
            height: 10,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem sx={{ fontWeight: "600" }}>
        <ListItemIcon>
          <PersonAddIcon sx={{ fontSize: "20px", fill: Colors.black }} />
        </ListItemIcon>
        Start Group Order
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />

      <MenuItem sx={{ fontWeight: "600" }}>
        <ListItemIcon>
          <InfoIcon sx={{ fontSize: "20px", fill: Colors.black }} />
        </ListItemIcon>
        Stores Info
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem sx={{ fontWeight: "600" }}>
        <ListItemIcon>
          <FavoriteBorderIcon
            onClick={() => setActiveFavourite(true)}
            sx={{ fontSize: "20px", fill: Colors.black }}
          />
        </ListItemIcon>
        Add to Favorite
      </MenuItem>
    </Menu>
  );
}

const ToggleSlider = styled(Box)(({ transformX }) => ({
  transition: "transform 300ms ease-in-out",
  transform: `translateX(${transformX}%)`,
  position: "absolute",
  width: "50%", // Make sure the slider covers half the container
  height: "100%",
  backgroundColor: "#ffffff", // Slider color
  borderRadius: "20px", // Rounded corners for slider
}));

const ToggleButtonContainer = styled(Box)({
  display: "flex",
  position: "relative",
  width: "180px", // Set a fixed width for the container
  height: "40px",
  borderRadius: "20px",
  backgroundColor: "#f0f0f0", // Background color for non-active area
  overflow: "hidden",
  border: "2px solid #aeaeae47", // Border color adjusted for visibility
});

const ToggleOption = styled(Button)(({ active }) => ({
  flex: 1,
  height: "100%",
  borderRadius: "20px",
  backgroundColor: "transparent", // Remove background color here
  color: "black", // Black text for all states
  "&:hover": {
    backgroundColor: "rgba(255,255,255,0.3)", // Light hover effect
  },
}));

const ToggleButton = ({
  selectedType,
  setSelectedType,
  isTakeaway,
  deliveryDisabled,
  pickupDisabled,
}) => {
  const [transformX, setTransformX] = useState(0);

  useEffect(() => {
    if (pickupDisabled) {
      setTransformX(0);
    } else if (deliveryDisabled) {
      setTransformX(50); // 50% of the container width to right side
    } else {
      setTransformX(selectedType === "Pickup" ? 100 : 0); // Correctly aligns with the options
    }
  }, [pickupDisabled, deliveryDisabled, selectedType]);

  return (
    <>
      <Box>
        <Box>
          <ToggleButtonContainer>
            <ToggleSlider transformX={transformX} />
            <ToggleOption
              disabled={deliveryDisabled}
              active={selectedType === "Delivery"}
              onClick={() => setSelectedType("Delivery")}
            >
              Delivery
            </ToggleOption>
            <ToggleOption
              active={selectedType === "Pickup"}
              onClick={() => setSelectedType("Pickup")}
            >
              Pickup
            </ToggleOption>
          </ToggleButtonContainer>
        </Box>
      </Box>
    </>
  );
};

export default function ShopDetail() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [currentCategoryInView, setCurrentCategoryInView] = useState(null);
  const [selectedType, setSelectedType] = useState("Delivery");

  const [activeFavourite, setActiveFavourite] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deliveryMethod, setDeliveryMethod] = useState("delivery");
  const [value, setValue] = useState("delivery");
  const [activeCategory, setActiveCategory] = useState(null);

  const paramsId = useParams();
  const sectionsRefs = useRef({});
  const [searchText, setSearchText] = useState("");
  const [activeSection, setActiveSection] = useState("");
  const [detailData, setDetailData] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1000);
  const [searchResultData, setSearchResultData] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const [isGroupOrder, setIsGroupOrder] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [shopItemsInCart, setShopItemsInCart] = useState([]);
  const [totalCartItems, setTotalCartItems] = useState(0);

  const { state } = useLocation();
  console.log("state", state);
  let sleep = () => new Promise((r) => setTimeout(r, 1000));

  console.log(groupId);
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // Scroll to top on pathname change
    window.scrollTo(0, 0);
  }, [location]);

  const shopId = state?.shopId;
  const typeId = state?.typeId;

  const openDropdown = Boolean(anchorEl);

  const CustomTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      width: "356px",
      background: Colors.grey,
      height: "35px",

      borderRadius: "25px", // Rounded corners
      paddingRight: "8px", // Right padding inside the input
      "& fieldset": {
        borderColor: "#E0E0E0", // Border color
      },
      "&:hover fieldset": {
        borderColor: "#BDBDBD", // Border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#BDBDBD", // Border color when focused
      },
    },
  });
  const CustomTabs = styled(Tabs)({
    minHeight: "25px",
    borderRadius: "18px",
    backgroundColor: "#E0E0E0",
    padding: "2px 7px",
    "& .MuiTabs-indicator": {
      display: "none",
    },
  });

  const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      minWidth: 72,
      minHeight: "36px",

      fontWeight: theme.typography.fontWeightRegular,
      color: "rgba(0, 0, 0, 0.85)",
      borderRadius: "18px",
      padding: "6px 16px",
      "&.Mui-selected": {
        color: "#000",
        backgroundColor: "#fff",
        padding: "2px 7px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      },
    })
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleScroll = () => {
    const firstGridItem = document.querySelector("#scroll_grid .MuiGrid-item");
    const itemHeight = firstGridItem
      ? firstGridItem.getBoundingClientRect().height
      : 200;
    const offset = window.innerHeight * 0.2;
    let foundCategory = currentCategoryInView;
    Object.entries(sectionsRefs.current).forEach(([category, ref]) => {
      if (ref) {
        const top = ref.getBoundingClientRect().top;

        // Adjust the condition to trigger when the item is partially visible
        if (top < window.innerHeight && top > offset) {
          foundCategory = category;
        }
      }
    });
    if (foundCategory !== currentCategoryInView) {
      setCurrentCategoryInView(foundCategory);
      console.log(foundCategory);
    }
  };
  const handleScrollOnClick = (item) => {
    const sectionId = `section-${item.category_id}`;
    const sectionElement = document.getElementById(sectionId);

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [currentCategoryInView]);

  const day = moment().format("ddd");
  const time = moment().format("HH:mm");
  const shop_id = paramsId?.id;
  const basedOn = "store";
  const TypeID = 1;
  const userId = userData ? userData?.id : "";
  // const getShopDetailData = async () => {
  //   try {
  //     const data = await CategoriesServices.getCategoriesShopDetail(
  //       shop_id,
  //       day,
  //       time,
  //       basedOn,
  //       TypeID,
  //       userId
  //     );
  //     setDetailData(data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   getShopDetailData();
  // }, [shop_id]);
  const debouncedSearch = useCallback(
    debounce(async (text) => {
      setLoader1(true);
      try {
        await sleep();
        const result = await SearchServices.getSearchData(
          text,
          "All",
          page,
          limit,
          shop_id,
          basedOn
        );
        setLoader1(false);
        setSearchResultData(result);
      } catch (error) {
        console.log("Error fetching search data:", error);
      } finally {
        setLoader1(false);
      }
    }, 300), // Debounce delay in milliseconds
    [page, limit, shop_id, basedOn]
  );

  const handleSearch = (event) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
    debouncedSearch(newSearchText);
  };

  const getShopProductsHandler = async () => {
    setLoader(true);
    let param = {
      user_id: userData?.id,
      shopid: shopId,
      based_on: basedOn,
      day: dayNTimeHandler().day,
      time: dayNTimeHandler().time,
      TypeID: typeId,
    };
    try {
      await sleep();
      const result = await CategoriesServices.getCategoriesShopDetail(
        param?.shopid,
        param?.day,
        param?.time,
        param?.based_on,
        param?.TypeID,
        param?.user_id
      );

      if (userData?.id != undefined) {
        const res = await groupOrderApiCall.getGroupOrderCartList({
          user_id: userData?.id,
        });
        console.log(res);
        for (let index = 0; index < res.length; index++) {
          console.log(res[index]?.shop_id);
          if (res[index]?.shop_id === result?.shopid) {
            setIsGroupOrder(true);
            // console.log('value', res[index]?.group_id);
            // console.log('true', res[index]?.shop_id, result?.shopid);
            setGroupId(res[index]?.group_id);
            break;
          } else {
            setIsGroupOrder(false);
          }
        }
      }
      console.log(result);
      setDetailData(result);
      // setLoader(false);
    } catch (error) {
      console.warn("get shop product handler error ", error);

      console.log(
        "🚀 ~ file: RestaurantDetail.js:55 ~ getShopProductsHandler ~ error:",
        error
      );
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };
  const getOnlineCartData = async () => {
    // setLoader(true);
    try {
      const result = await CartServices.getCartDetails(userData?.id);
      // setCartData(CartDetail);

      console.warn("get cart data result ", result);

      let count = 0;
      for (let index = 0; index < result?.length; index++) {
        if (
          shop_id == result?.[index].shop_id &&
          result?.[index].based_on === "store"
        ) {
          setShopItemsInCart(result?.[index]?.items);
        }
        count += result?.[index]?.items.length;
      }
      setTotalCartItems(count);
      // dispatch(setOnlineCartData(result?.items));
    } catch (error) {
      console.warn("get cart data error ", RangeError);
      console.log("file: index.js:37 => getCartData => error:", error);
    } finally {
      // setLoader(false);
    }
  };
  useEffect(() => {
    getOnlineCartData();
    getShopProductsHandler();
  }, []);
  console.log(shopItemsInCart);

  return (
    <Container sx={{ mt: 10, maxWidth: "1300px !important" }}>
      {loader ? (
        <Skeleton
          variant="rectangular"
          sx={{ borderRadius: "10px" }}
          width="100%"
          height={250}
        />
      ) : (
        <Card
          sx={{
            margin: "0 10px",
            display: "flex",
            justifyContent: "center",
            position: "relative",
            borderRadius: "10px",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              left: "15px",
              background: Colors.white,
              borderRadius: "50%",
              mb: 2,
            }}
          >
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIosNew sx={{ fontSize: "20px" }} />
            </IconButton>
          </Box>
          <IconButton
            sx={{
              position: "absolute",
              zIndex: 1,
              right: "50px",
              top: "10px",
              background: Colors.white,
              color: Colors.black,
              "&:hover": {
                background: Colors.white,
              },
            }}
          >
            {activeFavourite ? (
              <FavoriteIcon
                onClick={() => setActiveFavourite(false)}
                sx={{ fontSize: "20px", fill: Colors.black }}
              />
            ) : (
              <FavoriteBorderIcon
                onClick={() => setActiveFavourite(true)}
                sx={{ fontSize: "20px", fill: Colors.black }}
              />
            )}
          </IconButton>
          <IconButton
            sx={{
              position: "absolute",
              zIndex: 1,
              right: "10px",
              top: "10px",
              background: Colors.white,
              color: Colors.black,
              "&:hover": {
                background: Colors.white,
              },
            }}
            onClick={handleClick}
          >
            <MoreHorizIcon sx={{ fontSize: "20px", fill: Colors.black }} />
          </IconButton>
          <DropDown
            anchorEl={anchorEl}
            openDropdown={openDropdown}
            handleClose={handleClose}
            setActiveFavourite={setActiveFavourite}
          />
          <Grid container>
            <Grid item md={12} xs={12}>
              <CardMedia
                component="img"
                sx={{ height: "250px" }}
                image={
                  baseUrl +
                  "/docs/files/stores/banner/" +
                  detailData?.default_banner
                }
              />
            </Grid>
          </Grid>
        </Card>
      )}
      {/* ///second section  */}

      <Grid container spacing={2} sx={{ ml: { md: 1, xs: 0 } }}>
        <Grid item md={5} xs={12}>
          <Box>
            {loader ? (
              <>
                <Skeleton variant="text" width="60%" height={36} />
                <Skeleton variant="text" width="40%" height={24} />
                <Skeleton variant="text" width="80%" height={24} />
              </>
            ) : (
              <>
                <Box sx={{ fontWeight: "700", fontSize: "36px" }}>
                  {detailData?.name}
                </Box>
                <Box
                  sx={{
                    fontWeight: "400",
                    fontSize: "15px",
                    display: "flex",
                    mt: 2,
                  }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {detailData?.rating}{" "}
                    <StarBorderIcon sx={{ fontSize: "15px" }} />
                  </span>
                  ({detailData?.shopCategories?.length}){" "}
                  {moment
                    .duration(detailData?.estimated_delivery_time, "minutes")
                    .humanize()}
                </Box>
                <Box sx={{ fontWeight: "400", fontSize: "15px", pt: 0.4 }}>
                  <span style={{ fontWeight: "700", color: Colors.yellow }}>
                    Let's Eat
                  </span>
                  • {detailData?.start_time + "-" + detailData?.end_time} •
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Info
                  </span>
                </Box>
              </>
            )}
          </Box>
        </Grid>
        <Grid item md={7} xs={12} sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", float: "right", mt: 1, mr: 3 }}>
            {loader ? (
              <Skeleton
                variant="rectangular"
                sx={{ borderRadius: "25px" }}
                width="356px"
                height={36}
              />
            ) : (
              <TextField
                variant="outlined"
                value={searchText}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    width: "356px",
                    background: Colors.grey,
                    height: "35px",
                    borderRadius: "25px", // Rounded corners
                    paddingRight: "8px", // Right padding inside the input
                    "& fieldset": {
                      borderColor: "#E0E0E0", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#BDBDBD", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#BDBDBD", // Border color when focused
                    },
                  },
                }}
                onChange={(event) => handleSearch(event)} // Pass handleSearch directly
                placeholder={`Search in ${detailData?.name || "..."}`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ ml: { md: 1, xs: 0 } }}>
        <Divider sx={{ mt: 3 }} />
        <Grid item md={3} xs={12}>
          <Box
            sx={{
              position: "sticky",
              top: "10%",
            }}
          >
            {searchText === "" ? (
              loader ? (
                <Skeleton variant="rectangular" width="100%" height={400} />
              ) : (
                <List
                  sx={{
                    // maxHeight: "100vh",
                    overflow: "auto",
                  }}
                >
                  {detailData?.shopCategories?.map((item, index) => (
                    <ListItem
                      key={item.id}
                      sx={{
                        backgroundColor:
                          currentCategoryInView == item.category_id
                            ? Colors.grey
                            : "transparent",
                        borderLeft:
                          currentCategoryInView == item.category_id
                            ? "4px solid black"
                            : "transparent",
                        cursor: "pointer",
                        span: {
                          fontWeight:
                            currentCategoryInView == item.category_id
                              ? "bold"
                              : "",
                        },
                      }}
                      onClick={() => handleScrollOnClick(item)}
                    >
                      <ListItemText primary={item?.name} />
                    </ListItem>
                  ))}
                </List>
              )
            ) : (
              false
            )}
          </Box>
        </Grid>

        <Grid item md={9} xs={12} sx={{ width: "100%" }}>
          {/* {selectedType === "Delivery" && ( */}
          <Box sx={{ padding: "0 24px" }}>
            {loader ? (
              <>
                <Skeleton
                  sx={{ marginTop: "10px", marginLeft: "12px" }}
                  variant="rectangular"
                  width="30%"
                  height={25}
                />

                <Grid container spacing={2}>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <Skeleton
                        sx={{
                          marginTop: "10px",
                          marginLeft: "-22px",
                          borderRadius: "20px",
                        }}
                        variant="rectangular"
                        width="100%"
                        height="150px"
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : searchText === "" ? (
              detailData?.shopCategories?.map((item) => (
                <div key={item.category_id}>
                  <Box
                    sx={{ fontSize: "25px", fontWeight: "700", mt: 2 }}
                    ref={(el) => (sectionsRefs.current[item.category_id] = el)}
                    id={`section-${item.category_id}`}
                  >
                    {item?.name}
                  </Box>

                  <Grid container spacing={2} sx={{ mt: 2 }} id="scroll_grid">
                    {item?.products?.map((elem, index) => {
                      const hasImage = elem?.pic !== null;
                      const gridColumnSpan = hasImage ? 7 : 12;

                      return (
                        <Grid item key={index} xs={12} sm={6} md={6}>
                          {loader ? (
                            <Skeleton
                              sx={{
                                marginTop: "10px",
                                marginLeft: "-22px",
                                borderRadius: "20px",
                              }}
                              variant="rectangular"
                              width="100%"
                              height="150px"
                            />
                          ) : (
                            <Card
                              sx={{
                                margin: "0 2px",
                                display: "flex",
                                justifyContent: "center",
                                position: "relative",
                                borderRadius: "20px",
                                cursor: "pointer",
                                boxShadow: "none",
                                border: "1px solid rgb(243 243 243)",
                              }}
                            >
                              <Grid
                                container
                                
                                onClick={() => {
                                  dispatch(clearMenuTree(null));

                                  navigate(
                                    `/product/detail/${elem.product_id}`,
                                    {
                                      state: {
                                        elem,
                                        group_id: groupId,
                                        shopData: shopItemsInCart,
                                        basedOn: "store",
                                      },
                                    }
                                  );
                                }}
                              >
                                <Grid item md={gridColumnSpan} xs={12}>
                                  <CardContent>
                                    <Typography
                                      sx={{
                                        fontWeight: "700",
                                        fontSize: "20px",
                                      }}
                                    >
                                      {elem?.name}
                                    </Typography>
                                    <Box
                                      sx={{
                                        fontWeight: "400",
                                        fontSize: "15px",
                                        display: "flex",
                                      }}
                                    >
                                      ${elem?.price}
                                    </Box>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontSize: "15px",
                                        color: "rgb(117, 117, 117)",
                                      }}
                                    >
                                      {elem?.description}
                                    </Typography>
                                  </CardContent>
                                </Grid>
                                {hasImage && (
                                  <Grid item md={5} xs={12}>
                                    <CardMedia
                                      component="img"
                                      sx={{
                                        height: "150px",
                                        objectFit: "contain",
                                        opacity: "0.9",
                                      }}
                                      image={`${baseUrl}/docs/files/products/${elem?.pic}`}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </Card>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              ))
            ) : (
              <>
               
                {searchResultData?.map((elem) => (
                  // Iterate over products inside each elem
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    {elem.products?.map((product, index) => {
                      const hasImage = product?.pic !== null;
                      const gridColumnSpan = hasImage ? 7 : 12;
                      return (
                        <Grid item key={index} xs={12} sm={6} md={6}>
                          <Card
                            sx={{
                              margin: "0 2px",
                              display: "flex",
                              justifyContent: "center",
                              position: "relative",
                              borderRadius: "20px",
                              cursor: "pointer",
                              boxShadow: "none",
                              border: "1px solid rgb(243 243 243)",
                            }}
                          >
                            <Grid
                              container
                              onClick={() => {
                                dispatch(clearMenuTree(null));

                                navigate(
                                  `/product/detail/${product.product_id}`,
                                  {
                                    state: {
                                      product,
                                      group_id: groupId,
                                      shopData: shopItemsInCart,
                                      basedOn: "store",
                                    },
                                  }
                                );
                              }}
                            >
                              <Grid item md={gridColumnSpan} xs={12}>
                                <CardContent>
                                  <Typography
                                    sx={{
                                      fontWeight: "700",
                                      fontSize: "20px",
                                    }}
                                  >
                                    {product?.name}
                                  </Typography>
                                  <Box
                                    sx={{
                                      fontWeight: "400",
                                      fontSize: "15px",
                                      display: "flex",
                                    }}
                                  >
                                    ${product?.price}
                                  </Box>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontSize: "15px",
                                      color: "rgb(117, 117, 117)",
                                    }}
                                  >
                                    {product?.description}
                                  </Typography>
                                </CardContent>
                              </Grid>
                              {hasImage && (
                                <Grid item md={5} xs={12}>
                                  <CardMedia
                                    component="img"
                                    sx={{
                                      height: "150px",
                                      objectFit: "contain",
                                      opacity: "0.9",
                                    }}
                                    image={`${baseUrl}/docs/files/products/${product?.pic}`}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                ))}
              </>
            )}
          </Box>
          {/* )} */}
        </Grid>
      </Grid>
    </Container>
  );
}
