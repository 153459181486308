import React, { Fragment, useState } from 'react';
import Header from './header';
import SideNav from './sidenav';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Colors from '../../assets/styles';
import Footer from "../footer/index"
import FooterV2 from '../footerV2';
function DashboardLayout() {
  const [status, setStatus] = useState(false);

  const toggleStatus = () => {
    setStatus(!status);
  }

  return (
    <Fragment>
      <Header status={status} toggleStatus={toggleStatus} />
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", }}>
        <Box sx={{ display: "flex", flex: "1 1 auto", }}>
          {/* <Box sx={{ backgroundColor: Colors.dashboardBgColor,width:status?"0px":"270px" }}>
            <SideNav status={status} toggleStatus={toggleStatus} />
          </Box> */}
          <Box sx={{ flexGrow: 1, mt: "20px", position: "relative", width: "100%" }}>
            <Outlet />
          </Box>
        </Box>
        {/* ========== Footer ========== */}


        <FooterV2 />

      </Box>
    </Fragment>
  );
}

export default DashboardLayout;
