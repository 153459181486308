import React, { useState, useEffect } from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  IconButton,
  Box,
  CardContent,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Colors from "../../assets/styles";

const StoreCard = ({ navigate, data, addToFavorite }) => {
  const [filled, setFilled] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    setFilled(data?.Liked);
  }, []);

  return (
    <Card
      sx={{
        margin: "20px 0",
        cursor: "pointer",
        overflow: "hidden",
        position: "relative",
        boxShadow: "none !important",
      }}
      onClick={navigate}
    >
      <Box style={{ position: "absolute", top: "25%", right: 15 }}>
        <CardMedia
          style={{
            width: 80,
            height: 80,
            borderRadius: 50,
            borderColor: Colors.white,
            borderWidth: 3,
            backgroundColor: Colors.grey,
            paddingHorizontal: 4,
            objectFit: "contain",
          }}
          image={ `${baseUrl + "/docs/files/stores/"}` + data?.avatar}
        />
      </Box>
      <CardMedia
        component="img"
        sx={{ height: "160px", borderRadius: "10px" }}
        image={
          data?.default_banner 
              ? `${
                  baseUrl + "/docs/files/stores/banner/" + data?.default_banner
                }`
              : ""
           
        }
        alt={data?.name}
      />
      <CardContent sx={{ padding: "16px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div">
            {data?.name}
          </Typography>
          {data?.rating && (
            <Box
              sx={{
                backgroundColor: "#eee",
                padding: "4px 8px",
                borderRadius: "16px",
              }}
            >
              <Typography variant="body2">{data?.rating.toFixed(1)}</Typography>
            </Box>
          )}
        </Box>
        <Typography variant="body2" color="text.secondary">
          {`• ${
            data?.estimated_delivery_time == null
              ? ""
              : `${data?.estimated_delivery_time - 5} - ${
                  data?.estimated_delivery_time
                } mins`
          }`}
        </Typography>
      </CardContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "8px",
          position: "absolute",
          top: 8,
          right: 8,
        }}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setFilled(!filled);
            addToFavorite(data.shopid, !filled);
          }}
        >
          {filled ? (
            <FavoriteIcon sx={{ color: "red" }} />
          ) : (
            <FavoriteBorderIcon sx={{ color: "#fff" }} />
          )}
        </IconButton>
      </Box>
    </Card>
  );
};

export default StoreCard;
