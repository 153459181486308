import React, { useRef, useState, useEffect, Fragment } from 'react';
import {
  Typography,
  CircularProgress,
  IconButton,
  Container,
  Box,
  List,
  ListItem,
  Grid,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { favoriteApiCall } from '../../../../api/favorite/index';
import RestaurantCard from '../../../../components/RestaurantCard';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIosNew } from '@mui/icons-material';
import Colors from '../../../../assets/styles';


const Favorites = () => {
  const userData = useSelector((state) => state.userData.userData);
  const location = useLocation();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [favoriteShops, setFavoriteShops] = useState([]);

  const getFavorites = async () => {
    setLoader(true);
    try {
      let params = {
        user_id: userData?.id,
      };
      const result = await favoriteApiCall.getFavorites(params);
      setFavoriteShops(result);
    } catch (error) {
      console.log('Error fetching favorites:', error);
    } finally {
      setLoader(false);
    }
  };

  const addToFavorite = async (shopId, basedOn, status) => {
    try {
      console.warn(userData?.id, shopId, basedOn, status);
      await favoriteApiCall.addToFavorite(userData?.id, shopId, basedOn, status);
      getFavorites();
    } catch (error) {
      console.log('Error adding to favorites:', error);
    }
  };

  useEffect(() => {
    getFavorites();
  }, [location]);

  return (
    <Container sx={{ mt: 10, maxWidth: "1300px !important" }}>
      <Box sx={{ fontWeight: "bold" ,display:"flex",gap:"10px",alignItems:"center"}}>
      <Box sx={{background:Colors.grey,borderRadius:"50%",width:"40px"}}>
              <IconButton onClick={()=>navigate(-1)}> 
                <ArrowBackIosNew />
              </IconButton>
            </Box>
        <Typography variant="h5" sx={{fontWeight:"bold"}}>Favorites</Typography>
      </Box>

      {loader ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box mt={2}>
          {favoriteShops.length <= 0 ? (
            <Box textAlign="center">
              <Typography variant="body1">
                You don't have any shops in favorites.
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {favoriteShops.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <RestaurantCard
                    data={item}
                    addToFavorite={addToFavorite}
                    storeType={
                      item?.based_on === "store"
                        ? "store"
                        : "shop"
                    }
                    navigate={() =>
                      navigate(
                        item?.based_on === 'shop'
                          ? `/store/detail/${item?.shopid}`
                          : `/shop/detail/${item?.shopid}`
                      )
                    }
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      )}
    </Container>
  );
};

export default Favorites;
