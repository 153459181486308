
export const groupOrderEndpoints = {
    addGroupOrder: '/data/AddGroupOrder',
    editGroupOrder: '/data/EditGroupOrder',
    editGroupOrderAddToCart: '/data/EditGroupOrderAddToCart',
    joinGroupOrder: '/data/JoinGroup',
    addGroupOrderToCart: '/data/AddGroupOrderToCart',
    getgroupOrderDetail: '/data/GetGroupOrderDetails',
    getjoingroupOrderDetail: '/data/GetGroupOrder',
    getgroupOrderCartList: '/data/GetGroupOrderCartList',
    deleteGroupOrder: '/data/DeleteGroupOrder',
    deleteItemFromGroupOrder: '/data/DeleteItemFromGroupOrderCart',
    deleteUserFromGroupOrder: '/data/RemoveUserfromGroup',
  };
  