import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: {},
  selectedAddress:{} ,
  appliedPromoCode: [],
  cartCounter: 0,
  shopsList: [],
  previousOrderFeedback: [],
  userChatIds: '',
  urlId: '',
  dropOffOptions: { selectedItem: false, isSelected: false },
  editedText: undefined,
  currentLocation: { latitude: 0, longitude: 0 },
  editedAddress: {
    user_id: '',
    building: '',
    landmark: '',
    map_address: '',
    latitude: '',
    longitude: '',
    city: '',
    type: '',
    instructions: null,
    HandItToMe: {
      meet_at_door: false,
      meet_at_outside: false,
      meet_at_lobby: false,
    },
    LeaveAtLocation: {
      leave_at_door: false,
      leave_at_reception: false,
      leave_at_building_door: false,
    },
  },
  selectedPickupAddress: {},
  selectedDropoffAddress: {},
  parcelPickupAddresses: [],
  userIpAddress: '',
};

export const userDataSlice = createSlice({
  name: 'userDataReducer',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUserLocation: (state, action) => {
      state.selectedAddress = action.payload ;
    },
    setUserPickupLocation: (state, action) => {
      state.selectedPickupAddress = action.payload;
    },
    setUserDropoffLocation: (state, action) => {
      state.selectedDropoffAddress = action.payload;
    },
    setAppliedPromoCode: (state, action) => {
      state.appliedPromoCode = action.payload;
    },
    setCartCounter: (state, action) => {
      state.cartCounter = action.payload;
    },
    setShopsList: (state, action) => {
      state.shopsList = action.payload;
    },
    setPreviousOrderFeedback: (state, action) => {
      state.previousOrderFeedback = action.payload;
    },
    updateLikedStatus: (state, action) => {
      const { itemId, liked } = action.payload;
      const itemToUpdate = state.previousOrderFeedback?.[0]?.items.find(
        (item) => item.$id === itemId
      );
      if (itemToUpdate) {
        itemToUpdate.liked = liked;
      }
    },
    updateDriverLikedStatus: (state, action) => {
      const { driver_id, liked } = action.payload;
      const itemToUpdate = state.previousOrderFeedback?.[0]?.driver;
      if (itemToUpdate) {
        itemToUpdate.liked = liked;
      }
    },
    setuserChatIds: (state, action) => {
      state.userChatIds = action.payload;
    },
    setUrlId: (state, action) => {
      state.urlId = action.payload;
    },
    setDropOffOption: (state, action) => {
      state.dropOffOptions = action.payload;
    },
    setEditedtext: (state, action) => {
      state.editedText = action.payload;
    },
    setEditedAddress: (state, action) => {
      state.editedAddress = action.payload;
    },
    setCurrenLocation: (state, action) => {
      state.currentLocation = action.payload;
    },
    setUserIpAddress: (state, action) => {
      state.userIpAddress = action.payload;
    },
    resetUserData: () => initialState, // New reducer to reset state
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserLocation,
  setUserData,
  setAppliedPromoCode,
  setCartCounter,
  setShopsList,
  setPreviousOrderFeedback,
  updateLikedStatus,
  updateDriverLikedStatus,
  setuserChatIds,
  setUrlId,
  setDropOffOption,
  setEditedAddress,
  setEditedtext,
  setCurrenLocation,
  setUserPickupLocation,
  setUserDropoffLocation,
  setUserIpAddress,
  resetUserData, // Export the new action
} = userDataSlice.actions;

export default userDataSlice.reducer;
