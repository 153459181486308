import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  CircularProgress,
  Box,
  List,
  ListItem,
  Button,
  colors,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';
import Colors from '../../assets/styles';
import { Timer } from '@mui/icons-material';

const RestaurantCard = ({ navigate, data, storeType, addToFavorite }) => {
  const [loader, setLoader] = useState(true);
  const [filled, setFilled] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const baseUrl = process.env.REACT_APP_BASE_URL
  //   const navigate = useNavigate();
  console.log(storeType)

  useEffect(() => {
    setFilled(data?.Liked);
  }, []);

  return (
    <Card
      sx={{
        cursor: "pointer",
        overflow: 'hidden',
        position: 'relative',
        boxShadow: 'none !important',
      }}
      onClick={() => navigate(`/store/detail/${data?.product_id}`)}
    >
      {data?.status === 'Closed' && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '160px',
            bgcolor: 'rgba(0,0,0,0.5)',
            // zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            borderRadius: '10px'
          }}
        >
          <Typography variant="h6">CLOSED</Typography>
        </Box>
      )}

      <Box style={{ position: 'absolute', top: "25%", right: 15 }}>
        <CardMedia
          style={{
            width: 80,
            height: 80,
            borderRadius: 50,
            borderColor: Colors.white,
            borderWidth: 3,
            backgroundColor: Colors.grey,
            paddingHorizontal: 4,
            objectFit: 'contain'
          }}

          image={storeType == 'grocery' || storeType == "store"
            ? baseUrl + '/docs/files/stores/' + data?.avatar
            : baseUrl + '/docs/files/shops/' + data?.avatar
          }

        />
      </Box>
      <CardMedia
        component="img"
        sx={{ height: "160px", borderRadius: '10px', }}
        image={storeType == 'grocery' || storeType == "store"
          ? baseUrl + "/docs/files/stores/banner/" + data?.default_banner
          : baseUrl + "/docs/files/shops/banner/" + data?.default_banner
        }
        alt={data?.name}

      />

      {data?.is_trending && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 25,
            bgcolor: 'primary.main',
            color: '#fff',
            padding: '5px 10px',
            borderTopRightRadius: '20px',
            borderBottomRightRadius: '20px',
          }}
        >
          <Typography variant="body2">Trending</Typography>
        </Box>
      )}
      <CardContent sx={{ padding: '16px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-start" }}>
          <Typography variant="h6" component="div" >
            {data?.name}
          </Typography>
          {data?.rating && (
            <Box
              sx={{
                backgroundColor: '#eee',
                padding: '4px 8px',
                borderRadius: '16px',
              }}
            >
              <Typography variant="body2">{data?.rating.toFixed(1)}</Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "2px"
          }}
        >
          <Timer sx={{ fontSize: "16px", color: "#ff9d00" }} />
          <Typography color={colors.yellow} sx={{ fontFamily: "Noto Sans", fontSize: "14px", lineHeight: "16px", color: "#858585" }}>
            {`• ${data?.estimated_delivery_time == null
              ? ''
              : `${data?.estimated_delivery_time - 5} - ${data?.estimated_delivery_time
              } mins`
              }`}
          </Typography>
        </Box>
      </CardContent>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '8px',
          position: 'absolute',
          top: 8,
          right: 8,

        }}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setFilled(!filled);
            addToFavorite(data.shopid, storeType, !filled);
          }}
        >
          {filled ? (
            <FavoriteIcon sx={{ color: 'red' }} />
          ) : (
            <FavoriteBorderIcon sx={{ color: '#fff' }} />
          )}
        </IconButton>
      </Box>
      {data?.products && data?.products?.length > 0 && (
        <List sx={{ padding: '8px 16px' }}>
          {data?.products.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ marginBottom: '10px' }}>
              <Button
                // onClick={() =>
                //   history.push(`/orderSelectionScreen/${item?.product_id}`)
                // }
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  overflow: 'hidden',
                }}
              >
                <Box sx={{ padding: '16px' }}>
                  <Typography variant="body1">{item?.name}</Typography>
                  <Typography variant="body2">{`$${item?.price}`}</Typography>
                </Box>
                <img
                  src={`${baseUrl + "/docs/files/shops/banner/" + item?.pic}`}
                  alt={item?.name}
                  style={{
                    width: '80px',
                    height: '80px',
                    objectFit: 'cover',
                  }}
                />
              </Button>
            </ListItem>
          ))}
        </List>
      )}
    </Card>
  );
};

export default RestaurantCard;
