import moment from "moment";

// function for single values
export const handleMaxValueOne = (
    findItem,
    sumVal,
    listTitle,
    listLength,
    minValue,
    maxValue,
    spreadselectedRadioOpt,
    element,
    selectedValues
  ) => {
    let holdValues = [];
    if (findItem && Object.keys(findItem)?.length) {
      if (findItem?.qty > 0) {
        if (sumVal == 'increment') {
          let obj = {...findItem, qty: findItem.qty + 1};
          holdValues.push(obj);
          spreadselectedRadioOpt[listTitle] = holdValues;
          return spreadselectedRadioOpt;
        } else {
          let obj = {...findItem, qty: findItem.qty - 1};
          holdValues.push(obj);
          spreadselectedRadioOpt[listTitle] = holdValues;
          return spreadselectedRadioOpt;
        }
      } else {
        let sortElements = spreadselectedRadioOpt;
        delete sortElements[listTitle];
        return spreadselectedRadioOpt;
      }
    } else if (listLength >= minValue && listLength <= maxValue) {
      if (findItem && Object.keys(findItem)?.length) {
        if (sumVal == 'increment') {
          let obj = {...findItem, qty: findItem.qty + 1};
          holdValues.push(obj);
          spreadselectedRadioOpt[listTitle] = holdValues;
          return spreadselectedRadioOpt;
        } else {
          let obj = {...findItem, qty: findItem.qty - 1};
          holdValues.push(obj);
          spreadselectedRadioOpt[listTitle] = holdValues;
          return spreadselectedRadioOpt;
        }
      } else {
        let obj = {...element, qty: 1};
        selectedValues.push(obj);
        spreadselectedRadioOpt[listTitle] = selectedValues;
        return spreadselectedRadioOpt;
      }
    } else {
      return null;
    }
  };
  
  // function for multiple values
  export const handleMultipleValues = (
    findItem,
    sumVal,
    listTitle,
    listLength,
    minValue,
    maxValue,
    spreadselectedRadioOpt,
    findIndex,
    selectedValues,
    element,
  ) => {
    let qtyCount = 0;
    for (
      let index = 0;
      index < spreadselectedRadioOpt[listTitle].length;
      index++
    ) {
      qtyCount += spreadselectedRadioOpt[listTitle][index].qty;
    }
  
    if (findItem && Object.keys(findItem)?.length) {
      if (sumVal == 'increment') {
        selectedValues[findIndex] = {
          ...findItem,
          qty: findItem.qty + 1,
        };
        spreadselectedRadioOpt[listTitle] = selectedValues;
        // console.log('if')
        return {spreadselectedRadioOpt, qtyCount: qtyCount + 1};
      } else {
        const duplicate = [...selectedValues];
        duplicate[findIndex] = {
          ...findItem,
          qty: findItem.qty - 1,
        };
        // if (duplicate[findIndex].qty == 0) {
        //   duplicate.splice(findIndex, 1)
        // }
        // if (duplicate.length == 0) {
        //   delete spreadselectedRadioOpt[listTitle]
        // } else {
        // }
        spreadselectedRadioOpt[listTitle] = duplicate;
        // console.log('else');
        return {spreadselectedRadioOpt, qtyCount: qtyCount - 1};
      }
    }
  };
  
  // function for calculate radio values
  export const calculationFunc = (
    selectedData,
    totalCalcPrice,
    lastSelectedRadio,
  ) => {
    // console.log('lastSelectedRadio', lastSelectedRadio);
    var price = 0;
    for (let key in selectedData) {
      if (selectedData[key].length == 1) {
        const element = selectedData[key][0];
        price += element.price;
      }
    }
    return Math.abs(price + totalCalcPrice - lastSelectedRadio.price);
  };
  
  // function for calculate qty values
  export const calculateQty = (
    selectedData,
    totalCalcPrice,
    key,
    elementIndex,
    sumVal,
  ) => {
    const filteredData = Object.keys(selectedData).reduce((result, category) => {
      result[category] = selectedData[category].filter(
        item => item.qty !== 0 && item?.price !== 0,
      );
      console.log('result 134', result);
      return result;
    }, {});
   
    
    // Calculate the total by summing price * qty for each item
    let total = 0;
    Object.keys(filteredData).forEach(category => {
      filteredData[category].forEach(item => {
        total += item.price * (item?.qty == undefined ? 1 : item?.qty);
      });
    });
  
    //  from talha, filteredData will contain all those values that has qty 1
    // over to shairyar
    // console.warn(JSON.stringify(filteredData));
    // var price = 0;
    // if (sumVal == 'decrement') {
    //   price = totalCalcPrice - selectedData[key][elementIndex].price;
    // } else {
    //   price = totalCalcPrice + selectedData[key][elementIndex].price;
    // }
    console.log('total=>', total);
    return total;
  };
  
  // function for calculate checkbox values
  export const calculateCheckBox = (
    selectedData,
    totalCalcPrice,
    elementIndex,
  ) => {
    var price = 0;
    if (elementIndex == undefined || elementIndex == -1) {
      price = selectedData.price + totalCalcPrice;
      console.log(price)
    } else {
      price = Math.abs(totalCalcPrice - selectedData.price);
      console.log(price)
    }
    return price;
  };

export const extractDataFromJSON = jsonData => {
  const extractedData = [];

  for (const key in jsonData) {
    if (jsonData.hasOwnProperty(key)) {
      const array = jsonData[key];
      if (Array.isArray(array)) {
        array.forEach(item => {
          // console.warn(item);

          let {ProductName, price, qty, ProductId} = item;
          // console.warn(ProductName);
          if (qty != 0) {
            extractedData.push({key, ProductName, price, qty, ProductId});
          }
        });
      }
    }
  }

  return extractedData;
};
export const dayNTimeHandler = () => {
  let dateValue = new Date();
  var time = moment(dateValue).format('HH:mm');
  var day = moment(dateValue).format('ddd');

  return {
    time,
    day,
  };
};